import {AxiosInstance} from "axios";
import moment from "moment";
import {DEFAULT_DATE_FORMAT} from "../common/Constants";

export default class ExternalApi {
	constructor(public readonly axiosInstance: AxiosInstance) {}

	getFxRate(currencyId: string, invoiceDate: moment.Moment) {
		const url = `/api/nbp/${currencyId.toLowerCase()}/${invoiceDate.format(DEFAULT_DATE_FORMAT)}`;
		return this.axiosInstance.get(url)
	}
}