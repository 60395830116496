import {PartApiDto} from "./_RestModels";

export default class Part {
    constructor(public readonly id: string,
                public readonly partNumber: string,
                public readonly manufacturer: string,
                public readonly descriptionEn: string,
                public readonly descriptionPl: string,
                public readonly categoryId: string,
                public readonly typeId: string,
                public readonly unitId: string) {
    }

    public static fromJson(json: PartApiDto): Part {
        return new Part(json.id, json.partNumber, json.manufacturer, json.descriptionEn, json.descriptionPl, json.categoryId, json.typeId, json.unitId)
    }

}