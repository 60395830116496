import React from "react";
import {makeStyles, StyledComponentProps, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {defaultStyles} from "../../../styles/defaultStyles";
import {Option} from "ts-option";
import EGinDocument from "../../../models/EGinDocument";
import EGinItemsTableRow from "./EGinItemsTableRow";
import {EGinItemTableRow} from "./EGinItems";

const useStyles = makeStyles(defaultStyles);

interface IProps {
	eGinItemRows: EGinItemTableRow[],
	onEdit: (item: EGinItemTableRow) => void,
	onDelete: (item: EGinItemTableRow) => void,
	eGinDocument: Option<EGinDocument>
}

type Props = IProps & StyledComponentProps;

const EGinItemsTable: React.FC<Props> = (props: Props) => {
	const classes = useStyles();

	const {eGinItemRows, eGinDocument} = props;

	const isAccepted = eGinDocument.map(doc => doc.acceptedAt.isDefined).getOrElseValue(true);

	const onEdit = (ginItem: EGinItemTableRow) => () => {
		props.onEdit(ginItem);
	};

	const onDelete = (ginItem: EGinItemTableRow) => () => {
		props.onDelete(ginItem);
	};

	return (
		<Table size={"small"}>
			<TableHead>
				<TableRow>
					<TableCell className={classes.tableHeader}>No.</TableCell>
					<TableCell className={classes.tableHeader}>Part Number</TableCell>
					<TableCell className={classes.tableHeader}>Description En</TableCell>
					<TableCell className={classes.tableHeader} style={{textAlign: "center"}}>Quantity</TableCell>
					<TableCell className={classes.tableHeader} style={{textAlign: "center"}}>Unit</TableCell>
					<TableCell className={classes.tableHeader} style={{textAlign: "center"}}>Total Value PLN</TableCell>
					<TableCell className={classes.tableHeader} style={{width: 152, textAlign: "center"}}>Actions</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{eGinItemRows.map((eGinItem, index) => <EGinItemsTableRow key={eGinItem.id} index={index} eGinItemRow={eGinItem}
																		  onEdit={onEdit(eGinItem)} onDelete={onDelete(eGinItem)} accepted={isAccepted}/>)}
			</TableBody>
		</Table>
	);
};

export default EGinItemsTable
