import {PartTechnologyApiDto} from "./_RestModels";

export default class Technology {
	constructor(public readonly id: string,
		public readonly name: string,
		public readonly partId: string,
		public readonly partNumber: string,
		public readonly partDescriptionEn: string
	) {}

	public static fromJson(json: PartTechnologyApiDto) {
		return new Technology(json.id, json.name, json.partId, json.partNumber, json.partDescriptionEn)
	}
}