import React from "react";
import {SvgIcon} from "@material-ui/core";

interface IProps {
	path: string,
}

const GenericIcon: React.FC<IProps> = (props: IProps) => {
	const {path} = props;
	return (
		<SvgIcon style={{color: "rgba(0, 0, 0, 0.54)"}}>
			<svg style={{width: 24, height: 24}} viewBox="0 0 24 24">
				<path d={path}/>
			</svg>
		</SvgIcon>
	);
};

export default GenericIcon
