import GoogleCode from "../../models/GoogleCode";
import {AUTH_LOG_OUT, AUTH_TOKEN_FETCHED, GOOGLE_CODE_FETCHED} from "./authActionTypes";
import {Action} from "redux-actions";
import {Dispatch} from "redux";
import {OnFailure, OnSuccess} from "../actionHelpers";
import AuthToken from "../../models/AuthToken";
import {apiInstance} from "../../api/Api";
import {storage} from "../../App";
import LocalStorage from "../../common/LocalStorage";

function googleCodeFetched(googleCode: GoogleCode): Action<GOOGLE_CODE_FETCHED> {
    return {type: GOOGLE_CODE_FETCHED, payload: {googleCode}}
}

function authTokenFetched(authToken: AuthToken): Action<AUTH_TOKEN_FETCHED> {
    return {type: AUTH_TOKEN_FETCHED, payload: {authToken}}
}

function loggedOut(): Action<AUTH_LOG_OUT> {
    return {type: AUTH_LOG_OUT, payload: {}}
}

export function tokenObtained(authToken: AuthToken) {
    return function (dispatch: Dispatch<any>) {
        dispatch(authTokenFetched(authToken));
    }
}

export function fetchGoogleCode(googleCode: GoogleCode, onSuccess: OnSuccess) {
    return function (dispatch: Dispatch<any>) {
        dispatch(googleCodeFetched(googleCode));
        onSuccess();
    }
}

export function fetchAuthToken(googleCode: GoogleCode, onSuccess: OnSuccess, onFailure: OnFailure) {
    return function (dispatch: Dispatch<any>) {
        apiInstance.authApi.logIn(googleCode).then((token: AuthToken) => {
            storage.saveToStorage(LocalStorage.YOSENSI_WAREHOUSE_TOKEN, token);
            dispatch(authTokenFetched(token));
            onSuccess();
        }).catch(onFailure);
    }
}

export function logOut(onSuccess: OnSuccess, onFailure: OnFailure) {
    return function (dispatch: Dispatch<any>) {
        storage.removeYosensiData();
        dispatch(loggedOut());
        onSuccess();
    }
}