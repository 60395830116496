import React, {useEffect, useState} from "react";
import {Button, CircularProgress, Divider, Fab, makeStyles, Paper, Typography} from "@material-ui/core";
import {defaultStyles} from "../../../styles/defaultStyles";
import {useParams} from "react-router";
import Part from "../../../models/Part";
import PartState from "../../../models/PartState";
import TypeState from "../../../models/TypeState";
import {apiInstance} from "../../../api/Api";
import {none, Option, option} from "ts-option";
import EGinDocument from "../../../models/EGinDocument";
import EGinDocumentItem from "../../../models/EGinDocumentItem";
import TabSpinner from "../../common/TabSpinner";
import EGinItemsTable from "./EGinItemsTable";
import EGinItemsModal from "./EGinItemsModal";
import AddIcon from "@material-ui/icons/Add";
import AddMultiContainer from "../../common/AddMultiContainer";
import PartUnit from "../../../models/PartUnit";

const useStyles = makeStyles(defaultStyles);

const EGinItems: React.FC<{}> = () => {
	const [isLoading, setLoading] = useState<boolean>(false);
	const [isOpen, setOpen] = useState<boolean>(false);
	const [isSubmitting, setSubmitting] = useState<boolean>(false);
	const [eGinDocument, setEGinDocument] = useState<Option<EGinDocument>>(none);
	const [eGinItemRows, setEGinItemRows] = useState<EGinItemTableRow[]>([]);
	const [eGinItemRow, setEGinItemRow] = useState<Option<EGinItemTableRow>>(none);
	const [parts, setParts] = useState<Part[]>([]);
	const [units, setUnits] = useState<PartUnit[]>([]);
	const [partStates, setPartStates] = useState<PartState[]>([]);
	const [typeStates, setTypeStates] = useState<TypeState[]>([]);

	const {eginId} = useParams();

	const classes = useStyles();

	const fetchData = () => {
		setLoading(true);
		Promise.all([
			apiInstance.eGinDocumentApi.fetchEGinDocument(eginId as string),
			apiInstance.eGinDocumentApi.fetchEGinDocumentItems(eginId as string),
			apiInstance.partsApi.fetchParts(),
			apiInstance.partsApi.fetchPartUnits(),
			apiInstance.partsApi.fetchPartsStates(),
		]).then(data => {
			setEGinDocument(option(data[0]));
			setParts(data[2]);
			setUnits(data[3]);
			setPartStates(data[4].partState);
			setTypeStates(data[4].typeState);
			const rows: EGinItemTableRow[] = data[1].map(item => {
				const part = data[2].filter(p => p.id === item.partId)[0];
				const unitName = data[3].filter(u => u.id === part.unitId)[0].displayName;
				const descriptionEn = data[2].filter(p => p.id === item.partId)[0].descriptionEn;
				return {...item, descriptionEn, unitName}
			});
			setEGinItemRows(rows);
			setLoading(false);
		}).catch(err => console.error(err));
	};

	useEffect(() => {
		fetchData()
		// eslint-disable-next-line
	}, []);

	const onOpen = () => {
		setOpen(true);
	};

	const onClose = () => {
		setOpen(false);
		fetchData();
		setEGinItemRow(none)
	};

	const onEdit = (eginRow: EGinItemTableRow) => {
		setEGinItemRow(option(eginRow));
		setOpen(true);
	};

	const onDelete = (eginRow: EGinItemTableRow) => {
		if (window.confirm("Are you sure you want to delete this item?")) {
			apiInstance.eGinDocumentApi.deleteEginDocumentItem(eginRow.id).then(fetchData)
		}
	};
	const onDocumentAccept = () => {
		if (window.confirm("Accepted document can not be edited any more.\nAccept anyway?")) {
			setSubmitting(true);
			apiInstance.eGinDocumentApi.acceptEGinDocument(eginId as string).then(() => {
				setSubmitting(false);
				fetchData();
			}).catch((err: any) => {
				setSubmitting(false);
				console.error(err)
			})
		}
	};


	return (
		<Paper className={classes.tablePaper}>
			{isLoading ?
				<TabSpinner/> :
				<>
					{
						eGinDocument.map(doc => {
							return <Typography style={{margin: 16}} variant={"body1"}>EGIN Document number: <strong>{doc.documentNumber}</strong></Typography>
						}).orNull
					}
					<Divider/>
					<EGinItemsTable eGinDocument={eGinDocument} eGinItemRows={eGinItemRows} onEdit={onEdit} onDelete={onDelete}/>
					<EGinItemsModal eGinItem={eGinItemRow} parts={parts} partStates={partStates} typeStates={typeStates}
									isOpen={isOpen} onClose={onClose} units={units}/>
					<AddMultiContainer>
						{eGinDocument.map(doc => {
							return doc.acceptedAt.isEmpty && (
								<>
									<Button variant={"contained"} color={"primary"}
											onClick={onDocumentAccept}
											disabled={eGinItemRows.length === 0}>
										{isSubmitting ?
											<CircularProgress style={{color: "white"}} size={24}/>
											: "Accept Document"}
									</Button>
									<Fab size={"small"} color={"primary"}
										 onClick={onOpen}><AddIcon/></Fab>

								</>
							)

						}).getOrElseValue(false)
						}
					</AddMultiContainer>
				</>
			}
		</Paper>
	);
};

export default EGinItems;

export interface EGinItemTableRow extends EGinDocumentItem {
	descriptionEn: string,
	unitName: string,
}
