import {option, Option} from "ts-option";
import {IGrnDocumentItemApiDto} from "./_RestModels";

export default class IGrnDocumentItem {
	constructor(
		public readonly id: string,
		public readonly partId: string,
		public readonly typeId: Option<string>,
		public readonly partNumber: string,
		public readonly descriptionPl: string,
		public readonly quantity: number,
		public readonly totalValue: number,
		public readonly unitNamePl: string,
	) {}

	public static fromJson(json: IGrnDocumentItemApiDto): IGrnDocumentItem {
		const typeId = option(json.typeId);
		const {id, partId, partNumber, descriptionPl, quantity, totalValue, unitNamePl} = json;
		return new IGrnDocumentItem(id, partId, typeId, partNumber, descriptionPl, quantity, totalValue, unitNamePl)
	}
}