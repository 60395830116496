import {AuthTokenApiDto} from "./_RestModels";

export default class AuthToken {
    constructor(public readonly token: string,
                public readonly userId: string,
                public readonly userFullName: string) {}

    public static fromJson(json: AuthTokenApiDto) {
        return new AuthToken(json.token, json.userId, json.userFullName)
    }
}