import React, {useState} from "react";
import {Option} from "ts-option";
import {
	Button, Checkbox, CircularProgress,
	FormControl, FormControlLabel,
	FormHelperText,
	Grid, IconButton, InputAdornment,
	InputLabel,
	makeStyles,
	MenuItem,
	Modal,
	Select, TextField,
	Typography
} from "@material-ui/core";
import {defaultStyles} from "../../../styles/defaultStyles";
import Part from "../../../models/Part";
import {apiInstance} from "../../../api/Api";
import {Formik, Form, FormikHelpers, Field, FormikProps} from "formik";
import * as Yup from "yup";
import {useParams} from "react-router";
import FormTextField from "../../common/FormTextField";
import Decimal from "decimal.js";
import PartState from "../../../models/PartState";
import TypeState from "../../../models/TypeState";
import {EGinItemTableRow} from "./EGinItems";
import PartUnit from "../../../models/PartUnit";
import CloseIcon from '@material-ui/icons/Close';

interface IProps {
	isOpen: boolean,
	eGinItem: Option<EGinItemTableRow>,
	onClose: () => void,
	parts: Part[],
	units: PartUnit[],
	partStates: PartState[],
	typeStates: TypeState[],
}

const validationSchema = Yup.object().shape({
	quantity: Yup.number()
		.required('Quantity is required')
		.positive('Quantity have to be positive number')
});

const validationSchemaCreate = Yup.object().shape({
	quantity: Yup.number()
		.required('Quantity is required')
		.positive('Quantity have to be positive number'),
	partId: Yup.string().test("is-selected", "Part is required", (value => value !== "select")),
});

interface EGinItemFormValues {
	partId: string,
	quantity: string,
}

const useStyles = makeStyles(defaultStyles);

const EGinItemsModal: React.FC<IProps> = (props: IProps) => {

	const [isSubmitting, setSubmitting] = useState<boolean>(false);
	const [isAnotherOne, setAnotherOne] = useState<boolean>(false);
	const [filterString, setFilterString] = useState<string>("");

	const {isOpen, eGinItem, onClose, parts, units, partStates, typeStates} = props;
	const classes = useStyles();
	const {eginId} = useParams();

	const initialValues: EGinItemFormValues = props.eGinItem.map(eginItem => {
		const {partId, quantity} = eginItem;
		return ({partId, quantity: quantity.toString()})
	}).getOrElseValue({
		partId: "select",
		quantity: "",
	});

	const getAvailableParts = (partId: string) => {
		if (partId !== "select") {
			let partStateQuantity: number = 0;
			let typeStateQuantity: number = 0;
			const part = parts.filter(p => p.id === partId)[0];
			if (part) {
				const partState = partStates.filter(state => state.partId === part.id)[0];
				partStateQuantity = partState ? partState.quantity : 0;
				if (part.typeId) {
					const typeState = typeStates.filter(state => state.typeId === part.typeId)[0];
					typeStateQuantity = typeState ? typeState.quantity : 0;
				}
			}
			const partMessage = partStateQuantity !== 0 ?
				`Parts available: ${partStateQuantity} units.\n` :
				"No items available for this part number.\n";
			const typeMessage = typeStateQuantity !== 0 ? `Type available: ${typeStateQuantity} units.` :
				"No items available for this type.";

			if (part.typeId) {
				return (
					<>
						<div>{partMessage}</div>
						<div>{typeMessage}</div>
					</>
				)
			}
			return <div>{partMessage}</div>
		}
	};

	const filteredParts = parts.filter(part => {
		return filterString.length ? part.partNumber.toLowerCase().includes(filterString.toLowerCase()) : true
	}).sort((a, b) => a.partNumber.localeCompare(b.partNumber));

	return (
		<Modal
			open={isOpen}
			onClose={() => onClose()}
			style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
		>
			<div className={classes.extraWideModal}>
				<Formik
					initialValues={initialValues}
					enableReinitialize={true}
					validateOnBlur={true}
					validateOnChange={false}
					validationSchema={eGinItem.isDefined ? validationSchema : validationSchemaCreate}
					onSubmit={(values: EGinItemFormValues, actions: FormikHelpers<EGinItemFormValues>) => {
						setSubmitting(true);
						const quantity = new Decimal(values.quantity).toDecimalPlaces(2).toNumber();
						const part = parts.filter(part => part.id === values.partId)[0];
						const unit = units.filter(un => un.id === part.unitId)[0];
						const submitAction = eGinItem.isDefined ?
							apiInstance.eGinDocumentApi.putEginDocumentItem(eginId as string,
								eGinItem.get.id,
								part.id,
								part.typeId,
								quantity,
								part.partNumber,
								part.descriptionPl,
								unit.namePl) :
							apiInstance.eGinDocumentApi.postEGinDocumentItem(eginId as string,
								part.id,
								part.typeId,
								quantity,
								part.partNumber,
								part.descriptionPl,
								unit.namePl);
						submitAction
							.then(() => {
								setSubmitting(false);
								actions.resetForm();
								if (!isAnotherOne)
									onClose();
							})
							.catch((err: any) => {
								//TODO Handle error nicely
								setSubmitting(false);
								console.error(err)
							})
					}}
				>
					{(formikProps: FormikProps<EGinItemFormValues>) => (
						<Form noValidate={true}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography variant={"h6"}>{
										eGinItem.isDefined ?
											"Update EGin Document Item" :
											"Create EGin Document Item"
									}</Typography>
								</Grid>
								{eGinItem.isEmpty &&
								<>
									<Grid item xs={4}>
										<TextField
											variant={"outlined"}
											fullWidth={true}
											label={"Parts Filter"}
											margin={"dense"}
											id="partsFilter"
											value={filterString}
											onChange={(evt) => {
												formikProps.setFieldValue("partId", "select");
												setFilterString(evt.target.value)
											}}
											InputProps={{
												endAdornment: (
												<InputAdornment position="end">
												<IconButton
												size={"small"}
												onClick={() => setFilterString("")}
												disabled={!filterString.length}
												>
												<CloseIcon/>
												</IconButton>
												</InputAdornment>
												)
											}}
										/>
									</Grid>
									<Grid item xs={4}>
										<FormControl variant={"outlined"} fullWidth={true} style={{marginTop: 8}}
													 error={Boolean(
														 formikProps.errors.partId && formikProps.touched.partId)}>
											<InputLabel>Select Part</InputLabel>
											<Field name={"partId"} autoWidth={true} labelWidth={85} as={Select}
												   margin={"dense"} required={true}>
												<MenuItem value={"select"}>Select Part</MenuItem>
												{filteredParts.map(
													part => <MenuItem
														value={part.id} key={part.id}>{part.partNumber}</MenuItem>)}
											</Field>
											{formikProps.errors.partId && formikProps.touched.partId &&
											<FormHelperText>{formikProps.errors.partId}</FormHelperText>
											}
										</FormControl>
									</Grid>
								</>
								}
								<Grid item xs={eGinItem.isDefined ? 12 : 4}>
									<Field
										id="quantity"
										label="Quantity"
										name="quantity"
										required={true}
										type={"number"}
										error={formikProps.errors.quantity && formikProps.touched.quantity}
										helperText={formikProps.errors.quantity && formikProps.touched.quantity ?
											formikProps.errors.quantity : ""}
										as={FormTextField}
									/>
								</Grid>
								<Grid item xs={12} style={{height: 56}}>
									{getAvailableParts(formikProps.values.partId)}
								</Grid>
								<Grid item xs={12}>
									<div className={classes.buttonsRow}>
										<Button onClick={onClose}>Close</Button>
										{eGinItem.isEmpty &&
										<FormControlLabel
											control={
												<Checkbox
													checked={isAnotherOne}
													onChange={() => setAnotherOne(!isAnotherOne)}
													value={isAnotherOne}
													color="primary"
												/>
											}
											label="Create another one"
										/>
										}
										<Button type="submit" color={"primary"} variant={"contained"}>
											{isSubmitting ?
												<CircularProgress style={{color: "white"}} size={24}/> :
												eGinItem.isDefined ? "Update" : "Create"}
										</Button>
									</div>
								</Grid>
							</Grid>
						</Form>
					)}
				</Formik>
			</div>
		</Modal>
	);
};

export default EGinItemsModal