import {AxiosInstance} from "axios";
import Supplier from "../models/Supplier";
import {SupplierApiDto} from "../models/_RestModels";
import {Option} from "ts-option";

export default class SuppliersApi {
    constructor(public readonly axiosInstance: AxiosInstance) {
    }

    fetchSuppliers(): Promise<Supplier[]> {
        const url = "/api/suppliers";
        return this.axiosInstance.get(url).then(resp => resp.data.map((sp: SupplierApiDto) => Supplier.fromJson(sp)))
    }

    postSupplier(externalId: string,
                 vatNumber: Option<string>,
                 shortName: string,
                 fullName: string,
                 street: string,
                 city: string,
                 zipCode: string,
                 country: string,
                 website: Option<string>,): Promise<void> {
        const url = "/api/suppliers";
        const config = {
            externalId,
            vatNumber: vatNumber.orNull,
            shortName,
            fullName,
            street,
            city,
            zipCode,
            country,
            website: website.orNull,
        };
        return this.axiosInstance.post(url, config)
    }

    fetchSupplier(supplierId: string): Promise<Supplier> {
        const url = `/api/suppliers/${supplierId}`;
        return this.axiosInstance.get(url).then(resp => Supplier.fromJson(resp.data));
    }

    putSupplier(supplierId: string,
                externalId: string,
                vatNumber: Option<string>,
                shortName: string,
                fullName: string,
                street: string,
                city: string,
                zipCode: string,
                country: string,
                website: Option<string>): Promise<void> {
        const url = `/api/suppliers/${supplierId}`;
        const config = {
            externalId,
            vatNumber: vatNumber.orNull,
            shortName,
            fullName,
            street,
            city,
            zipCode,
            country,
            website: website.orNull,
        };
        return this.axiosInstance.put(url, config);
    }
}