import React from "react";
import {IconButton, TableCell, TableRow} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import IconDelete from "@material-ui/icons/Delete";
import {EGinItemTableRow} from "./EGinItems";

interface IProps {
	eGinItemRow: EGinItemTableRow,
	index: number,
	onEdit: () => void,
	onDelete: () => void,
	accepted: boolean,
}

const EGinItemsTableRow: React.FC<IProps> = (props: IProps) => {

	const {eGinItemRow, index, onEdit, onDelete, accepted} = props;

	return (
		<TableRow>
			<TableCell>{index + 1}</TableCell>
			<TableCell>{eGinItemRow.partNumber}</TableCell>
			<TableCell>{eGinItemRow.descriptionEn}</TableCell>
			<TableCell style={{textAlign: "center"}}>{eGinItemRow.quantity}</TableCell>
			<TableCell style={{textAlign: "center"}}>{eGinItemRow.unitName}</TableCell>
			<TableCell style={{textAlign: "center"}}>{(eGinItemRow.totalValue / 10000).toFixed(
				2)}</TableCell>
			<TableCell style={{width: 152, textAlign: "center"}}>
				{
					!accepted &&
					<>
						<IconButton size={"small"} onClick={onEdit}>
							<EditIcon/>
						</IconButton>
						<IconButton size={"small"} onClick={onDelete}>
							<IconDelete/>
						</IconButton>
					</>
				}
			</TableCell>
		</TableRow>
	);
};

export default EGinItemsTableRow
