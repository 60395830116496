import React, {useState} from "react";
import {StyledComponentProps, Table, TableBody, TableCell, TableHead, TableRow, withStyles} from "@material-ui/core";
import {defaultStyles} from "../../../styles/defaultStyles";
import PartUnitsModal from "./PartUnitsModal";
import {none, option, Option} from "ts-option";
import PartUnitsTableRow from "./PartUnitsTableRow";
import PartUnit from "../../../models/PartUnit";

interface IProps {
	partUnits: PartUnit[];
	onClose: () => void,
}

type Props = IProps & StyledComponentProps;

const PartUnitsTable: React.FunctionComponent<Props> = (props: Props) => {
	const [isOpen, setOpen] = useState(false);
	const [partUnit, setPartUnit] = useState<Option<PartUnit>>(none);
	const {partUnits} = props;
	const {classes}: any = props;

	const onEdit = (partUnit: PartUnit) => () => {
		setPartUnit(option(partUnit));
		setOpen(true);
	};

	const onClose = () => {
		setOpen(false);
		props.onClose()
	};

	return (
		<div className={classes.settingsTableContainer}>
			<Table size={"small"}>
				<TableHead>
					<TableRow>
						<TableCell className={classes.tableHeader}>Name En</TableCell>
						<TableCell className={classes.tableHeader}>Name Pl</TableCell>
						<TableCell className={classes.tableHeader}>Display Name</TableCell>
						<TableCell className={classes.tableHeader} style={{width: 80}}>Actions</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{partUnits
						.map((partUnit) => <PartUnitsTableRow
							key={partUnit.id}
							partUnit={partUnit}
							onEdit={onEdit(partUnit)}/>)}
				</TableBody>
			</Table>
			<PartUnitsModal isOpen={isOpen}
							partUnit={partUnit}
							onClose={() => onClose()}/>
		</div>
	);
};

export default withStyles(defaultStyles)(PartUnitsTable);