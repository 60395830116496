import {SettingsState} from "./settingsState";
import {none, option} from "ts-option";
import {Action, handleActions, Reducer} from "redux-actions";
import {SETTINGS_FETCHED} from "./settingsActionTypes";

const initialState: SettingsState = {
    settings: none
};

const reducer: Reducer<SettingsState, SettingsState> = handleActions({
    [SETTINGS_FETCHED] : (state: SettingsState, action: Action<any>): SettingsState => {
        return {...state, settings: option(action.payload.settings)}
    },
}, initialState);

export default reducer;