import {AxiosInstance} from "axios";
import GinDocument from "../models/GinDocument";
import {GinDocumentApiDto, GinPartStateApiDto} from "../models/_RestModels";
import FormsUtils from "../common/FormsUtils";
import GinPartState from "../models/GinPartState";
import {GinPartStateApiData} from "../components/gin_documents/GinDocumentsModal";

export default class GinDocumentApi {
	constructor(public readonly axiosInstance: AxiosInstance) {
	}

	postGinDocument(description: string,
		technologyId: string,
		technologyQuantity: string,
		documentDate: string,
		internalUse: boolean): Promise<GinDocument> {
		const url = "/api/gin_documents";
		const technologyConfig = technologyId === "select" ? {} : {
			technologyId: FormsUtils.optionalSelect(technologyId),
			technologyQuantity: technologyQuantity ? parseInt(technologyQuantity) : null,
		};
		const config = {
			description,
			documentDate,
			internalUse,
			...technologyConfig
		};
		return this.axiosInstance.post(url, config).then(resp => GinDocument.fromJson(resp.data))
	}

	putGinDocument(id: string, description: string, documentDate: string, internalUse: boolean): Promise<GinDocument> {
		const url = `/api/gin_documents/${id}`;
		const config = {
			description,
			documentDate,
			internalUse
		};
		return this.axiosInstance.put(url, config).then((resp => GinDocument.fromJson(resp.data)))
	}

	fetchGinDocuments(): Promise<GinDocument[]> {
		const url = "/api/gin_documents";
		return this.axiosInstance.get(url).then(
			(resp) => resp.data.map((gin: GinDocumentApiDto) => GinDocument.fromJson(gin)))
	}

	fetchGinDocument(ginId: string): Promise<GinDocument> {
		const url = `/api/gin_documents/${ginId}`;
		return this.axiosInstance.get(url).then((resp) => GinDocument.fromJson(resp.data))
	}

	deleteGinDocument(id: string): Promise<void> {
		const url = `/api/gin_documents/${id}`;
		return this.axiosInstance.delete(url);
	}

	postGinItems(id: string, items: GinPartStateApiData[]): Promise<void> {
		const url = `/api/gin_documents/${id}/items`;
		const config = items;
		return this.axiosInstance.post(url, config)
	}

	fetchGinItems(id: string): Promise<GinPartState[]> {
		const url = `/api/gin_documents/${id}/items`;
		return this.axiosInstance(url).then(resp => resp.data.map((item: GinPartStateApiDto) => GinPartState.fromJson(item)))
	}

	updateGinItem(ginId: string, ginItemId: string, items: GinPartStateApiData[]): Promise<void> {
		// We do not have real update, firstly delete item and then create new one with updated data.
		return this.deleteGinItem(ginItemId).then(() => {
			return this.postGinItems(ginId, items)
		})
	}

	deleteGinItem(ginId: string): Promise<void> {
		const url = `/api/gin_document_items/${ginId}`;
		return this.axiosInstance.delete(url)
	}

	acceptGinDocument(ginId: string): Promise<void> {
		const url = `/api/gin_documents/${ginId}/accept`;
		return this.axiosInstance.put(url)
	}
}