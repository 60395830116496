import {AxiosInstance} from "axios";
import IGrnDocument from "../models/IGrnDocument";
import {IGrnDocumentApiDto, IGrnDocumentItemApiDto} from "../models/_RestModels";
import IGrnDocumentItem from "../models/IGrnDocumentItem";

export default class IGrnDocumentApi {
	constructor(public readonly axiosInstance: AxiosInstance) {
	}

	fetchIGrnDocuments(): Promise<IGrnDocument[]> {
		const url = "/api/igrn_documents";
		return this.axiosInstance.get(url).then(resp => resp.data.map((igrn: IGrnDocumentApiDto) => IGrnDocument.fromJson(igrn)));
	}

	fetchIGrnDocument(id: string): Promise<IGrnDocument> {
		const url = `/api/igrn_documents/${id}`;
		return this.axiosInstance.get(url).then((resp) => IGrnDocument.fromJson(resp.data))
	}

	postIGrnDocument(description: string, documentDate: string, ginDocumentId: string): Promise<IGrnDocument> {
		const url = "/api/igrn_documents";
		const config = {description, documentDate, ginDocumentId};
		return this.axiosInstance.post(url, config).then((resp) => IGrnDocument.fromJson(resp.data))
	}

	putIGrnDocument(id: string, description: string, documentDate: string, ginDocumentId: string): Promise<IGrnDocument> {
		const url = `/api/igrn_documents/${id}`;
		const config = {description, documentDate, ginDocumentId};
		return this.axiosInstance.put(url, config)
	}

	deleteIGrnDocument(id: string): Promise<void> {
		const url = `/api/igrn_documents/${id}`;
		return this.axiosInstance.delete(url)
	}
	acceptIGrnDocument(id:string): Promise<void> {
		const url = `/api/igrn_documents/${id}/accept`;
		return this.axiosInstance.put(url)
	}
	fetchIGrnItems(id: string): Promise<IGrnDocumentItem[]> {
		const url = `/api/igrn_documents/${id}/items`;
		return this.axiosInstance.get(url).then(
			resp => resp.data.map((igrnItem: IGrnDocumentItemApiDto) => IGrnDocumentItem.fromJson(igrnItem)));
	}

	deleteIGrnItem(id: string): Promise<void> {
		const url = `/api/igrn_document_items/${id}`;
		return this.axiosInstance.delete(url);
	}

	postIGrnItem(id: string,
		partId: string,
		partNumber: string,
		descriptionPl: string,
		quantity: number,
		totalValue: number,
		unitNamePl: string,
		typeId: string | null): Promise<void> {
		const url = `/api/igrn_documents/${id}/items`;
		const config = {
			partId, typeId, partNumber, descriptionPl, quantity, totalValue, unitNamePl
		};
		return this.axiosInstance.post(url, config);
	}

	putIGrnItem(id: string, quantity: number, totalValue: number): Promise<void> {
		const url = `/api/igrn_document_items/${id}`;
		const config = {quantity, totalValue};
		return this.axiosInstance.put(url, config)
	}
}