import {TextField} from "@material-ui/core";
import React from "react";

interface IProps {
    onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void,
    value: string,
    required: boolean,
    id: string,
    label: string,
    name: string,
    error?: boolean,
    helperText?: string
	disabled?: boolean
	type?: string
}

const FormTextField: React.FC<IProps> = (props: IProps) => {
    const {onChange, value, required, id, label, name, error, helperText, disabled, type} = props;
    return (
        <TextField
            variant="outlined"
            id={id}
            label={label}
            name={name}
            margin="dense"
            fullWidth={true}
            required={required}
            onChange={onChange}
            value={value}
            type={type}
			disabled={disabled}
            error={error || false}
            helperText={helperText || ""}
        />
    )
};

export default FormTextField;