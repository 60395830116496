import React from "react";
import {none} from "ts-option";
import {makeStyles, Paper} from "@material-ui/core";
import {defaultStyles} from "../../styles/defaultStyles";
import InvoicesForm from "./InvoicesForm";

const useStyles = makeStyles(defaultStyles);

const InvoicesCreate = () => {
    const classes = useStyles();
    return (
        <Paper className={classes.defaultPaper}>
            <InvoicesForm invoice={none}/>
        </Paper>
    )
};

export default InvoicesCreate;