import PartState from "./PartState";
import {option, Option} from "ts-option";
import {ValidateRequestItemApiDto} from "./_RestModels";

export default class ValidateRequestItem {
	constructor(public readonly requestedPartId: string,
		public readonly requestedTypeId: Option<string>,
		public readonly requestedQuantity: number,
		public readonly availableQuantity: number,
		public readonly available: PartState[]) {}

	public static fromJson(json: ValidateRequestItemApiDto) {
		const requestedTypeId = option(json.requestedTypeId);
		const {requestedPartId, requestedQuantity, availableQuantity} = json;
		const available = json.available.map(ps => PartState.fromJson(ps));
		return new ValidateRequestItem(requestedPartId, requestedTypeId, requestedQuantity, availableQuantity, available);
	}
}