import React, {useState} from "react";
import {StyledComponentProps, Table, TableBody, TableCell, TableHead, TableRow, withStyles} from "@material-ui/core";
import {defaultStyles} from "../../../styles/defaultStyles";
import PartType from "../../../models/PartType";
import PartTypesTableRow from "./PartTypesTableRow";
import PartTypeModal from "./PartTypesModal";
import {none, option, Option} from "ts-option";

interface IProps {
	partTypes: PartType[];
	onClose: () => void,
}

type Props = IProps & StyledComponentProps;

const PartTypesTable: React.FunctionComponent<Props> = (props: Props) => {
	const [isOpen, setOpen] = useState(false);
	const [partType, setPartType] = useState<Option<PartType>>(none);
	const {partTypes} = props;
	const {classes}: any = props;

	const onEdit = (partType: PartType) => () => {
		setPartType(option(partType));
		setOpen(true);
	};

	const onClose = () => {
		setOpen(false);
		props.onClose()
	};

	return (
		<div className={classes.settingsTableContainer}>
			<Table size={"small"}>
				<TableHead>
					<TableRow>
						<TableCell className={classes.tableHeader}>Type ID</TableCell>
						<TableCell className={classes.tableHeader} style={{width: 80}}>Actions</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{partTypes
						.map((partType) => <PartTypesTableRow
							key={partType.id}
							partType={partType}
							onEdit={onEdit(partType)}/>)}
				</TableBody>
			</Table>
			<PartTypeModal isOpen={isOpen}
						   partType={partType}
						   onSuccess={() => onClose()}
						   onClose={() => onClose()}/>
		</div>
	);
};

export default withStyles(defaultStyles)(PartTypesTable);