import * as React from "react";
import {CircularProgress, withStyles} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";

const styles = () => createStyles({
	tabSpinner: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "100%"
	}
});

interface IProps {
	size?: number
}

class TabSpinner extends React.Component<IProps, {}> {
	render() {
		const {classes, size}: any = this.props;
		return (
			<div className={classes.tabSpinner}>
				<CircularProgress size={size ? size : 40}/>
			</div>
		);
	}
}

export default withStyles(styles)(TabSpinner);