import React, {useState} from "react";
import {useFormik} from 'formik';
import {Button, CircularProgress, Grid, StyledComponentProps, Typography, withStyles} from "@material-ui/core";
import {defaultStyles} from "../../styles/defaultStyles";
import {useHistory} from "react-router-dom";
import FormTextField from "../common/FormTextField";
import {apiInstance} from "../../api/Api";
import {none, Option, option} from "ts-option";
import Client from "../../models/Client";
import {useParams} from "react-router-dom";

export interface ClientFormValues {
    externalId: string,
    vatNumber: string,
    shortName: string,
    fullName: string,
    street: string,
    city: string,
    zipCode: string,
    country: string,
    website: string,
}

interface CProps {
    client: Option<Client>
}

type IProps = CProps & StyledComponentProps;

const ClientsForm: React.FC<IProps> = (props: IProps) => {

    const initialValues: ClientFormValues = props.client.map((client: Client) => {
        return ({
            externalId: client.externalId,
            vatNumber: client.vatNumber.getOrElseValue(""),
            shortName: client.shortName,
            fullName: client.fullName,
            street: client.street,
            city: client.city,
            zipCode: client.zipCode,
            country: client.country,
            website: client.website.getOrElseValue(""),
        });
    }).getOrElse(() => ({
        externalId: "",
        vatNumber: "",
        shortName: "",
        fullName: "",
        street: "",
        city: "",
        zipCode: "",
        country: "",
        website: "",
    }));

    const {clientId} = useParams();
    const [isSubmitting, setSubmitting] = useState(false);
    const history = useHistory();

    const formik = useFormik({
        initialValues,
        onSubmit: (values: ClientFormValues) => {
            const submitAction = props.client.isDefined ?
                apiInstance.clientApi.putClient((clientId as string),
                    values.externalId,
                    values.vatNumber.length === 0 ? none : option(values.vatNumber),
                    values.shortName,
                    values.fullName,
                    values.street,
                    values.city,
                    values.zipCode,
                    values.country,
                    values.website.length === 0 ? none : option(values.website),
                ) :
                apiInstance.clientApi.postClient(
                    values.externalId,
                    values.vatNumber.length === 0 ? none : option(values.vatNumber),
                    values.shortName,
                    values.fullName,
                    values.street,
                    values.city,
                    values.zipCode,
                    values.country,
                    values.website.length === 0 ? none : option(values.website),
                );
            // TODO Handle errors nicely
            setSubmitting(true);
            submitAction.then(() => {
                setSubmitting(false);
                formik.resetForm();
                if (props.client.isDefined) {
                    history.push("/clients")
                }
            }).catch((err) => {
                setSubmitting(false);
                console.error(err)
            })
        }
    });
    const {classes}: any = props;
    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container>
                <Grid container item lg={6} md={12} spacing={3}>
                    <Grid item xs={12}>
                        {props.client.isDefined ?
                            <Typography variant={"h6"}>Edit Client</Typography> :
                            <Typography variant={"h6"}>Create New Client</Typography>
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <FormTextField
                            id="externalId"
                            label="External ID"
                            name="externalId"
                            required={true}
                            onChange={formik.handleChange}
                            value={formik.values.externalId}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormTextField
                            id="vatNumber"
                            label="Vat Number"
                            name="vatNumber"
                            required={false}
                            onChange={formik.handleChange}
                            value={formik.values.vatNumber}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField
                            id="shortName"
                            label="Short Name"
                            name="shortName"
                            required={true}
                            onChange={formik.handleChange}
                            value={formik.values.shortName}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField
                            id="fullName"
                            label="Full Name"
                            name="fullName"
                            required={true}
                            onChange={formik.handleChange}
                            value={formik.values.fullName}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField
                            id="street"
                            label="Street"
                            name="street"
                            required={true}
                            onChange={formik.handleChange}
                            value={formik.values.street}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormTextField
                            id="city"
                            label="City"
                            name="city"
                            required={true}
                            onChange={formik.handleChange}
                            value={formik.values.city}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormTextField
                            id="zipCode"
                            label="Zip Code"
                            name="zipCode"
                            required={true}
                            onChange={formik.handleChange}
                            value={formik.values.zipCode}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormTextField
                            id="country"
                            label="Country"
                            name="country"
                            required={true}
                            onChange={formik.handleChange}
                            value={formik.values.country}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormTextField
                            id="website"
                            label="Website"
                            name="website"
                            required={false}
                            onChange={formik.handleChange}
                            value={formik.values.website}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.buttonsRow}>
                            <Button onClick={() => history.goBack()}>Back</Button>
                            <Button type="submit" color={"primary"} variant={"contained"}>
                                {isSubmitting ? <CircularProgress style={{color: "white"}} size={24}/> :
                                    props.client.isDefined ? "Update" : "Create"}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default withStyles(defaultStyles)(ClientsForm);