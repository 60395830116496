import React, {useState} from 'react';
import {createStyles, CssBaseline, StyledComponentProps, Theme, withStyles} from "@material-ui/core";
import NavigationBar from "./layout/NavigationBar";
import AppDrawer from "./layout/AppDrawer";
import {Route, useHistory, Switch, Redirect} from 'react-router-dom';
import Settings from "./settings/Settings";
import Parts from "./parts/Parts";
import Suppliers from "./suppliers/Suppliers";
import SuppliersEdit from "./suppliers/SuppliersEdit";
import SuppliersCreate from "./suppliers/SuppliersCreate";
import PartsCreate from "./parts/PartsCreate";
import PartsEdit from "./parts/PartsEdit";
import Invoices from "./invoices/Invoices";
import InvoicesCreate from "./invoices/InvoicesCreate";
import InvoicesEdit from "./invoices/InvoicesEdit";
import GrnEdit from "./grn_documents/GrnEdit";
import Technologies from "./tchnologies/Technologies";
import TechnologyItems from "./tchnologies/items/TechnologyItems";
import GinDocuments from "./gin_documents/GinDocuments";
import GinItems from "./gin_documents/items/GinItems";
import IGrnDocuments from "./igrn_documents/IGrnDocuments";
import IGrnItems from "./igrn_documents/items/IGrnItems";
import ClientsCreate from "./clients/ClientsCreate";
import ClientsEdit from "./clients/ClientsEdit";
import Clients from "./clients/Clients";
import EGinDocuments from "./egin_documents/EGinDocuments";
import EGinItems from "./egin_documents/items/EGinItems";
import PartsSummary from "./parts_summary/PartsSummary";

const styles = (theme: Theme) => createStyles({
    root: {
        flex: 1,
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    toolbar: theme.mixins.toolbar,
    contentContainer: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: "column",
        width: '100%',
        overflow: "hidden"
    },
});

type IProps = StyledComponentProps;

const Main: React.FC<IProps> = (props: IProps) => {
    let history = useHistory();
    const [isMobileOpen, setMobileOpen] = useState(false);

    const handleRouteSelect = (route: string) => () => {
        history.push(route);
        if (isMobileOpen)
            handleDrawerToggle();
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!isMobileOpen);
    };
    const {classes}: any = props;
    return (
        <div className={classes.root}>
            <CssBaseline/>
            <NavigationBar handleDrawerToggle={handleDrawerToggle}/>
            <AppDrawer mobileOpen={isMobileOpen}
                       handleDrawerToggle={handleDrawerToggle}
                       onRouteSelected={handleRouteSelect}/>
            <main className={classes.contentContainer}>
                <div className={classes.toolbar}/>
                <Switch>
					<Route path={"/invoices/:invoiceId/grn_document/:id"} component={GrnEdit}/>
                    <Route path={"/invoices/create"} component={InvoicesCreate}/>
                    <Route path={"/invoices/:invoiceId"} component={InvoicesEdit}/>
                    <Route path={"/invoices"} component={Invoices}/>
					<Route path={"/egin_documents/:eginId/items"} component={EGinItems}/>
					<Route path={"/egin_documents/"} component={EGinDocuments}/>
                    <Route path={"/igrn_documents/:igrnId/items"} component={IGrnItems}/>
                    <Route path={"/igrn_documents/"} component={IGrnDocuments}/>
                    <Route path={"/gin_documents/:ginId/items"} component={GinItems}/>
                    <Route path={"/gin_documents"} component={GinDocuments}/>
                    <Route path={"/technologies/:technologyId/items"} component={TechnologyItems}/>
                    <Route path={"/technologies/:technologyId"} component={Technologies}/>
                    <Route path={"/technologies"} component={Technologies}/>
                    <Route path={"/clients/create"} component={ClientsCreate}/>
                    <Route path={"/clients/:clientId"} component={ClientsEdit}/>
                    <Route path={"/clients"} component={Clients}/>
                    <Route path={"/suppliers/create"} component={SuppliersCreate}/>
                    <Route path={"/suppliers/:supplierId"} component={SuppliersEdit}/>
                    <Route path={"/suppliers"} component={Suppliers}/>
                    <Route path={"/app_settings"} component={Settings}/>
                    <Route path={"/parts/create"} component={PartsCreate}/>
                    <Route path={"/parts/:partId"} component={PartsEdit}/>
                    <Route path={"/parts"} component={Parts}/>
                    <Route path={"/summary"} component={PartsSummary}/>
                    <Redirect to={"/suppliers"}/>
                </Switch>
            </main>
        </div>
    );
};

export default withStyles(styles)(Main);