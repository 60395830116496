import {AuthState} from "./authState";
import {none, option} from "ts-option";
import {Action, handleActions, Reducer} from "redux-actions";
import {AUTH_TOKEN_FETCHED, GOOGLE_CODE_FETCHED} from "./authActionTypes";

const initialState: AuthState = {
    googleCode: none,
    authToken: none,
};

const reducer: Reducer<AuthState, AuthState> = handleActions({
    [GOOGLE_CODE_FETCHED] : (state: AuthState, action: Action<any>): AuthState => {
        return {...state, googleCode: option(action.payload.googleCode)}
    },
    [AUTH_TOKEN_FETCHED] :(state: AuthState, action: Action<any>): AuthState => {
        return {...state, authToken: option(action.payload.authToken)}
    }
}, initialState);

export default reducer;