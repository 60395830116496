import React, {useEffect, useState} from "react";
import {
	Divider,
	ExpansionPanelDetails,
	Fab,
	makeStyles,
	Paper,
	Typography,
	ExpansionPanelSummary,
	ExpansionPanel, Button, CircularProgress
} from "@material-ui/core";
import TabSpinner from "../../common/TabSpinner";
import AddIcon from "@material-ui/icons/Add";
import {defaultStyles} from "../../../styles/defaultStyles";
import GinItemsTable from "./GinItemsTable";
import {apiInstance} from "../../../api/Api";
import {useParams} from "react-router";
import GinPartState from "../../../models/GinPartState";
import GinItemsModal from "./GinItemsModal";
import {none, option, Option} from "ts-option";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Part from "../../../models/Part";
import PartState from "../../../models/PartState";
import AddMultiContainer from "../../common/AddMultiContainer";
import GinDocument from "../../../models/GinDocument";
import TypeState from "../../../models/TypeState";

const useStyles = makeStyles(defaultStyles);

const GinItems: React.FC = () => {

	const [isLoading, setLoading] = useState<boolean>(false);
	const [isSubmitting, setSubmitting] = useState<boolean>(false);
	const [isOpen, setOpen] = useState<boolean>(false);
	const [ginItemsRows, setGinItemsRows] = useState<GinPartStateTableRow[]>([]);
	const [ginItems, setGinItems] = useState<GinPartState[]>([]);
	const [ginItem, setGinItem] = useState<Option<GinPartState>>(none);
	const [ginDocument, setGinDocument] = useState<Option<GinDocument>>(none);
	const [parts, setParts] = useState<Part[]>([]);
	const [partStates, setPartStates] = useState<PartState[]>([]);
	const [typeStates, setTypeStates] = useState<TypeState[]>([]);

	const {ginId} = useParams();

	const classes = useStyles();

	const fetchData = () => {
		setLoading(true);
		Promise.all([
			apiInstance.ginDocumentApi.fetchGinItems(ginId as string),
			apiInstance.partsApi.fetchParts(),
			apiInstance.partsApi.fetchPartUnits(),
			apiInstance.partsApi.fetchPartsStates(),
			apiInstance.ginDocumentApi.fetchGinDocument(ginId as string),
		]).then(data => {
			const items: GinPartStateTableRow[] = data[0].map((item: GinPartState) => {
				const part = data[1].filter(p => p.id === item.partId)[0];
				const unit = data[2].filter(u => u.id === part.unitId)[0];
				return ({
					...item,
					unitName: unit ? unit.displayName : "-",
					partDescriptionEn: part ? part.descriptionEn : "-",
					partNumber: part ? part.partNumber : "-",
				})
			});
			setParts(data[1]);
			setGinItems(data[0]);
			setPartStates(data[3].partState);
			setTypeStates(data[3].typeState);
			setGinItemsRows(items);
			setGinDocument(option(data[4]));
			setLoading(false);
		}).catch(err => console.error(err));
	};

	useEffect(() => {
		fetchData()
		// eslint-disable-next-line
	}, []);

	const onOpen = () => {
		setOpen(true);
	};

	const onClose = () => {
		setOpen(false);
		fetchData();
		setGinItem(none)
	};

	const onEdit = (item: GinPartStateTableRow) => {
		const ginItem = ginItems.filter(gi => gi.id === item.id)[0];
		setGinItem(option(ginItem));
		setOpen(true);
	};

	const onDelete = (item: GinPartStateTableRow) => {
		if (window.confirm("Are you sure you want to delete this item?")) {
			apiInstance.ginDocumentApi.deleteGinItem(item.id).then(() => {
				fetchData()
			});
		}
	};

	const getTotalValue = (items: GinPartStateTableRow[]) => {
		return (items.reduce(((ac, current) => ac + current.totalValue), 0) / 10000).toFixed(2)
	};

	const onDocumentAccept = () => {
		if (window.confirm("Accepted document can not be edited any more.\nAccept anyway?")) {
			setSubmitting(true);
			apiInstance.ginDocumentApi.acceptGinDocument(ginId as string).then(() => {
				setSubmitting(false);
				fetchData();
			}).catch((err: any) => {
				setSubmitting(false);
				console.error(err)
			})
		}
	};

	const technologyItems = ginItemsRows.filter(item => item.fromTechnology);
	const ownItems = ginItemsRows.filter(item => !item.fromTechnology);

	return (
		<Paper className={classes.tablePaper}>
			{isLoading ?
				<TabSpinner/> :
				<>
					{
						ginDocument.map(doc => {
							return <Typography style={{margin: 16}} variant={"body1"}>GIN Document number: <strong>{doc.documentNumber}</strong></Typography>
						}).orNull
					}
					<Divider/>
					{technologyItems.length !== 0 &&
					<ExpansionPanel>
						<ExpansionPanelSummary
							expandIcon={<ExpandMoreIcon/>}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography variant={"body1"}>Items from technology: {getTotalValue(technologyItems)} PLN</Typography>
						</ExpansionPanelSummary>
						<ExpansionPanelDetails>
							<div style={{width: "100%"}}>
								<Divider/>
								<GinItemsTable ginItems={technologyItems} onEdit={onEdit} onDelete={onDelete}
											   ginDocument={ginDocument}/>
								<Typography variant={"body1"} style={{marginTop: 16, textAlign: "right"}}>
									Total value: {getTotalValue(technologyItems)} PLN</Typography>
							</div>
						</ExpansionPanelDetails>
					</ExpansionPanel>
					}
					<br/>
					<Divider/>
					<GinItemsTable ginItems={ownItems} onEdit={onEdit} onDelete={onDelete} ginDocument={ginDocument}/>
					<GinItemsModal ginItem={ginItem} isOpen={isOpen} parts={parts} partStates={partStates} typeStates={typeStates} onClose={onClose}/>
					<Typography variant={"body1"} style={{margin: 16, textAlign: "right"}}>
						Total value: {getTotalValue(ownItems)} PLN</Typography>
					<AddMultiContainer>
						{ginDocument.map(doc => {
							return doc.acceptedAt.isEmpty && (
								<>
									<Button variant={"contained"} color={"primary"}
											onClick={onDocumentAccept}
											disabled={ginItems.length === 0}>
										{isSubmitting ?
											<CircularProgress style={{color: "white"}} size={24}/>
											: "Accept Document"}
									</Button>
									<Fab size={"small"} color={"primary"}
										 onClick={onOpen}><AddIcon/></Fab>

								</>
							)

						}).getOrElseValue(false)
						}
					</AddMultiContainer>
				</>
			}
		</Paper>
	);
};

export default GinItems

export interface GinPartStateTableRow extends GinPartState {
	unitName: string,
	partDescriptionEn: string,
	partNumber: string,
}