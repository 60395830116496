import GoogleCode from "../models/GoogleCode";
import {AxiosInstance} from "axios";
import {AuthTokenApiDto} from "../models/_RestModels";
import AuthToken from "../models/AuthToken";

export default class AuthApi {
    constructor(public readonly axiosInstance: AxiosInstance) {}

    logIn(googleCode: GoogleCode): Promise<AuthTokenApiDto> {
        const url = "/api/login";
        return this.axiosInstance.post(url, googleCode).then(resp => AuthToken.fromJson(resp.data as AuthTokenApiDto));
    }
}