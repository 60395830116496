import React from "react";
import {createStyles, makeStyles, StyledComponentProps, Theme} from "@material-ui/core";

interface ReactProps {
    children: React.ReactNode
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    addContainer: {
        display: "flex",
        justifyContent: "space-between",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    }
}));

type IProps = ReactProps & StyledComponentProps;

const SettingsTableHeader:React.FC<IProps> = (props:IProps) => {
    const classes = useStyles();
    return (
        <div className={classes.addContainer}>
            {props.children}
        </div>
    );
};

export default SettingsTableHeader;