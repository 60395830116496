import React, {useEffect, useState} from "react";
import Part from "../../models/Part";
import {none, Option} from "ts-option";
import {useHistory, useParams} from "react-router-dom";
import {Field, Form, Formik, FormikHelpers, FormikProps} from "formik";
import {apiInstance} from "../../api/Api";
import {
	Grid,
	Typography,
	MenuItem,
	Select,
	FormControl,
	InputLabel,
	Button,
	CircularProgress,
	makeStyles, FormHelperText, Divider, Fab
} from "@material-ui/core";
import PartUnit from "../../models/PartUnit";
import PartCategory from "../../models/PartCategory";
import PartType from "../../models/PartType";
import FormTextField from "../common/FormTextField";
import {defaultStyles} from "../../styles/defaultStyles";
import * as Yup from 'yup';
import IconAdd from "@material-ui/icons/Add";
import PartTypeModal from "../settings/part_types/PartTypesModal";

export interface PartsFormValues {
	partNumber: string,
	manufacturer: string,
	descriptionEn: string,
	descriptionPl: string,
	categoryId: string,
	typeId: string,
	unitId: string
}

interface IProps {
	part: Option<Part>,
}


const validationSchema = Yup.object().shape({
	partNumber: Yup.string()
		.required('Part Number is required'),
	manufacturer: Yup.string()
		.required('Manufacturer is required'),
	descriptionEn: Yup.string()
		.required('English description is required'),
	descriptionPl: Yup.string()
		.required('Polish description is required'),
	unitId: Yup.string().test("is-selected", "Part Unit is required", (value => value !== "select")),
});

const useStyles = makeStyles(defaultStyles);

const PartsForm: React.FC<IProps> = (props: IProps) => {

	const [isLoading, setLoading] = useState<boolean>(true);
	const [isSubmitting, setSubmitting] = useState<boolean>(false);
	const [types, setTypes] = useState<PartType[]>([]);
	const [units, setUnits] = useState<PartUnit[]>([]);
	const [categories, setCategories] = useState<PartCategory[]>([]);
	const [isTypeModalVisible, setTypeModalVisible] = useState(false);


	const classes = useStyles();

	const fetchData = async () => {
		setLoading(true);
		const categories = await apiInstance.partsApi.fetchPartCategories();
		const units = await apiInstance.partsApi.fetchPartUnits();
		const types = await apiInstance.partsApi.fetchPartTypes();
		setTypes(types);
		setCategories(categories);
		setUnits(units);
	};

	const reloadParts = (formikProps: FormikProps<PartsFormValues>) => async (partType: PartType) => {
		const types = await apiInstance.partsApi.fetchPartTypes();
		setTypes(types);
		formikProps.setFieldValue("typeId", partType.id);
		setTypeModalVisible(false);
	};

	useEffect(() => {
		fetchData()
			.then(() => setLoading(false))
			.catch((err: Error) => {
				// TODO Handle error
				setLoading(false);
				console.error(err)
			});
	}, []);

	const initialValues: PartsFormValues = props.part.map((part: Part) => {
		const {partNumber, manufacturer, descriptionEn, descriptionPl, categoryId, typeId, unitId} = part;
		return ({
			partNumber, manufacturer, descriptionEn, descriptionPl, categoryId, typeId, unitId
		})
	}).getOrElseValue({
		partNumber: "",
		manufacturer: "",
		descriptionEn: "",
		descriptionPl: "",
		categoryId: "select",
		typeId: "select",
		unitId: "select"
	});

	const {partId} = useParams();
	const history = useHistory();
	return (
		<Formik initialValues={initialValues}
				enableReinitialize={true}
				validateOnBlur={true}
				validateOnChange={false}
				onSubmit={(values: PartsFormValues, actions: FormikHelpers<PartsFormValues>) => {
					// TODO Handle is submitting
					const submitAction = props.part.isDefined ?
						apiInstance.partsApi.putPart((partId as string),
							values.partNumber,
							values.manufacturer,
							values.descriptionEn,
							values.descriptionPl,
							values.categoryId,
							values.typeId,
							values.unitId
						) :
						apiInstance.partsApi.postPart(
							values.partNumber,
							values.manufacturer,
							values.descriptionEn,
							values.descriptionPl,
							values.categoryId,
							values.typeId,
							values.unitId
						);
					setSubmitting(true);
					submitAction.then(() => {
						setSubmitting(false);
						actions.resetForm();
						if (props.part.isDefined) {
							history.push("/parts")
						}
					})
						.catch((err: any) => {
							//TODO Handle error nicely
							setSubmitting(false);
							if (err.response.status === 409) {
								actions.setFieldError("partNumber", "Provided part number is already in use");
							}
							else {
								console.error(err)
							}
						})
				}}
				validationSchema={validationSchema}
		>
			{(formikProps: FormikProps<PartsFormValues>) => (
				<Form noValidate={true}>
					<Grid container>
						<Grid container item lg={6} md={12} spacing={3}>
							<Grid item xs={12}>
								{props.part.isDefined ?
									<Typography variant={"h6"}>Edit Part</Typography> :
									<Typography variant={"h6"}>Create New Part</Typography>
								}
							</Grid>
							<Grid item xs={12} md={6}>
								<Field
									id="partNumber"
									label="Part Number"
									name="partNumber"
									required={true}
									error={formikProps.errors.partNumber && formikProps.touched.partNumber}
									helperText={formikProps.errors.partNumber && formikProps.touched.partNumber ?
										formikProps.errors.partNumber : ""}
									as={FormTextField}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<Field
									id="manufacturer"
									label="Manufacturer"
									name="manufacturer"
									required={true}
									error={formikProps.errors.manufacturer && formikProps.touched.manufacturer}
									helperText={formikProps.errors.manufacturer && formikProps.touched.manufacturer ?
										formikProps.errors.manufacturer : ""}
									as={FormTextField}
								/>
							</Grid>
							<Grid item xs={12}>
								<Field
									id="descriptionEn"
									label="Description En"
									name="descriptionEn"
									required={true}
									error={formikProps.errors.descriptionEn && formikProps.touched.descriptionEn}
									helperText={formikProps.errors.descriptionEn && formikProps.touched.descriptionEn ?
										formikProps.errors.descriptionEn : ""}
									as={FormTextField}
								/>
							</Grid>
							<Grid item xs={12}>
								<Field
									id="descriptionPl"
									label="Description Pl"
									name="descriptionPl"
									required={true}
									error={formikProps.errors.descriptionPl && formikProps.touched.descriptionPl}
									helperText={formikProps.errors.descriptionPl && formikProps.touched.descriptionPl ?
										formikProps.errors.descriptionPl : ""}
									as={FormTextField}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControl variant={"outlined"} fullWidth={true}
											 error={Boolean(formikProps.errors.unitId && formikProps.touched.unitId)}>
									<InputLabel>Select Unit</InputLabel>
									<Field name={"unitId"} autoWidth={true} labelWidth={85} as={Select} margin={"dense"}
										   disabled={isLoading}>
										<MenuItem value={"select"}>Select Unit</MenuItem>
										{units.sort((a,b) => a.displayName.localeCompare(b.displayName)).map(unit => <MenuItem
											value={unit.id} key={unit.id}>{unit.displayName}</MenuItem>)}
									</Field>
									{formikProps.errors.unitId && formikProps.touched.unitId &&
									<FormHelperText>{formikProps.errors.unitId}</FormHelperText>
									}
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<Divider/>
							</Grid>
							<Grid item xs={6}>
								<FormControl variant={"outlined"} fullWidth={true}>
									<InputLabel>Select Category</InputLabel>
									<Field name={"categoryId"} autoWidth={true} labelWidth={120} as={Select}
										   margin={"dense"}
										   disabled={isLoading}>
										<MenuItem value={"select"}>Select Category</MenuItem>
										{categories.sort((a,b) => a.name.localeCompare(b.name)).map(category => <MenuItem
											value={category.id} key={category.id}>{category.name}</MenuItem>)}
									</Field>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<Grid container>
									<Grid item xs>
										<FormControl variant={"outlined"} fullWidth={true}>
											<InputLabel>Select Type</InputLabel>
											<Field name={"typeId"} autoWidth={true} labelWidth={90} as={Select} margin={"dense"}
												   disabled={isLoading}>
												<MenuItem value={"select"}>Select Type</MenuItem>
												{types.sort((a,b) => a.typeId.localeCompare(b.typeId)).map(type => <MenuItem
													value={type.id} key={type.id}>{type.typeId}</MenuItem>)}
											</Field>
										</FormControl>
									</Grid>
									<Grid item>
										<Fab size={"small"} color={"primary"} style={{marginLeft: 8}}
											 onClick={() => setTypeModalVisible(true)}>
											<IconAdd/>
										</Fab>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<div className={classes.buttonsRow}>
									<Button onClick={() => history.goBack()}>Back</Button>
									<Button type="submit" color={"primary"} variant={"contained"}>
										{isSubmitting ?
											<CircularProgress style={{color: "white"}} size={24}/> :
											props.part.isDefined ? "Update" : "Create"}
									</Button>
								</div>
							</Grid>
							<PartTypeModal isOpen={isTypeModalVisible} partType={none}
										   onClose={() => setTypeModalVisible(false)}
										   onSuccess={reloadParts(formikProps)}/>
						</Grid>
					</Grid>
				</Form>
			)}
		</Formik>
	);
};

export default PartsForm;