import React, {useEffect, useState} from "react";
import {Paper, StyledComponentProps, withStyles} from "@material-ui/core";
import {defaultStyles} from "../../styles/defaultStyles";
import SuppliersTable from "./SuppliersTable";
import Supplier from "../../models/Supplier";
import TabSpinner from "../common/TabSpinner";
import {apiInstance} from "../../api/Api";
import {useHistory} from "react-router-dom";
import Scrollable from "../common/Scrollable";
import AddFab from "../common/AddFab";

type IProps = StyledComponentProps

const Suppliers: React.FunctionComponent<IProps> = (props: IProps) => {

	const [isLoading, setLoading] = useState<boolean>(true);
	const [suppliers, setSuppliers] = useState<Supplier[]>([]);
	const history = useHistory();

	useEffect(() => {
		const fetchData = async () => {
			const data = await apiInstance.suppliersApi.fetchSuppliers();
			setSuppliers(data)
		};
		fetchData()
			.then(() => setLoading(false))
			.catch((err) => {
				// TODO Handle error
			});
	}, []);

	const {classes}: any = props;
	return (
		<Paper className={classes.tablePaper}>
			{isLoading ?
				<TabSpinner/> :
				<>
					<Scrollable>
						<SuppliersTable suppliers={suppliers}/>
					</Scrollable>
					<AddFab onClick={() => history.push("/suppliers/create")}/>
				</>
			}
		</Paper>
	);
};

export default withStyles(defaultStyles)(Suppliers);