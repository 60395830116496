import React, {useState} from "react";
import {Option} from "ts-option";
import {
	Button, Checkbox, CircularProgress,
	FormControl, FormControlLabel,
	FormHelperText,
	Grid, IconButton, InputAdornment,
	InputLabel,
	makeStyles,
	MenuItem,
	Modal,
	Select, TextField,
	Typography
} from "@material-ui/core";
import {defaultStyles} from "../../../styles/defaultStyles";
import Part from "../../../models/Part";
import {apiInstance} from "../../../api/Api";
import {Formik, Form, FormikHelpers, Field, FormikProps} from "formik";
import * as Yup from "yup";
import FormTextField from "../../common/FormTextField";
import Decimal from "decimal.js";
import IGrnDocument from "../../../models/IGrnDocument";
import PartUnit from "../../../models/PartUnit";
import IGrnDocumentItem from "../../../models/IGrnDocumentItem";
import CloseIcon from "@material-ui/icons/Close";

interface IProps {
	isOpen: boolean,
	iGrnDocument: Option<IGrnDocument>,
	iGrnItem: Option<IGrnDocumentItem>,
	parts: Part[],
	units: PartUnit[],
	onClose: () => void,
}

const validationSchema = Yup.object().shape({
	quantity: Yup.number()
		.required('Quantity is required')
		.positive('Quantity have to be positive number'),
	totalValue: Yup.number()
		.required('Total value is required')
		.positive('Total value have to be positive number'),
	partId: Yup.string().test("is-selected", "Part is required", (value => value !== "select")),
});

interface IGrnItemFormValues {
	partId: string,
	quantity: string,
	totalValue: string,
}

const useStyles = makeStyles(defaultStyles);

const IGrnItemsModal: React.FC<IProps> = (props: IProps) => {

	const [isSubmitting, setSubmitting] = useState<boolean>(false);
	const [isAnotherOne, setAnotherOne] = useState<boolean>(false);
	const [filterString, setFilterString] = useState<string>("");

	const {isOpen, iGrnDocument, iGrnItem, onClose, parts, units} = props;
	const classes = useStyles({});

	const initialValues: IGrnItemFormValues = iGrnItem.map(item => {
		const {partId, quantity, totalValue} = item;
		return ({partId, quantity: quantity.toString(), totalValue: (totalValue / 10000).toString()})
	}).getOrElseValue({
		partId: "select",
		quantity: "",
		totalValue: ""
	});

	const filteredParts = parts.filter(part => {
		return filterString.length ? part.partNumber.toLowerCase().includes(filterString.toLowerCase()) : true
	}).sort((a, b) => a.partNumber.localeCompare(b.partNumber));

	return (
		<Modal
			open={isOpen}
			onClose={() => onClose()}
			style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
		>
			<div className={classes.extraWideModal}>
				<Formik
					initialValues={initialValues}
					enableReinitialize={true}
					validateOnBlur={true}
					validateOnChange={false}
					validationSchema={validationSchema}
					onSubmit={(values: IGrnItemFormValues, actions: FormikHelpers<IGrnItemFormValues>) => {
						setSubmitting(true);
						const part = parts.filter((part) => part.id === values.partId)[0];
						const unit = units.filter(u => u.id === part.unitId)[0];
						const totalValue = new Decimal(values.totalValue).mul(10000).toNumber();
						const quantity = new Decimal(values.quantity).toDecimalPlaces(2).toNumber();
						const submitAction = iGrnItem.isDefined ?
							apiInstance.iGrnDocumentApi.putIGrnItem(
								iGrnDocument.get.id,
								quantity,
								totalValue
							) :
							apiInstance.iGrnDocumentApi.postIGrnItem(
								iGrnDocument.get.id,
								values.partId,
								part.partNumber,
								part.descriptionPl,
								quantity,
								totalValue,
								unit.namePl,
								part.typeId,
							);
						submitAction
							.then(() => {
								setSubmitting(false);
								actions.resetForm();
								if (!isAnotherOne)
									onClose();
							})
							.catch((err: any) => {
								//TODO Handle error nicely
								setSubmitting(false);
								console.error(err)
							})
					}}
				>
					{(formikProps: FormikProps<IGrnItemFormValues>) => (
						<Form noValidate={true}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography variant={"h6"}>{
										iGrnItem.isDefined ?
											"Update IGrn Document Item" :
											"Create IGrn Document Item"
									}</Typography>
								</Grid>
								{iGrnItem.isEmpty &&
								<>
									<Grid item xs={3}>
										<TextField
											variant={"outlined"}
											fullWidth={true}
											label={"Parts Filter"}
											margin={"dense"}
											id="partsFilter"
											value={filterString}
											onChange={(evt) => {
												formikProps.setFieldValue("partId", "select");
												setFilterString(evt.target.value)
											}}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															size={"small"}
															onClick={() => setFilterString("")}
															disabled={!filterString.length}
														>
															<CloseIcon/>
														</IconButton>
													</InputAdornment>
												)
											}}
										/>
									</Grid>
									<Grid item xs={3}>
										<FormControl variant={"outlined"} fullWidth={true} style={{marginTop: 8}}
													 error={Boolean(
														 formikProps.errors.partId && formikProps.touched.partId)}>
											<InputLabel>Select Part</InputLabel>
											<Field name={"partId"} autoWidth={true} labelWidth={85} as={Select}
												   margin={"dense"} required={true}>
												<MenuItem value={"select"}>Select Part</MenuItem>
												{filteredParts.map(
													part => <MenuItem
														value={part.id} key={part.id}>{part.partNumber}</MenuItem>)}
											</Field>
											{formikProps.errors.partId && formikProps.touched.partId &&
											<FormHelperText>{formikProps.errors.partId}</FormHelperText>
											}
										</FormControl>
									</Grid>
								</>
								}
								<Grid item xs={iGrnItem.isDefined ? 6 : 3}>
									<Field
										id="quantity"
										label="Quantity"
										name="quantity"
										required={true}
										type={"number"}
										error={formikProps.errors.quantity && formikProps.touched.quantity}
										helperText={formikProps.errors.quantity && formikProps.touched.quantity ?
											formikProps.errors.quantity : ""}
										as={FormTextField}
									/>
								</Grid>
								<Grid item xs={iGrnItem.isDefined ? 6 : 3}>
									<Field
										id="totalValue"
										label="Total Value"
										name="totalValue"
										required={true}
										type={"number"}
										error={formikProps.errors.totalValue && formikProps.touched.totalValue}
										helperText={formikProps.errors.totalValue && formikProps.touched.totalValue ?
											formikProps.errors.totalValue : ""}
										as={FormTextField}
									/>
								</Grid>
								<Grid item xs={12}>
									<div className={classes.buttonsRow}>
										<Button onClick={onClose}>Close</Button>
										{iGrnItem.isEmpty &&
										<FormControlLabel
											control={
												<Checkbox
													checked={isAnotherOne}
													onChange={() => setAnotherOne(!isAnotherOne)}
													value={isAnotherOne}
													color="primary"
												/>
											}
											label="Create another one"
										/>
										}
										<Button type="submit" color={"primary"} variant={"contained"}>
											{isSubmitting ?
												<CircularProgress style={{color: "white"}} size={24}/> :
												props.iGrnItem.isDefined ? "Update" : "Create"}
										</Button>
									</div>
								</Grid>
							</Grid>
						</Form>
					)}
				</Formik>
			</div>
		</Modal>
	);
};

export default IGrnItemsModal