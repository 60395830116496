import React, {useEffect, useState} from "react";
import {Fab, Paper, StyledComponentProps, Typography, withStyles} from "@material-ui/core";
import {defaultStyles} from "../../../styles/defaultStyles";
import TabSpinner from "../../common/TabSpinner";
import {apiInstance} from "../../../api/Api";
import SettingsTableHeader from "../../common/SettingsTableHeader";
import PartType from "../../../models/PartType";
import AddContainer from "../../common/AddContainer";
import {none} from "ts-option";
import PartTypeModal from "./PartTypesModal";
import PartTypesTable from "./PartTypesTable";
import AddIcon from '@material-ui/icons/Add';

type IProps = StyledComponentProps

const PartTypes: React.FunctionComponent<IProps> = (props: IProps) => {

	const [isLoading, setLoading] = useState<boolean>(true);
	const [isOpen, setOpen] = useState<boolean>(false);
	const [partTypes, setPartTypes] = useState<PartType[]>([]);

	const fetchData = async () => {
		const partTypes = await apiInstance.partsApi.fetchPartTypes();
		setPartTypes(partTypes)
	};

	const onClose = () => {
		setOpen(false);
		fetchData().then()
	};

	useEffect(() => {
		fetchData()
			.then(() => setLoading(false))
			.catch(() => {
				// TODO Handle error
				setLoading(false)
			});
	}, []);

	const {classes}: any = props;
	return (
		<Paper className={classes.settingsTablePaper}>
			{isLoading ?
				<TabSpinner/> :
				<>
					<SettingsTableHeader>
						<Typography variant={"h6"}>Part Types</Typography>
					</SettingsTableHeader>
					<PartTypesTable partTypes={partTypes} onClose={onClose}/>
					<AddContainer>
						<Fab size={"small"} color={"primary"} onClick={() => setOpen(true)}><AddIcon/></Fab>
					</AddContainer>
					<PartTypeModal isOpen={isOpen}
								   partType={none}
								   onSuccess={() => onClose()}
								   onClose={() => onClose()}/>
				</>
			}
		</Paper>
	);
};

export default withStyles(defaultStyles)(PartTypes);