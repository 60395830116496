/**
 * Capitalize first letter in text.
 * @param text text to capitalize.
 * @returns {string} string with capitalized first letter.
 */
export function capitalizeString(text: string): string {
	return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export function capitalizeSnakeCase(text: string): string {
	if (text.includes("_"))
		return text.split("_").map(part => {
			if (["gin", "egin", "grn", "igrn"].includes(part.toLowerCase())) {
				return part.toUpperCase();
			}
			return capitalizeString(part)
		}).join(" ");
	else
		return capitalizeString(text)
}