import React, {useState} from "react";
import {
	Button, CircularProgress,
	Grid,
	makeStyles,
	Modal,
	Typography
} from "@material-ui/core";
import {Field, Form, Formik, FormikProps} from "formik";
import * as Yup from "yup";
import {defaultStyles} from "../../styles/defaultStyles";
import TechnologyItem from "../../models/TechnologyItem";
import FormTextField from "../common/FormTextField";
import Technology from "../../models/Technology";
import {none, Option} from "ts-option";
import {apiInstance} from "../../api/Api";
import PartValidate from "../../models/PartValidate";
import PartType from "../../models/PartType";
import Part from "../../models/Part";
import ValidationRequest from "../../models/ValidationRequest";
import TechnologyValidationTable from "./TechnologyValidationTable";

const validationSchema = Yup.object().shape({
	quantity: Yup.number()
		.required('Quantity is required')
		.min(0, 'Quantity have to be greater than 0'),
});

interface IProps {
	isOpen: boolean,
	technologyItems: TechnologyItem[],
	parts: Part[],
	types: PartType[],
	technology: Option<Technology>,
	onClose: () => void,
}

interface TechnologyValidateFormValues {
	quantity: string,
}

const useStyles = makeStyles(defaultStyles);

const TechnologyValidateModal: React.FC<IProps> = (props: IProps) => {

	const {isOpen, technologyItems, onClose, parts, types} = props;
	const [isSubmitting, setSubmitting] = useState(false);
	const [validationRequest, setValidationRequest] = useState<Option<ValidationRequest>>(none);

	const classes = useStyles({});

	const initialValues: TechnologyValidateFormValues = {quantity: "0"};

	return (
		<Modal
			open={isOpen}
			onClose={() => {
				setValidationRequest(none);
				onClose()
			}}
			style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
		>
			<div className={classes.extraWideModal}>
				<Formik
					initialValues={initialValues}
					enableReinitialize={false}
					validateOnBlur={true}
					validateOnChange={false}
					validationSchema={validationSchema}
					onSubmit={((values: TechnologyValidateFormValues) => {
						setSubmitting(true);
						const validationItems = technologyItems.map(item => {
							const {partId, typeId, quantity} = item;
							return new PartValidate(partId, typeId.orNull, quantity * parseFloat(values.quantity))
						});
						apiInstance.partsApi.validateTechnology(validationItems).then(
							validationRequest => {
								setValidationRequest(validationRequest);
								setSubmitting(false);
							}).catch(err => console.error(err))
					})}>
					{(formikProps: FormikProps<TechnologyValidateFormValues>) => (
						<Form noValidate={true}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography variant={"h6"}>
										Validate technology items
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Field
										id="quantity"
										label="Quantity"
										name="quantity"
										required={true}
										type={"number"}
										error={formikProps.errors.quantity && formikProps.touched.quantity}
										helperText={formikProps.errors.quantity && formikProps.touched.quantity ?
											formikProps.errors.quantity : ""}
										as={FormTextField}
									/>
								</Grid>
								{
									validationRequest.isDefined && <>
										<Grid item xs={12}>
											{!validationRequest.get.valid &&
											<Typography className={classes.textError}
														style={{textAlign: "center", paddingBottom: 16}}>
												Not enough elements for requested technology quantity
											</Typography>}
											<TechnologyValidationTable reqItems={validationRequest.get.items}
																	   parts={parts} types={types}/>
										</Grid>
									</>
								}

								<Grid item xs={12}>
									<div className={classes.modalButtonsRow}>
										<Button onClick={() => {
											formikProps.resetForm();
											setValidationRequest(none);
											onClose();
										}}>Close</Button>
										<Button type="submit" color={"primary"} variant={"contained"}>
											{isSubmitting ? <CircularProgress style={{color: "white"}} size={24}/> : "Validate"}
										</Button>
									</div>
								</Grid>
							</Grid>
						</Form>
					)}
				</Formik>
			</div>
		</Modal>
	);
};

export default TechnologyValidateModal