import React, {useEffect, useState} from "react";
import {makeStyles, Paper} from "@material-ui/core";
import {defaultStyles} from "../../styles/defaultStyles";
import {apiInstance} from "../../api/Api";
import TabSpinner from "../common/TabSpinner";
import {none, Option, option} from "ts-option";
import Client from "../../models/Client";
import EGinDocument from "../../models/EGinDocument";
import EGinDocumentsTable from "./EGinDocumentsTable";
import EGinDocumentsModal from "./EGinDocumentsModal";
import Scrollable from "../common/Scrollable";
import AddFab from "../common/AddFab";

const useStyles = makeStyles(defaultStyles);

const EGinDocuments: React.FC<{}> = () => {

	const classes = useStyles();

	const [isLoading, setLoading] = useState<boolean>(true);
	const [isOpen, setOpen] = useState(false);
	const [egins, setEgins] = useState<EGinDocument[]>([]);
	const [egin, setEgin] = useState<Option<EGinDocument>>(none);
	const [clients, setClients] = useState<Client[]>([]);

	const fetchData = async () => {
		const clients = await apiInstance.clientApi.fetchClients();
		const egins = await apiInstance.eGinDocumentApi.fetchEGinDocuments();
		setClients(clients);
		setEgins(egins);
		setLoading(false);
	};

	useEffect(() => {
		fetchData()
			.then(() => setLoading(false))
			.catch((err: Error) => {
				// TODO Handle error
				console.error(err);
				setLoading(false)
			});
	}, []);

	const onEdit = (document: EGinDocument) => {
		setOpen(true);
		setEgin(option(document))
	};

	const onDelete = (document: EGinDocument) => {
		if (window.confirm("Delete EGIN Document?")) {
			apiInstance.eGinDocumentApi.deleteEGinDocument(document.id).then(() => {
				setLoading(true);
				fetchData()
					.then(() => setLoading(false))
					.catch((err: any) => {
						console.error(err);
						setLoading(false)
					});
			}).catch((err: any) => console.error(err))
		}
	};

	const onCreate = () => {
		setOpen(true)
	};

	const onClose = () => {
		setOpen(false);
		setLoading(true);
		fetchData()
			.then(() => setLoading(false))
			.catch(() => {
				// TODO Handle error
				setLoading(false)
			});
		setEgin(none)
	};

	return (
		<Paper className={classes.tablePaper}>
			{isLoading ?
				<TabSpinner/> :
				<>
					<Scrollable>
						<EGinDocumentsTable egins={egins} onEdit={onEdit} onDelete={onDelete}/>
					</Scrollable>
					<EGinDocumentsModal isOpen={isOpen} clients={clients} eGinDocument={egin} onClose={onClose}/>
					<AddFab onClick={onCreate}/>
				</>
			}
		</Paper>
	);
};

export default EGinDocuments