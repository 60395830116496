import React, {useState} from "react";
import {
	Button, CircularProgress, Grid, makeStyles, Modal, Typography
} from "@material-ui/core";
import {Field, Form, Formik, FormikProps} from "formik";
import {defaultStyles} from "../../styles/defaultStyles";
import {apiInstance} from "../../api/Api";
import FormTextField from "../common/FormTextField";
import * as Yup from "yup";
import {KeyboardDatePicker, MaterialUiPickersDate} from "@material-ui/pickers";
import {DEFAULT_DATE_FORMAT} from "../../common/Constants";
import GinDocument from "../../models/GinDocument";
import moment from "moment";
import {useHistory} from "react-router-dom";
import DateUtils from "../../common/DateUtils";

const validationSchema = Yup.object().shape({
	description: Yup.string()
		.required('Description is required'),
});

interface IProps {
	isOpen: boolean,
	ginDocument: GinDocument,
	onClose: () => void,
}

interface iGrnCreateFormValues {
	description: string,
	documentDate: Date,
}

const useStyles = makeStyles(defaultStyles);

const IGrnCreateModal: React.FC<IProps> = (props: IProps) => {
	const {isOpen, ginDocument, onClose} = props;
	const [isSubmitting, setSubmitting] = useState(false);
	const history = useHistory();

	const classes = useStyles({});

	const initialValues: iGrnCreateFormValues = {
		description: ginDocument.description,
		documentDate: new Date(),
	};

	return (
		<Modal
			open={isOpen}
			onClose={() => onClose()}
			style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
			<div className={classes.wideModal}>
				<Formik
					initialValues={initialValues}
					enableReinitialize={true}
					validateOnBlur={true}
					validateOnChange={false}
					validationSchema={validationSchema}
					onSubmit={((values: iGrnCreateFormValues, actions) => {
						setSubmitting(true);
						apiInstance.iGrnDocumentApi.postIGrnDocument(
							values.description,
							DateUtils.toUTCDate(values.documentDate),
							ginDocument.id
						).then((iGrnDocument) => {
							if (ginDocument.technologyId.isDefined && !ginDocument.internalUse) {
								Promise.all([
									apiInstance.technologiesApi.fetchTechnology(ginDocument.technologyId.get),
									apiInstance.ginDocumentApi.fetchGinItems(ginDocument.id),
									apiInstance.partsApi.fetchParts(),
									apiInstance.partsApi.fetchPartUnits(),
								]).then(data => {
									const [technology, ginItems, parts, units] = data;
									const technologyPart = parts.filter(part => part.id === technology.partId)[0];
									const partUnit = technologyPart ? units.filter(unit => unit.id === technologyPart.unitId)[0] :
										undefined;
									const totalValue = ginItems.reduce(((ac, current) => ac + current.totalValue), 0);
									if (technologyPart && partUnit) {
										apiInstance.iGrnDocumentApi.postIGrnItem(iGrnDocument.id, technologyPart.id,
											technologyPart.partNumber, technologyPart.descriptionPl,
											ginDocument.technologyQuantity.get, totalValue, partUnit.namePl,
											technologyPart.typeId).then(() => {
											setSubmitting(false);
											actions.resetForm();
											history.push(`igrn_documents/${iGrnDocument.id}/items`);
										}).catch((err) => console.error(err))
									} else {
										setSubmitting(false);
										actions.resetForm();
										history.push(`igrn_documents/${iGrnDocument.id}/items`);
									}
								})
							}
						}).catch((err: any) => {
							setSubmitting(false);
							console.error(err)
						})
					})}>
					{(formikProps: FormikProps<iGrnCreateFormValues>) => (
						<Form noValidate={true}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography variant={"h6"}>Create New IGRN Document</Typography>
									<Field
										id="description"
										label="Description"
										name="description"
										required={true}
										error={formikProps.errors.description && formikProps.touched.description}
										helperText={formikProps.errors.description &&
										formikProps.touched.description ?
											formikProps.errors.description : ""}
										as={FormTextField}
									/>
								</Grid>
								<Grid item xs={12}>
									<KeyboardDatePicker
										autoOk={true}
										disableToolbar
										inputVariant="outlined"
										required={true}
										fullWidth={true}
										error={Boolean(formikProps.errors.documentDate) &&
										Boolean(formikProps.touched.documentDate)}
										maxDate={new Date()}
										format={DEFAULT_DATE_FORMAT}
										margin="dense"
										id="date-picker-inline"
										label="Document Date"
										value={formikProps.values.documentDate}
										onChange={(date: MaterialUiPickersDate) => {
											formikProps.setFieldValue("documentDate", date ? moment(date).toDate() : null)
										}}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<div className={classes.modalButtonsRow}>
										<Button onClick={() => onClose()}>Close</Button>
										<Button type="submit" color={"primary"} variant={"contained"}>
											{isSubmitting ? <CircularProgress style={{color: "white"}} size={24}/> : "Create"}
										</Button>
									</div>
								</Grid>
							</Grid>
						</Form>
					)}
				</Formik>
			</div>
		</Modal>
	);
};

export default IGrnCreateModal