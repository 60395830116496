import {ValidationRequestApiDto} from "./_RestModels";
import ValidateRequestItem from "./ValidateRequestItem";

export default class ValidationRequest {
	constructor(public readonly valid: boolean,
		public readonly items: ValidateRequestItem[]) {}

	public static fromJson(json: ValidationRequestApiDto): ValidationRequest {
		const {valid} = json;
		const items = json.items.map(vr => ValidateRequestItem.fromJson(vr));
		return new ValidationRequest(valid, items);
	}
}