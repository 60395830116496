import Axios, {AxiosInstance} from "axios";
import AuthApi from "./AuthApi";
import store from "./../redux/strore";
import SuppliersApi from "./SuppliersApi";
import PartsApi from "./PartsApi";
import SettingsApi from "./SettingsApi";
import InvoicesApi from "./InvoicesApi";
import ExternalApi from "./ExternalApi";
import TechnologiesApi from "./TechnologyApi";
import GinDocumentApi from "./GinDocumentApi";
import IGrnDocumentApi from "./IGrnDocumentApi";
import ClientApi from "./ClientApi";
import EGinDocumentApi from "./EGinDocumentApi";
import {logOut} from "../redux/auth/authActions";


class ApiInstance {
	api: Api;

	constructor() {
		this.api = new Api();
	}

	create() {
		this.api = new Api();
	}

	get authApi(): AuthApi {
		return this.api.authApi;
	}

	get suppliersApi(): SuppliersApi {
		return this.api.suppliersApi;
	}

	get partsApi(): PartsApi {
		return this.api.partsApi;
	}

	get settingsApi(): SettingsApi {
		return this.api.settingsApi;
	}

	get invoicesApi(): InvoicesApi {
		return this.api.invoicesApi;
	}

	get externalApi(): ExternalApi {
		return this.api.externalApi;
	}

	get technologiesApi(): TechnologiesApi {
		return this.api.technologiesApi;
	}

	get ginDocumentApi(): GinDocumentApi {
		return this.api.ginDocumentApi;
	}

	get iGrnDocumentApi(): IGrnDocumentApi {
		return this.api.iGrnDocumentApi;
	}

	get eGinDocumentApi(): EGinDocumentApi {
		return this.api.eGinDocumentApi;
	}


	get clientApi(): ClientApi {
		return this.api.clientApi;
	}
}

class Api {
	private readonly axiosInstance: AxiosInstance;
	public readonly authApi: AuthApi;
	public readonly suppliersApi: SuppliersApi;
	public readonly partsApi: PartsApi;
	public readonly settingsApi: SettingsApi;
	public readonly invoicesApi: InvoicesApi;
	public readonly externalApi: ExternalApi;
	public readonly technologiesApi: TechnologiesApi;
	public readonly ginDocumentApi: GinDocumentApi;
	public readonly iGrnDocumentApi: IGrnDocumentApi;
	public readonly eGinDocumentApi: EGinDocumentApi;
	public readonly clientApi: ClientApi;

	constructor() {
		this.axiosInstance = this.createInstance();
		this.authApi = new AuthApi(this.axiosInstance);
		this.suppliersApi = new SuppliersApi(this.axiosInstance);
		this.partsApi = new PartsApi(this.axiosInstance);
		this.settingsApi = new SettingsApi(this.axiosInstance);
		this.invoicesApi = new InvoicesApi(this.axiosInstance);
		this.externalApi = new ExternalApi(this.axiosInstance);
		this.technologiesApi = new TechnologiesApi(this.axiosInstance);
		this.ginDocumentApi = new GinDocumentApi(this.axiosInstance);
		this.iGrnDocumentApi = new IGrnDocumentApi(this.axiosInstance);
		this.eGinDocumentApi = new EGinDocumentApi(this.axiosInstance);
		this.clientApi = new ClientApi(this.axiosInstance);
	}

	private createInstance(): AxiosInstance {
		const headers: { [key: string]: string } = {};
		let axios = Axios.create({
			timeout: 60000,
			headers
		});
		axios.interceptors.request.use(request => {
			if (request.url !== `/api/login`) {
				// eslint-disable-next-line
				store.getState().auth.authToken.map(token => {
					request.headers['Authorization'] = `Bearer ${token.token}`;
				});
			}
			return request;
		});
		axios.interceptors.response.use(resp => resp, error => {
			if (error.status === 401) {
				store.dispatch(logOut(() => {}, () => {}));
				window.location.replace("/auth")
			}
			return error;
		});
		return axios;
	}
}

export const apiInstance: ApiInstance = new ApiInstance();
