import {AxiosInstance} from "axios";
import {InvoiceApiDto} from "../models/_RestModels";
import Invoice from "../models/Invoice";
import {Currency} from "../common/Constants";
import GrnDocument from "../models/GrnDocument";
import InvoiceSummary from "../models/InvoiceSummary";
import FormsUtils from "../common/FormsUtils";

export default class InvoicesApi {
	constructor(public readonly axiosInstance: AxiosInstance) {
	}

	postInvoice(invoiceNumber: string,
		invoiceDate: string,
		currency: string,
		fxRate: number,
		supplierId: string,
		supplierExternalId: string,
		supplierFullName: string,
		supplierShortName: string,
		supplierStreet: string,
		supplierCity: string,
		supplierZipCode: string,
		supplierCountry: string,
	): Promise<void> {
		const url = "/api/invoices";
		const config = {
			invoiceNumber,
			invoiceDate,
			currency,
			fxRate: currency === Currency.PLN ? null : fxRate,
			supplierId,
			supplierExternalId,
			supplierFullName,
			supplierShortName,
			supplierStreet,
			supplierCity,
			supplierZipCode,
			supplierCountry
		};
		return this.axiosInstance.post(url, config)
	}

	fetchInvoices(): Promise<InvoiceSummary[]> {
		const url = "/api/invoices";
		return this.axiosInstance.get(url).then(resp => resp.data.map((i: InvoiceApiDto) => Invoice.fromJson(i)))
	}

	fetchInvoice(id: string): Promise<Invoice> {
		const url = `/api/invoices/${id}`;
		return this.axiosInstance.get(url).then(resp => Invoice.fromJson(resp.data))
	}

	putInvoice(id: string,
		invoiceNumber: string,
		invoiceDate: string,
		currency: string,
		fxRate: number,
		supplierId: string,
		supplierExternalId: string,
		supplierFullName: string,
		supplierShortName: string,
		supplierStreet: string,
		supplierCity: string,
		supplierZipCode: string,
		supplierCountry: string,
	): Promise<void> {
		const url = `/api/invoices/${id}`;
		const config = {
			invoiceNumber,
			invoiceDate,
			currency,
			fxRate: currency === Currency.PLN ? null : fxRate,
			supplierId,
			supplierExternalId,
			supplierFullName,
			supplierShortName,
			supplierStreet,
			supplierCity,
			supplierZipCode,
			supplierCountry
		};
		return this.axiosInstance.put(url, config)
	}

	fetchInvoiceGrn(invoiceId: string): Promise<GrnDocument> {
		const url = `/api/invoices/${invoiceId}/grn_document`;
		return this.axiosInstance.get(url).then(resp => GrnDocument.fromJson(resp.data))
	}

	postInvoiceGrn(invoiceId: string): Promise<GrnDocument> {
		const url = `/api/invoices/${invoiceId}/grn_document`;
		return this.axiosInstance.post(url).then(resp => GrnDocument.fromJson(resp.data))
	}

	postInvoiceGrnItem(invoiceId: string,
		partId: string,
		typeId: string,
		partNumber: string,
		descriptionPl: string,
		unitNamePl: string,
		quantity: number,
		totalValue: number) {
		const url = `/api/invoices/${invoiceId}/grn_document/items`;
		const config = {
			partId, typeId: FormsUtils.optionalSelect(typeId), partNumber, descriptionPl, unitNamePl, quantity, totalValue
		};
		return this.axiosInstance.put(url, config)
	}

	putInvoiceGrnItem(id: string,
		invoiceId: string,
		partId: string,
		typeId: string,
		partNumber: string,
		descriptionPl: string,
		unitNamePl: string,
		quantity: number,
		totalValue: number) {
		const url = `/api/grn_document_items/${id}`;
		const config = {
			partId, typeId: FormsUtils.optionalSelect(typeId), partNumber, descriptionPl, unitNamePl, quantity, totalValue
		};
		return this.axiosInstance.put(url, config)
	}

	deleteInvoiceGrnItem(id: string) {
		const url = `/api/grn_document_items/${id}`;
		return this.axiosInstance.delete(url)
	}

	acceptGrnDocument(id: string) {
		const url = `/api/invoices/${id}/grn_document/accept`;
		return this.axiosInstance.put(url)
	}
}