import React, {useEffect, useState} from "react";
import {makeStyles, Paper} from "@material-ui/core";
import {defaultStyles} from "../../styles/defaultStyles";
import GrnForm from "./GrnForm";
import {apiInstance} from "../../api/Api";
import TabSpinner from "../common/TabSpinner";
import GrnDocument from "../../models/GrnDocument";
import {none, option, Option} from "ts-option";
import {useParams} from "react-router";
import GrnDocumentItem from "../../models/GrnDocumentItem";
import Scrollable from "../common/Scrollable";

const useStyles = makeStyles(defaultStyles);

const GrnEdit: React.FC = () => {

	const [isLoading, setLoading] = useState<boolean>(true);
	const [grnDocument, setGrnDocument] = useState<Option<GrnDocument>>(none);
	const [grnItems, setGrnItems] = useState<GrnItemRow[]>([]);
	const {invoiceId} = useParams();

	const updateData = async () => {
		setLoading(true);
		const grnDocument = await apiInstance.invoicesApi.fetchInvoiceGrn(invoiceId as string);
		const parts = await apiInstance.partsApi.fetchParts();
		const units = await apiInstance.partsApi.fetchPartUnits();
		const grnItemRows = grnDocument.grnItems.map(item => {
			const unitName = units.filter(u => u.id === (parts.filter(p => p.id === item.partId)[0].unitId))[0].displayName;
			const descriptionEn = parts.filter(p => p.id === item.partId)[0].descriptionEn;
			return {...item, unitName, descriptionEn}
		});
		setGrnItems(grnItemRows);
		setGrnDocument(option(grnDocument));
		setLoading(false);
	};

	useEffect(() => {
		setLoading(true);
		const fetchData = async () => {
			const grnDocument = await apiInstance.invoicesApi.fetchInvoiceGrn(invoiceId as string);
			const parts = await apiInstance.partsApi.fetchParts();
			const units = await apiInstance.partsApi.fetchPartUnits();
			const grnItemRows = grnDocument.grnItems.map(item => {
				const unitName = units.filter(u => u.id === (parts.filter(p => p.id === item.partId)[0].unitId))[0].displayName;
				const descriptionEn = parts.filter(p => p.id === item.partId)[0].descriptionEn;
				return {...item, unitName, descriptionEn}
			});
			setGrnItems(grnItemRows);
			setGrnDocument(option(grnDocument));
		};

		fetchData()
			.then(() => setLoading(false))
			.catch((err: Error) => {
				// TODO Handle error
				setLoading(false);
				console.error(err)
			});
	}, [invoiceId]);


	const classes = useStyles();
	return (
		<Paper className={classes.tablePaper}>
			<Scrollable>
				{
					isLoading ?
						<TabSpinner/> :
						grnDocument.map(grn => <GrnForm grn={grn} items={grnItems} onChange={() => updateData()}/>).orNull
				}
			</Scrollable>
		</Paper>
	)
};

export default GrnEdit

export interface GrnItemRow extends GrnDocumentItem {
	descriptionEn: string,
	unitName: string,
}