import * as React from 'react';
import withStyles, {StyledComponentProps} from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import {Theme} from "@material-ui/core/styles/createMuiTheme";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconSettings from "@material-ui/icons/SettingsRounded";
import IconInvoice from "@material-ui/icons/Assignment";
import IconParts from "@material-ui/icons/WidgetsRounded";
import IconSuppliers from "@material-ui/icons/PeopleAltRounded";
import FormatListBulletedRoundedIcon from '@material-ui/icons/FormatListBulletedRounded';
import logo from "../../assets/logo.png";
import {version} from "../../../package.json"
import moment from "moment";
import GinIcon from '@material-ui/icons/OpenInBrowser';
import { mdiApplicationImport } from '@mdi/js';
import { mdiFileSendOutline } from '@mdi/js';
import GenericIcon from "../common/GenericIcon";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

const drawerWidth = 250;

const styles = (theme: Theme) => createStyles({
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth,
		justifyContent: "space-between"
	}
});

interface IProps {
	mobileOpen: boolean,
	handleDrawerToggle: () => void,
	onRouteSelected: (route: string) => () => void,
}

class AppDrawer extends React.Component<IProps & StyledComponentProps> {
	render() {
		const {classes}: any = this.props;
		return (
			<nav className={classes.drawer}>
				<Hidden smUp implementation="css">
					<Drawer
						container={(this.props as any).container}
						variant="temporary"
						anchor={"left"}
						open={this.props.mobileOpen}
						onClose={this.props.handleDrawerToggle}
						classes={{paper: classes.drawerPaper}}
						ModalProps={{keepMounted: true}}>
						<div>
							{this.renderRoutes()}
						</div>
						{this.renderVersion()}
					</Drawer>
				</Hidden>
				<Hidden xsDown implementation="css">
					<Drawer
						classes={{paper: classes.drawerPaper}}
						variant="permanent"
						open>
						<div>
							<div style={{height: "64px", textAlign: "center"}}>
								<img alt={"Yosensi"} src={logo}
									 style={{width: "80%", height: "auto", marginTop: 8}}/>
							</div>
							<Divider/>
							{this.renderRoutes()}
						</div>
						{this.renderVersion()}
					</Drawer>
				</Hidden>
			</nav>
		);
	}

	renderVersion = () => {
		const {classes}: any = this.props;

		return (
			<div style={{marginLeft: 8, marginBottom: 8}}>
				<div>Version {version}</div>
				<span className={classes.textMuted}
					  style={{fontSize: "80%"}}>Build on: {moment(process.env.REACT_APP_BUILD_DATETIME).format(
					"MMM Do YYYY")}</span>
			</div>
		);
	};

	renderRoutes() {
		return (
			<List>
				<ListItem button key={"invoices"}
						  onClick={this.props.onRouteSelected("/invoices")}>
					<ListItemIcon>
						<IconInvoice/>
					</ListItemIcon>
					<ListItemText primary={"Invoices"}/>
				</ListItem>
				<ListItem button key={"gin_documents"}
						  onClick={this.props.onRouteSelected("/gin_documents")}>
					<ListItemIcon>
						<GinIcon/>
					</ListItemIcon>
					<ListItemText primary={"GIN Documents"}/>
				</ListItem>
				<ListItem button key={"igrn_documents"}
						  onClick={this.props.onRouteSelected("/igrn_documents")}>
					<ListItemIcon>
						<GenericIcon path={mdiApplicationImport}/>
					</ListItemIcon>
					<ListItemText primary={"IGRN Documents"}/>
				</ListItem>
				<ListItem button key={"egin_documents"}
						  onClick={this.props.onRouteSelected("/egin_documents")}>
					<ListItemIcon>
						<GenericIcon path={mdiFileSendOutline}/>
					</ListItemIcon>
					<ListItemText primary={"EGIN Documents"}/>
				</ListItem>
				<Divider/>
				<ListItem button key={"summary"}
						  onClick={this.props.onRouteSelected("/summary")}>
					<ListItemIcon>
						<PlaylistAddCheckIcon/>
					</ListItemIcon>
					<ListItemText primary={"Summary"}/>
				</ListItem>
				<ListItem button key={"parts"}
						  onClick={this.props.onRouteSelected("/parts")}>
					<ListItemIcon>
						<IconParts/>
					</ListItemIcon>
					<ListItemText primary={"Parts"}/>
				</ListItem>
				<ListItem button key={"suppliers"}
						  onClick={this.props.onRouteSelected("/suppliers")}>
					<ListItemIcon>
						<IconSuppliers/>
					</ListItemIcon>
					<ListItemText primary={"Suppliers"}/>
				</ListItem>
				<ListItem button key={"clients"}
						  onClick={this.props.onRouteSelected("/clients")}>
					<ListItemIcon>
						<AccountBoxIcon/>
					</ListItemIcon>
					<ListItemText primary={"Clients"}/>
				</ListItem>
				<ListItem button key={"boms"}
						  onClick={this.props.onRouteSelected("/technologies")}>
					<ListItemIcon>
						<FormatListBulletedRoundedIcon/>
					</ListItemIcon>
					<ListItemText primary={"Technologies"}/>
				</ListItem>
				<ListItem button key={"settings"}
						  onClick={this.props.onRouteSelected("/app_settings")}>
					<ListItemIcon>
						<IconSettings/>
					</ListItemIcon>
					<ListItemText primary={"Settings"}/>
				</ListItem>
			</List>
		);
	}
}

export default withStyles((theme) => ({
	...styles(theme),
}), {withTheme: true})(AppDrawer);