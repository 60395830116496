import React from "react";
import {StyledComponentProps, Table, TableBody, TableCell, TableHead, TableRow, withStyles} from "@material-ui/core";
import Client from "../../models/Client";
import ClientTableRow from "./ClientsTableRow";
import {defaultStyles} from "../../styles/defaultStyles";

interface IProps {
	clients: Client[];
}

type Props = IProps & StyledComponentProps;

const ClientsTable: React.FunctionComponent<Props> = (props: Props) => {
	const {clients} = props;
	const {classes}: any = props;
	return (
		<Table size={"small"}>
			<TableHead>
				<TableRow>
					<TableCell className={classes.tableHeader}>External ID</TableCell>
					<TableCell className={classes.tableHeader}>Full Name</TableCell>
					<TableCell className={classes.tableHeader}>Short Name</TableCell>
					<TableCell className={classes.tableHeader}>Street</TableCell>
					<TableCell className={classes.tableHeader}>City</TableCell>
					<TableCell className={classes.tableHeader}>Zip Code</TableCell>
					<TableCell className={classes.tableHeader}>Country</TableCell>
					<TableCell className={classes.tableHeader}>Actions</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{clients.map((client) => <ClientTableRow key={client.id} client={client}/>)}
			</TableBody>
		</Table>
	);
};

export default withStyles(defaultStyles)(ClientsTable);