import React, {useEffect, useState} from "react";
import {makeStyles, Paper} from "@material-ui/core";
import {defaultStyles} from "../../styles/defaultStyles";
import GinDocument from "../../models/GinDocument";
import {apiInstance} from "../../api/Api";
import TabSpinner from "../common/TabSpinner";
import GinDocumentsTable from "./GinDocumentsTable";
import GinDocumentsModal from "./GinDocumentsModal";
import {none, Option, option} from "ts-option";
import IGrnCreateModal from "./IGrnCreateModal";
import Scrollable from "../common/Scrollable";
import AddFab from "../common/AddFab";
import Technology from "../../models/Technology";

const useStyles = makeStyles(defaultStyles);

const GinDocuments: React.FC<{}> = () => {

	const classes = useStyles();

	const [isLoading, setLoading] = useState<boolean>(true);
	const [isOpen, setOpen] = useState(false);
	const [isDocOpen, setDocOpen] = useState(false);
	const [ginDocuments, setGinDocuments] = useState<GinDocumentTableRow[]>([]);
	const [ginDocument, setGinDocument] = useState<Option<GinDocument>>(none);
	const [technologies, setTechnologies] = useState<Technology[]>([]);

	const fetchData = async () => {
		const ginDocuments = await apiInstance.ginDocumentApi.fetchGinDocuments();
		const technologies = await apiInstance.technologiesApi.fetchTechnologies();
		const rows = ginDocuments.map(doc => {
			let technologyName = "-";
			// eslint-disable-next-line
			doc.technologyId.map(techId => {
				const foundTech = technologies.filter(tech => tech.id === techId)[0];
				technologyName = foundTech && foundTech.name;
			});
			return ({
				...doc, technologyName: technologyName
			});
		});
		setTechnologies(technologies);
		setGinDocuments(rows);
		setLoading(false);
	};

	useEffect(() => {
		fetchData()
			.then(() => setLoading(false))
			.catch(() => {
				// TODO Handle error
				setLoading(false)
			});
	}, []);

	const onEdit = (document: GinDocument) => {
		setOpen(true);
		setGinDocument(option(document))
	};

	const onDelete = (document: GinDocument) => {
		if (window.confirm("Delete GIN Document?")) {
			apiInstance.ginDocumentApi.deleteGinDocument(document.id).then(() => {
				setLoading(true);
				fetchData()
					.then(() => setLoading(false))
					.catch((err: any) => {
						console.error(err);
						setLoading(false)
					});
			}).catch((err: any) => console.error(err))
		}
	};

	const onCreate = () => {
		setOpen(true)
	};

	const onDocCreate = (document: GinDocument) => {
		setDocOpen(true);
		setGinDocument(option(document))
	};

	const onClose = () => {
		setOpen(false);
		setDocOpen(false);
		setLoading(true);
		fetchData()
			.then(() => setLoading(false))
			.catch(() => {
				// TODO Handle error
				setLoading(false)
			});
		setGinDocument(none)
	};

	return (
		<Paper className={classes.tablePaper}>
			{isLoading ?
				<TabSpinner/> :
				<>
					<Scrollable>
						<GinDocumentsTable ginDocuments={ginDocuments} onEdit={onEdit} onDelete={onDelete}
										   onDocCreate={onDocCreate}/>
					</Scrollable>
					<GinDocumentsModal isOpen={isOpen} ginDocument={ginDocument} onClose={onClose} technologies={technologies}/>
					{ginDocument.map(doc => {
						return <IGrnCreateModal isOpen={isDocOpen} ginDocument={doc} onClose={onClose}/>
					}).orNull}
					<AddFab onClick={onCreate}/>
				</>
			}
		</Paper>
	);
};

export default GinDocuments

export interface GinDocumentTableRow extends GinDocument {
	technologyName: string
}