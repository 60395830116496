import React from "react";
import GinItemsTableRow from "./GinItemsTableRow";
import {makeStyles, StyledComponentProps, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {defaultStyles} from "../../../styles/defaultStyles";
import {GinPartStateTableRow} from "./GinItems";
import GinDocument from "../../../models/GinDocument";
import {Option} from "ts-option";

const useStyles = makeStyles(defaultStyles);

interface IProps {
	ginItems: GinPartStateTableRow[],
	onEdit: (item: GinPartStateTableRow) => void,
	onDelete: (item: GinPartStateTableRow) => void,
	ginDocument: Option<GinDocument>
}

type Props = IProps & StyledComponentProps;

const GinItemsTable: React.FC<Props> = (props: Props) => {
	const classes = useStyles();

	const {ginItems, ginDocument} = props;

	const isAccepted = ginDocument.map(doc => doc.acceptedAt.isDefined).getOrElseValue(true);

	const onEdit = (ginItem: GinPartStateTableRow) => () => {
		props.onEdit(ginItem);
	};

	const onDelete = (ginItem: GinPartStateTableRow) => () => {
		props.onDelete(ginItem);
	};

	return (
		<Table size={"small"}>
			<TableHead>
				<TableRow>
					<TableCell className={classes.tableHeader}>No.</TableCell>
					<TableCell className={classes.tableHeader}>Part Number</TableCell>
					<TableCell className={classes.tableHeader}>Description En</TableCell>
					<TableCell className={classes.tableHeader} style={{textAlign: "center"}}>Quantity</TableCell>
					<TableCell className={classes.tableHeader} style={{textAlign: "center"}}>Unit</TableCell>
					<TableCell className={classes.tableHeader} style={{textAlign: "center"}}>Total Value PLN</TableCell>
					<TableCell className={classes.tableHeader} style={{width: 152, textAlign: "center"}}>Actions</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{ginItems.map((ginItem, index) => <GinItemsTableRow key={ginItem.id} index={index} ginItem={ginItem}
																	onEdit={onEdit(ginItem)} onDelete={onDelete(ginItem)} accepted={isAccepted}/>)}
			</TableBody>
		</Table>
	);
};

export default GinItemsTable
