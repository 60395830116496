import {Option} from "ts-option";
import {
	Button,
	CircularProgress,
	FormControl, FormHelperText,
	Grid, IconButton, InputAdornment,
	InputLabel,
	makeStyles,
	MenuItem,
	Modal,
	Select, TextField,
	Typography
} from "@material-ui/core";
import {Field, Form, Formik, FormikProps} from "formik";
import Technology from "../../models/Technology";
import {defaultStyles} from "../../styles/defaultStyles";
import {apiInstance} from "../../api/Api";
import FormTextField from "../common/FormTextField";
import Part from "../../models/Part";
import TabSpinner from "../common/TabSpinner";
import * as Yup from "yup";
import React, {useEffect, useState} from "react";
import CloseIcon from "@material-ui/icons/Close";

const validationSchema = Yup.object().shape({
	name: Yup.string()
		.required('Name is required'),
	partId: Yup.string().test("is-selected", "Part is required", (value => value !== "select")),
});

interface IProps {
	isOpen: boolean,
	technology: Option<Technology>,
	onClose: () => void,
}

interface TechnologyFormValues {
	name: string,
	partId: string,
}

const useStyles = makeStyles(defaultStyles);

const TechnologyModal: React.FC<IProps> = (props: IProps) => {
	const {isOpen, technology, onClose} = props;
	const [isSubmitting, setSubmitting] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [parts, setParts] = useState<Part[]>([]);
	const [filterString, setFilterString] = useState<string>("");

	const classes = useStyles({});

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const parts = await apiInstance.partsApi.fetchParts();
			setParts(parts);
		};
		fetchData()
			.then(() => setLoading(false))
			.catch((err: Error) => {
				// TODO Handle error
				setLoading(false);
				console.error(err)
			});
	}, []);

	const initialValues: TechnologyFormValues = props.technology.map(partUnit => {
		return ({
			name: partUnit.name,
			partId: partUnit.partId,
		});
	}).getOrElseValue({
		name: "",
		partId: "select",
	});

	const filteredParts = parts.filter(part => {
		return filterString.length ? part.partNumber.toLowerCase().includes(filterString.toLowerCase()) : true
	}).sort((a, b) => a.partNumber.localeCompare(b.partNumber));

	return (
		<Modal
			open={isOpen}
			onClose={() => onClose()}
			style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
		>
			<div className={classes.wideModal}>
				{
					isLoading ?
						<TabSpinner/> :
						<>
							<Formik
								initialValues={initialValues}
								enableReinitialize={true}
								validateOnBlur={true}
								validateOnChange={false}
								validationSchema={validationSchema}
								onSubmit={((values: TechnologyFormValues, actions) => {
									const submitAction = technology.isDefined ?
										apiInstance.technologiesApi.putTechnology(technology.get.id, values.name,
											values.partId) :
										apiInstance.technologiesApi.postTechnology(values.name, values.partId);
									// TODO Handle errors nicely
									setSubmitting(true);
									submitAction.then(() => {
										setSubmitting(false);
										actions.resetForm();
										onClose();
									}).catch((err) => {
										setSubmitting(false);
										console.error(err)
									})
								})}>
								{(formikProps: FormikProps<TechnologyFormValues>) => (
									<Form noValidate={true}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<Typography variant={"h6"}>{
													technology.isDefined ?
														"Update Product Technology" :
														"Create New Product Technology"
												}</Typography>
												<Field
													id="name"
													label="Name"
													name="name"
													required={true}
													error={formikProps.errors.name && formikProps.touched.name}
													helperText={formikProps.errors.name && formikProps.touched.name ?
														formikProps.errors.name : ""}
													as={FormTextField}
												/>
											</Grid>
											<Grid item xs={6}>
												<TextField
													variant={"outlined"}
													fullWidth={true}
													label={"Parts Filter"}
													margin={"dense"}
													id="partsFilter"
													value={filterString}
													onChange={(evt) => {
														formikProps.setFieldValue("partId", "select");
														setFilterString(evt.target.value)
													}}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton
																	size={"small"}
																	onClick={() =>
																	{
																		formikProps.setFieldValue("partId", "select");
																		setFilterString("")
																	}}
																	disabled={!filterString.length}
																>
																	<CloseIcon/>
																</IconButton>
															</InputAdornment>
														)
													}}
												/>
											</Grid>
											<Grid item xs={6}>
												<FormControl variant={"outlined"} fullWidth={true} style={{marginTop: 8}}
															 error={Boolean(
																 formikProps.errors.partId && formikProps.touched.partId)}>
													<InputLabel>Select Part</InputLabel>
													<Field name={"partId"} autoWidth={true} labelWidth={85} as={Select}
														   margin={"dense"} required={true}
														   disabled={isLoading}>
														<MenuItem value={"select"}>Select Part</MenuItem>
														{filteredParts.map(
															part => <MenuItem
																value={part.id} key={part.id}>{part.partNumber}</MenuItem>)}
													</Field>
													{formikProps.errors.partId && formikProps.touched.partId &&
													<FormHelperText>{formikProps.errors.partId}</FormHelperText>
													}
												</FormControl>
											</Grid>
											<Grid item xs={12}>
												<div className={classes.modalButtonsRow}>
													<Button onClick={() => onClose()}>Close</Button>
													<Button type="submit" color={"primary"} variant={"contained"}>
														{isSubmitting ? <CircularProgress style={{color: "white"}} size={24}/> :
															technology.isDefined ? "Update" : "Create"}
													</Button>
												</div>
											</Grid>
										</Grid>
									</Form>
								)}
							</Formik>
						</>
				}
			</div>
		</Modal>
	);
};

export default TechnologyModal