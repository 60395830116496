import {GrnDocumentApiDto} from "./_RestModels";
import GrnDocumentItem from "./GrnDocumentItem";
import moment from "moment";
import {none, option, Option} from "ts-option";

export default class GrnDocument {
	constructor(public readonly id: string,
		public readonly documentNumber: string,
		public readonly invoiceId: string,
		public readonly invoiceNumber: string,
		public readonly invoiceDate: moment.Moment,
		public readonly invoiceCurrency: string,
		public readonly supplierFullName: string,
		public readonly supplierStreet: string,
		public readonly supplierCity: string,
		public readonly supplierZipCode: string,
		public readonly supplierCountry: string,
		public readonly acceptedAt: Option<moment.Moment>,
		public readonly acceptedBy: Option<string>,
		public readonly grnItems: GrnDocumentItem[]
	) {}

	public static fromJson(json: GrnDocumentApiDto): GrnDocument {
		const invoiceDate = moment(json.invoiceDate);
		const acceptedAt = json.acceptedAt ? option(moment(json.acceptedAt)) : none;
		const acceptedBy = option(json.acceptedBy);
		const grnItems: GrnDocumentItem[] = json.grnItems ? json.grnItems.map(item => GrnDocumentItem.fromJson(item)) : [];
		const {id, documentNumber, invoiceId, invoiceNumber, invoiceCurrency, supplierFullName, supplierStreet, supplierCity, supplierCountry, supplierZipCode} = json;
		return new GrnDocument(id, documentNumber, invoiceId, invoiceNumber, invoiceDate, invoiceCurrency, supplierFullName, supplierStreet,
			supplierCity, supplierZipCode, supplierCountry, acceptedAt, acceptedBy, grnItems)
	}
}