import React from "react";
import {Divider, Grid, Typography} from "@material-ui/core";
import GrnDocument from "../../models/GrnDocument";
import {DEFAULT_DATE_FORMAT} from "../../common/Constants";
import GrnItems from "./grn_items/GrnItems";
import {GrnItemRow} from "./GrnEdit";

interface IProps {
	grn: GrnDocument
	items: GrnItemRow[]
	onChange: () => {}
}

const GrnForm: React.FC<IProps> = (props: IProps) => {

	const {grn, onChange, items} = props;

	return (
		<Grid container>
			<Grid item xs={12}>
				<div style={{margin: "16px 16px 0 16px"}}>
					<Typography
						variant={"body1"}>Invoice: <strong>{grn.invoiceNumber}</strong> from: <strong>{grn.invoiceDate.format(
						DEFAULT_DATE_FORMAT)}</strong></Typography>
					<Typography variant={"body2"}>{grn.supplierFullName}</Typography>
					<Typography variant={"body2"}>{grn.supplierStreet}</Typography>
					<Typography variant={"body2"}>{grn.supplierCity} {grn.supplierZipCode}</Typography>
					<Typography variant={"body2"}>{grn.supplierCountry}</Typography><br/>
				</div>
				<Divider/>
				<GrnItems grn={grn} items={items} onChange={onChange}/>
			</Grid>
		</Grid>
	);
};

export default GrnForm