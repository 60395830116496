import React, {useState} from "react";
import {Option} from "ts-option";
import {Button, CircularProgress, makeStyles, Modal, Typography} from "@material-ui/core";
import {defaultStyles} from "../../../styles/defaultStyles";
import {FormikHelpers, useFormik} from "formik";
import {apiInstance} from "../../../api/Api";
import FormTextField from "../../common/FormTextField";
import PartType from "../../../models/PartType";

interface IProps {
	isOpen: boolean,
	partType: Option<PartType>,
	onClose: () => void,
	onSuccess: (partType: PartType) => void,
}

interface PartTypeFormValues {
	typeId: string,
}

const useStyles = makeStyles(defaultStyles);

const PartTypeModal: React.FC<IProps> = (props: IProps) => {
	const {isOpen, partType, onClose, onSuccess} = props;
	const [isSubmitting, setSubmitting] = useState(false);
	const classes = useStyles();
	const initialValues: PartTypeFormValues = props.partType.map(partType => {
		return ({
			typeId: partType.typeId,
		});
	}).getOrElseValue({
		typeId: "",
	});
	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit: ((values: PartTypeFormValues, actions: FormikHelpers<PartTypeFormValues>) => {
			const submitAction = partType.isDefined ?
				apiInstance.partsApi.putPartType(partType.get.id, values.typeId) :
				apiInstance.partsApi.postPartType(values.typeId);
			// TODO Handle errors nicely
			setSubmitting(true);
			submitAction.then((partType) => {
				setSubmitting(false);
				formik.resetForm();
				onSuccess(partType);
			})
				.catch((err: any) => {
					//TODO Handle error nicely
					setSubmitting(false);
					if (err.response.status === 409) {
						actions.setFieldError("typeId", "Provided Type ID is already in use");
					}
					else {
						console.error(err)
					}
				})
		})
	});
	const handleClose = () => {
		formik.resetForm();
		onClose()
	};
	return (
		<Modal
			open={isOpen}
			onClose={() => onClose()}
			style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
		>
			<div className={classes.modal}>
				<Typography variant={"h6"}>{
					partType.isDefined ?
						"Update Part Type" :
						"Create New Part Type"
				}</Typography>
				<form onSubmit={formik.handleSubmit}>
					<FormTextField
						id="typeId"
						label="Type ID"
						name="typeId"
						required={true}
						error={Boolean(formik.errors.typeId && formik.touched.typeId)}
						helperText={formik.errors.typeId && formik.touched.typeId ? formik.errors.typeId : ""}
						onChange={formik.handleChange}
						value={formik.values.typeId}
					/>
					<div className={classes.modalButtonsRow}>
						<Button onClick={handleClose}>Close</Button>
						<Button type="submit" color={"primary"} variant={"contained"}>
							{isSubmitting ? <CircularProgress style={{color: "white"}} size={24}/> :
								partType.isDefined ? "Update" : "Create"}
						</Button>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default PartTypeModal