import React from "react";
import {IconButton, TableCell, TableRow} from "@material-ui/core";
import IconDelete from '@material-ui/icons/Delete';
import EditIcon from "@material-ui/icons/Edit";
import {GrnItemRow} from "../GrnEdit";

interface IProps {
	item: GrnItemRow;
	onDelete: () => void;
	onEdit: () => void;
	index: number
	isAccepted: boolean
}

const GrnItemsTableRow: React.FunctionComponent<IProps> = (props: IProps) => {
	const {item, onDelete, index, onEdit, isAccepted} = props;
	return (
		<TableRow>
			<TableCell>{index + 1}</TableCell>
			<TableCell>{item.partNumber}</TableCell>
			<TableCell>{item.descriptionEn}</TableCell>
			<TableCell style={{textAlign: "center"}}>{item.quantity}</TableCell>
			<TableCell style={{textAlign: "center"}}>{item.unitName}</TableCell>
			<TableCell style={{textAlign: "center"}}>{(item.totalValue / 100).toFixed(2)}</TableCell>
			{
				!isAccepted &&
				<TableCell style={{width: 120, textAlign: "center"}}>
					<IconButton size={"small"} onClick={onEdit}>
						<EditIcon/>
					</IconButton>
					<IconButton size={"small"} onClick={onDelete}>
						<IconDelete/>
					</IconButton>
				</TableCell>
			}
		</TableRow>
	);
};

export default GrnItemsTableRow;