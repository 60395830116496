import React from "react";

const Scrollable: React.FC<{}> = (props) => {
	return (
		<div style={{overflowY: "auto", height: "100%"}}>
			{props.children}
		</div>
	);
};

export default Scrollable
