import {option, Option} from "ts-option";
import {ClientApiDto} from "./_RestModels";

export default class Client {
    constructor(
        public readonly id: string,
        public readonly externalId: string,
        public readonly vatNumber: Option<string>,
        public readonly fullName: string,
        public readonly shortName: string,
        public readonly street: string,
        public readonly city: string,
        public readonly zipCode: string,
        public readonly country: string,
        public readonly website: Option<string>,
    ) {
    }

    public static fromJson(json: ClientApiDto): Client {
        return new Client(
            json.id,
            json.externalId,
            option(json.vatNumber),
            json.fullName,
            json.shortName,
            json.street,
            json.city,
            json.zipCode,
            json.country,
            option(json.website),
        );
    }
}
