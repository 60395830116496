import {AxiosInstance} from "axios";
import EGinDocument from "../models/EGinDocument";
import {EGinDocumentApiDto, EGinDocumentItemApiDto} from "../models/_RestModels";
import EGinDocumentItem from "../models/EGinDocumentItem";

export default class EGinDocumentApi {
	constructor(public readonly axiosInstance: AxiosInstance) {}

	postEGinDocument(description: string,
		documentDate: string,
		clientId: string,
		clientExternalId: string,
		clientFullName: string,
		clientShortName: string,
		clientStreet: string,
		clientCity: string,
		clientZipCode: string,
		clientCountry: string): Promise<EGinDocument> {
		const url = '/api/egin_documents';
		const config = {
			description,
			documentDate,
			clientId,
			clientExternalId,
			clientFullName,
			clientShortName,
			clientStreet,
			clientCity,
			clientZipCode,
			clientCountry
		};
		return this.axiosInstance.post(url, config).then(resp => EGinDocument.fromJson(resp.data))
	}

	fetchEGinDocument(id: string): Promise<EGinDocument> {
		const url = `/api/egin_documents/${id}`;
		return this.axiosInstance.get(url).then(resp => EGinDocument.fromJson(resp.data))
	}

	putEGinDocument(id: string,
		description: string,
		documentDate: string,
		clientId: string,
		clientExternalId: string,
		clientFullName: string,
		clientShortName: string,
		clientStreet: string,
		clientCity: string,
		clientZipCode: string,
		clientCountry: string): Promise<EGinDocument> {
		const url = `/api/egin_documents/${id}`;
		const config = {
			description,
			documentDate,
			clientId,
			clientExternalId,
			clientFullName,
			clientShortName,
			clientStreet,
			clientCity,
			clientZipCode,
			clientCountry
		};
		return this.axiosInstance.put(url, config).then(resp => EGinDocument.fromJson(resp.data))
	}

	fetchEGinDocuments(): Promise<EGinDocument[]> {
		const url = '/api/egin_documents';
		return this.axiosInstance.get(url).then(resp => resp.data.map((eg: EGinDocumentApiDto) => EGinDocument.fromJson(eg)))
	}

	deleteEGinDocument(id: string): Promise<void> {
		const url = `/api/egin_documents/${id}`;
		return this.axiosInstance.delete(url);
	}

	fetchEGinDocumentItems(id: string): Promise<EGinDocumentItem[]> {
		const url = `/api/egin_documents/${id}/items`;
		return this.axiosInstance.get(url).then(
			resp => resp.data.map((egi: EGinDocumentItemApiDto) => EGinDocumentItem.fromJson(egi)))
	}

	postEGinDocumentItem(eginId: string,
		partId: string,
		typeId: string | null,
		quantity: number,
		partNumber: string,
		descriptionPl: string,
		unitNamePl: string): Promise<void> {
		const url = `/api/egin_documents/${eginId}/items`;
		const config = {partId, typeId, quantity, partNumber, descriptionPl, unitNamePl};
		return this.axiosInstance.post(url, config);
	}

	putEginDocumentItem(eginId: string,
		id: string,
		partId: string,
		typeId: string | null,
		quantity: number,
		partNumber: string,
		descriptionPl: string,
		unitNamePl: string): Promise<void> {
		return this.deleteEginDocumentItem(id).then(
			() => this.postEGinDocumentItem(eginId, partId, typeId, quantity, partNumber, descriptionPl, unitNamePl));
	}

	deleteEginDocumentItem(id:string) {
		const url = `/api/egin_document_items/${id}`;
		return this.axiosInstance.delete(url)
	}

	acceptEGinDocument(id: string) {
		const url = `/api/egin_documents/${id}/accept`;
		return this.axiosInstance.put(url)
	}
}
