import {Option} from "ts-option";
import {
	Button, CircularProgress, Grid, makeStyles, Modal, Typography
} from "@material-ui/core";
import {Field, Form, Formik, FormikProps} from "formik";
import {defaultStyles} from "../../styles/defaultStyles";
import {apiInstance} from "../../api/Api";
import FormTextField from "../common/FormTextField";
import * as Yup from "yup";
import React, {useState} from "react";
import {KeyboardDatePicker, MaterialUiPickersDate} from "@material-ui/pickers";
import {DEFAULT_DATE_FORMAT} from "../../common/Constants";
import IGrnDocument from "../../models/IGrnDocument";
import DateUtils from "../../common/DateUtils";

const validationSchema = Yup.object().shape({
	description: Yup.string()
		.required('Description is required'),
});

interface IProps {
	isOpen: boolean,
	iGrnDocument: Option<IGrnDocument>,
	onClose: () => void,
}

interface iGrnFormValues {
	description: string,
	documentDate: Date,
}

const useStyles = makeStyles(defaultStyles);

const IGrnDocumentsModal: React.FC<IProps> = (props: IProps) => {
	const {isOpen, iGrnDocument, onClose} = props;
	const [isSubmitting, setSubmitting] = useState(false);

	const classes = useStyles({});

	const initialValues: iGrnFormValues = props.iGrnDocument.map(doc => {
		return ({
			description: doc.description,
			documentDate: doc.documentDate.toDate(),
		});
	}).getOrElseValue({
		description: "",
		documentDate: new Date(),
	});

	return (
		<Modal
			open={isOpen}
			onClose={() => onClose()}
			style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
			<div className={classes.wideModal}>
				<Formik
					initialValues={initialValues}
					enableReinitialize={true}
					validateOnBlur={true}
					validateOnChange={false}
					validationSchema={validationSchema}
					onSubmit={((values: iGrnFormValues, actions) => {
						setSubmitting(true);
						apiInstance.iGrnDocumentApi.putIGrnDocument(iGrnDocument.get.id, values.description,
							DateUtils.toUTCDate(values.documentDate), iGrnDocument.get.ginDocumentId)
							.then(() => {
								setSubmitting(false);
								actions.resetForm();
								onClose();
							}).catch((err: any) => {
							setSubmitting(false);
							console.error(err)
						})
					})}>
					{(formikProps: FormikProps<iGrnFormValues>) => (
						<Form noValidate={true}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography variant={"h6"}>Update IGRN Document</Typography>
									<Field
										id="description"
										label="Description"
										name="description"
										required={true}
										error={formikProps.errors.description && formikProps.touched.description}
										helperText={formikProps.errors.description &&
										formikProps.touched.description ?
											formikProps.errors.description : ""}
										as={FormTextField}
									/>
								</Grid>
								<Grid item xs={12}>
									<KeyboardDatePicker
										autoOk={true}
										disableToolbar
										inputVariant="outlined"
										required={true}
										fullWidth={true}
										maxDate={new Date()}
										format={DEFAULT_DATE_FORMAT}
										margin="dense"
										id="date-picker-inline"
										label="Document Date"
										value={formikProps.values.documentDate}
										onChange={(date: MaterialUiPickersDate) => {
											formikProps.setFieldValue("documentDate", date)
										}}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<div className={classes.modalButtonsRow}>
										<Button onClick={() => onClose()}>Close</Button>
										<Button type="submit" color={"primary"} variant={"contained"}>
											{isSubmitting ? <CircularProgress style={{color: "white"}} size={24}/> : "Update"}
										</Button>
									</div>
								</Grid>
							</Grid>
						</Form>
					)}
				</Formik>
			</div>
		</Modal>
	);
};

export default IGrnDocumentsModal