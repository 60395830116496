import React, {useEffect, useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import './App.css';
import Main from "./components/Main";
import Login from "./components/Login";
import {ThemeProvider} from '@material-ui/styles';
import {createMuiTheme, CssBaseline} from "@material-ui/core";
import {blueGrey} from "@material-ui/core/colors";
import {Provider} from 'react-redux';
import store from "./redux/strore";
import {PrivateRoute} from "./components/common/PrivateRoute";
import {ThunkDispatch} from "redux-thunk";
import {RootState} from "./redux/root/rootState";
import {AnyAction} from "redux";
import {fetchSettings} from "./redux/settings/settingsActions";
import TabSpinner from "./components/common/TabSpinner";
import AuthToken from "./models/AuthToken";
import {Option} from "ts-option";
import {tokenObtained} from "./redux/auth/authActions";
import LocalStorage from "./common/LocalStorage";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const theme = createMuiTheme({
    palette: {
        primary: {main: "#0095da"},
        secondary: blueGrey,
    },
});

export const storage = new LocalStorage();

const App: React.FC = () => {

    const [isLoading, setLoading] = useState(true);

    const checkToken = () => {
        if (store.getState().auth.authToken.isEmpty) {
            const tokenFromStorage: Option<AuthToken> = storage.getYosensiToken();
            if (tokenFromStorage.isDefined) {
                store.dispatch(tokenObtained(tokenFromStorage.get));
            }
        }
    };

    useEffect(() => {
        checkToken();
        (store.dispatch as ThunkDispatch<RootState, void, AnyAction>)(fetchSettings(() => {
            setLoading(false)
        }, () => {
            console.error("Could not fetch application settings.")
        }));
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Provider store={store}>
				<MuiPickersUtilsProvider utils={MomentUtils}>
                <CssBaseline/>
                {isLoading ?
                    <TabSpinner/> :
                    <Router>
                        <Switch>
                            <Route path="/auth" component={Login}/>
                            {process.env.NODE_ENV === "development" ?
                                <Route path="/" component={Main}/> :
                                <PrivateRoute component={Main}/>
                            }
                        </Switch>
                    </Router>
                }
				</MuiPickersUtilsProvider>
            </Provider>
        </ThemeProvider>
    );
};

export default App;
