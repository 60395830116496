import React from "react";
import {
    makeStyles,
    StyledComponentProps,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    withStyles
} from "@material-ui/core";
import {defaultStyles} from "../../styles/defaultStyles";
import InvoicesTableRow from "./InvoicesTableRow";
import InvoiceSummary from "../../models/InvoiceSummary";

const useStyles = makeStyles(defaultStyles);

interface IProps {
    invoices: InvoiceSummary[];
}

type Props = IProps & StyledComponentProps;

const InvoicesTable: React.FunctionComponent<Props> = (props: Props) => {
    const classes = useStyles();

    const {invoices} = props;
    return (
        <Table size={"small"}>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.tableHeader}>Supplier</TableCell>
                    <TableCell className={classes.tableHeader}>Invoice ID</TableCell>
                    <TableCell className={classes.tableHeader}>Invoice Date</TableCell>
                    <TableCell className={classes.tableHeader}>Currency</TableCell>
                    <TableCell className={classes.tableHeader}>FX Rate</TableCell>
                    <TableCell className={classes.tableHeader} style={{width: 152, textAlign:"center"}}>Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {invoices.map((invoice) => <InvoicesTableRow key={invoice.id} invoice={invoice}/>)}
            </TableBody>
        </Table>
    );
};

export default withStyles(defaultStyles)(InvoicesTable);