import React from "react";
import {Grid} from "@material-ui/core";
import PartCategories from "./part_categories/PartCategories";
import PartTypes from "./part_types/PartTypes";
import PartUnits from "./part_units/PartUnits";


const Settings: React.FunctionComponent<{}> = () => {
    return (
        <Grid container spacing={0}>
            <Grid item lg={4} md={6} sm={12}>
                <PartCategories/>
            </Grid>
            <Grid item lg={4} md={6} sm={12}>
                <PartTypes/>
            </Grid>
            <Grid item lg={4} md={6} sm={12}>
                <PartUnits/>
            </Grid>
        </Grid>
    );
};

export default Settings;