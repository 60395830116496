import {AxiosInstance} from "axios";
import Technology from "../models/Technology";
import {PartTechnologyApiDto, PartTechnologyItemApiDto} from "../models/_RestModels";
import TechnologyItem from "../models/TechnologyItem";
import {apiInstance} from "./Api";
import PartValidate from "../models/PartValidate";

export default class TechnologiesApi {
	constructor(public readonly axiosInstance: AxiosInstance) {}

	fetchTechnologies(): Promise<Technology[]> {
		const url = "/api/product_technologies";
		return this.axiosInstance.get(url).then(resp => resp.data.map((t: PartTechnologyApiDto) => Technology.fromJson(t)));
	}

	fetchTechnology(id: string): Promise<Technology> {
		const url = `/api/product_technologies/${id}`;
		return this.axiosInstance.get(url).then(resp => Technology.fromJson(resp.data));
	}


	postTechnology(name: string, partId: string): Promise<Technology> {
		const url = "/api/product_technologies";
		const config = {
			name,
			partId
		};
		return this.axiosInstance.post(url, config).then((resp) => Technology.fromJson(resp.data))
	}

	putTechnology(id: string, name: string, partId: string): Promise<Technology> {
		const url = `/api/product_technologies/${id}`;
		const config = {
			name,
			partId
		};
		return this.axiosInstance.put(url, config).then((resp) => Technology.fromJson(resp.data))
	}

	fetchTechnologyItems(id: string): Promise<TechnologyItem[]> {
		const url = `/api/product_technologies/${id}/items`;
		return this.axiosInstance.get(url).then(
			resp => resp.data.map((ti: PartTechnologyItemApiDto) => TechnologyItem.fromJson(ti)))
	}

	postTechnologyItem(id: string, partId: string, quantity: number): Promise<void> {
		const url = `/api/product_technologies/${id}/items`;
		const config = {partId, quantity};
		return this.axiosInstance.post(url, config)
	}

	deleteTechnologyItem(id: string) {
		const url = `/api/product_technology_items/${id}`;
		return this.axiosInstance.delete(url)
	}

	validateTechnologyId(technologyId: string, technologyQuantity: number): Promise<boolean> {
		return apiInstance.technologiesApi.fetchTechnologyItems(technologyId).then(items => {
			const validationItems = items.map(item => {
				const {partId, typeId, quantity} = item;
				return new PartValidate(partId, typeId.orNull, quantity * technologyQuantity)
			});
			return apiInstance.partsApi.validateTechnology(validationItems).then(validationRequest => {
				return validationRequest.get.valid;
			})
		})
	}
}