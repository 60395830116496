import React, {useState} from "react";
import {Option} from "ts-option";
import {Button, CircularProgress, makeStyles, Modal, Typography} from "@material-ui/core";
import {defaultStyles} from "../../../styles/defaultStyles";
import {useFormik} from "formik";
import {apiInstance} from "../../../api/Api";
import FormTextField from "../../common/FormTextField";
import PartUnit from "../../../models/PartUnit";

interface IProps {
    isOpen: boolean,
    partUnit: Option<PartUnit>,
    onClose: () => void,
}

interface PartUnitFormValues {
    nameEn: string,
    namePl: string,
    displayName: string,
}

const useStyles = makeStyles(defaultStyles);

const PartUnitsModal: React.FC<IProps> = (props: IProps) => {
    const {isOpen, partUnit, onClose} = props;
    const [isSubmitting, setSubmitting] = useState(false);
    const classes = useStyles();
    const initialValues: PartUnitFormValues = props.partUnit.map(partUnit => {
        return ({
            nameEn: partUnit.nameEn,
            namePl: partUnit.namePl,
            displayName: partUnit.displayName
        });
    }).getOrElseValue({
        nameEn: "",
        namePl: "",
        displayName: ""
    });
    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        onSubmit: ((values: PartUnitFormValues) => {
            const submitAction = partUnit.isDefined ?
                apiInstance.partsApi.putPartUnit(partUnit.get.id, values.nameEn, values.namePl, values.displayName) :
                apiInstance.partsApi.postPartUnit(values.nameEn, values.namePl, values.displayName);
            // TODO Handle errors nicely
            setSubmitting(true);
            submitAction.then(() => {
                setSubmitting(false);
                formik.resetForm();
                onClose();
            }).catch((err) => {
                setSubmitting(false);
                console.error(err)
            })
        })
    });
    return (
        <Modal
            open={isOpen}
            onClose={() => onClose()}
            style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        >
            <div className={classes.modal}>
                <Typography variant={"h6"}>{
                    partUnit.isDefined ?
                        "Update Part Unit" :
                        "Create New Part Unit"
                }</Typography>
                <form onSubmit={formik.handleSubmit}>
                    {partUnit.isDefined}
                    <FormTextField
                        id="nameEn"
                        label="Name En"
                        name="nameEn"
                        required={true}
                        onChange={formik.handleChange}
                        value={formik.values.nameEn}
                    />
                    <FormTextField
                        id="namePl"
                        label="Name Pl"
                        name="namePl"
                        required={true}
                        onChange={formik.handleChange}
                        value={formik.values.namePl}
                    />
                    <FormTextField
                        id="displayName"
                        label="Display Name"
                        name="displayName"
                        required={true}
                        onChange={formik.handleChange}
                        value={formik.values.displayName}
                    />
                    <div className={classes.modalButtonsRow}>
                        <Button onClick={() => onClose()}>Close</Button>
                        <Button type="submit" color={"primary"} variant={"contained"}>
                            {isSubmitting ? <CircularProgress style={{color: "white"}} size={24}/> :
                                partUnit.isDefined ? "Update" : "Create"}
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default PartUnitsModal