import React from "react";
import {makeStyles, StyledComponentProps, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {defaultStyles} from "../../../styles/defaultStyles";
import TechnologyItem from "../../../models/TechnologyItem";
import TechnologyItemsTableRow from "./TechnologyItemsTableRow";

const useStyles = makeStyles(defaultStyles);

interface IProps {
	technologyItems: TechnologyItem[];
	onDelete: (technologyItem: TechnologyItem) => void;
}

type Props = IProps & StyledComponentProps;

const TechnologyItemsTable: React.FC<Props> = (props: Props) => {
	const classes = useStyles({});

	const onDelete = (technologyItem: TechnologyItem) => () => {
		props.onDelete(technologyItem);
	};

	const {technologyItems} = props;
	return (
		<Table size={"small"}>
			<TableHead>
				<TableRow>
					<TableCell className={classes.tableHeader}>Category</TableCell>
					<TableCell className={classes.tableHeader}>Part Number</TableCell>
					<TableCell className={classes.tableHeader}>Manufacturer</TableCell>
					<TableCell className={classes.tableHeader}>Description</TableCell>
					<TableCell className={classes.tableHeader}>Quantity</TableCell>
					<TableCell className={classes.tableHeader}>Unit</TableCell>
					<TableCell className={classes.tableHeader} style={{width: 152, textAlign: "center"}}>Actions</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{technologyItems.map((item) => <TechnologyItemsTableRow key={item.id} technologyItem={item}
																	  onDelete={onDelete(item)}/>)}
			</TableBody>
		</Table>
	);
};

export default TechnologyItemsTable
