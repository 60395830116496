import React from "react";
import {makeStyles, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {defaultStyles} from "../../styles/defaultStyles";
import Part from "../../models/Part";
import PartsTableRow from "./PartsTableRow";
import PartUnit from "../../models/PartUnit";
import PartType from "../../models/PartType";
import PartCategory from "../../models/PartCategory";
import PartState from "../../models/PartState";

interface IProps {
    parts: Part[],
    units: PartUnit[],
    types: PartType[],
    states: PartState[],
    categories: PartCategory[],
}

const useStyles = makeStyles(defaultStyles);

const PartsTable: React.FC<IProps> = (props: IProps) => {
    const {units, types, categories, parts, states} = props;
    const partRows: PartTableRow[] = parts.map((part: Part) => {
        const {id, categoryId, unitId, partNumber, manufacturer, descriptionPl, descriptionEn} = part;
        const category = categories.filter(c => c.id === part.categoryId)[0];
        const unit = units.filter(u => u.id === part.unitId)[0];
        const type = types.filter(t => t.id === part.typeId)[0];
        const state = states.filter(s => s.partId === part.id)[0];
        return ({
            category: category ? category.name : categoryId,
            unit: unit ? unit.displayName : unitId,
			typeId: type ? type.typeId : part.typeId,
			quantity: state ? state.quantity : 0,
            id, partNumber, manufacturer, descriptionEn, descriptionPl,
        })
    });

    const classes = useStyles();
    return (
        <>
            <Table size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHeader}>Category</TableCell>
                        <TableCell className={classes.tableHeader}>Type</TableCell>
                        <TableCell className={classes.tableHeader}>Manufacturer</TableCell>
                        <TableCell className={classes.tableHeader}>Part Number</TableCell>
                        <TableCell className={classes.tableHeader}>Description En</TableCell>
                        <TableCell className={classes.tableHeader}>Description Pl</TableCell>
                        <TableCell className={classes.tableHeader}>Quantity</TableCell>
                        <TableCell className={classes.tableHeader}>Units</TableCell>
                        <TableCell className={classes.tableHeader}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {partRows
						.map((partRow) => <PartsTableRow
                            key={partRow.id}
                            partRow={partRow}/>)}
                </TableBody>
            </Table>
        </>
    );
};

export default PartsTable;

export interface PartTableRow {
    id: string,
	quantity: number,
    category: string,
    typeId: string,
    manufacturer: string,
    partNumber: string,
    descriptionEn: string,
    descriptionPl: string,
    unit: string
}