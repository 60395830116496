import React from "react";
import {IconButton, TableCell, TableRow} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import PartUnit from "../../../models/PartUnit";

interface IProps {
    partUnit: PartUnit;
    onEdit: () => void;
}

const PartUnitsTableRow: React.FunctionComponent<IProps> = (props: IProps) => {
    const {partUnit, onEdit} = props;
    return (
        <TableRow>
            <TableCell>{partUnit.nameEn}</TableCell>
            <TableCell>{partUnit.namePl}</TableCell>
            <TableCell>{partUnit.displayName}</TableCell>
            <TableCell style={{textAlign: "center"}}>
                <IconButton size={"small"} onClick={onEdit}>
                    <EditIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default PartUnitsTableRow;