import React from "react";
import ValidateRequestItem from "../../models/ValidateRequestItem";
import Part from "../../models/Part";
import PartType from "../../models/PartType";
import {makeStyles, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {defaultStyles} from "../../styles/defaultStyles";
import TechnologyValidationTableRow from "./TechnologyValidationTableRow";

interface IProps {
	reqItems: ValidateRequestItem[],
	parts: Part[],
	types: PartType[],
}

const useStyles = makeStyles(defaultStyles);

const TechnologyValidationTable: React.FC<IProps> = (props: IProps) => {

	const {reqItems, parts, types} = props;

	const classes = useStyles();

	return (
		<Table size={"small"} style={{width: "100%"}}>
			<TableHead>
				<TableRow>
					<TableCell className={classes.tableHeader}>Part</TableCell>
					<TableCell className={classes.tableHeader}>Type</TableCell>
					<TableCell className={classes.tableHeader} style={{textAlign: "center"}}>Requested qty.</TableCell>
					<TableCell className={classes.tableHeader} style={{textAlign: "center"}}>Available qty.</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{reqItems.map(
					item => <TechnologyValidationTableRow key={item.requestedPartId} req={item}
														  parts={parts} types={types}/>)
				}
			</TableBody>
		</Table>
	);
};

export default TechnologyValidationTable
