import React, {useEffect, useState} from "react";
import PartsForm from "./PartsForm";
import {none, option, Option} from "ts-option";
import {makeStyles, Paper} from "@material-ui/core";
import {defaultStyles} from "../../styles/defaultStyles";
import {useParams} from "react-router";
import {apiInstance} from "../../api/Api";
import Part from "../../models/Part";
import TabSpinner from "../common/TabSpinner";

const useStyles = makeStyles(defaultStyles);

const PartsEdit = () => {
    const classes = useStyles();

    const {partId} = useParams();
    const [isLoading, setLoading] = useState<boolean>(true);
    const [part, setPart] = useState<Option<Part>>(none);

    useEffect(() => {
        const fetchData = async () => {
            const part = await apiInstance.partsApi.fetchPart(partId as string);
            setPart(option(part))
        };
        fetchData()
            .then(() => setLoading(false))
            .catch((err: Error) => {
                // TODO Handle error
                console.error(err)
            });
    }, [partId]);

    return (
        <Paper className={classes.defaultPaper}>
            {
                isLoading ?
                    <TabSpinner/> :
                    <PartsForm part={part}/>
            }
        </Paper>
    )
};

export default PartsEdit;