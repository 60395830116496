import React from "react";
import {Chip, IconButton, TableCell, TableRow} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import Technology from "../../models/Technology";
import {useHistory} from "react-router-dom";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconGrn from "@material-ui/icons/AssignmentReturn";

interface IProps {
	technology: Technology;
	onEdit: () => void;
	onCopy: () => void;
	onValidate: () => void,
}

const TechnologyTableRow: React.FunctionComponent<IProps> = (props: IProps) => {
	const {technology, onEdit, onCopy, onValidate} = props;

	const history = useHistory();

	return (
		<TableRow>
			<TableCell>{technology.name}</TableCell>
			<TableCell>{technology.partNumber}</TableCell>
			<TableCell>{technology.partDescriptionEn}</TableCell>
			<TableCell style={{width: 200, textAlign: "center"}}>
				<Chip size={"small"} variant={"outlined"} label={"Validate"} onClick={onValidate}/>
				<IconButton size={"small"} onClick={() => history.push(`/technologies/${technology.id}/items`)}>
					<IconGrn style={{transform: "scaleX(-1)"}}/>
				</IconButton>
				<IconButton size={"small"} onClick={onEdit}>
					<EditIcon/>
				</IconButton>
				<IconButton size={"small"} onClick={onCopy}>
					<FileCopyIcon/>
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default TechnologyTableRow;