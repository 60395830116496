import React from "react";
import {IconButton, TableCell, TableRow} from "@material-ui/core";
import Client from "../../models/Client";
import EditIcon from '@material-ui/icons/Edit';
import {useHistory} from "react-router-dom";

interface IProps {
    client: Client;
}

const ClientTableRow: React.FunctionComponent<IProps> = (props: IProps) => {
    const history = useHistory();
    const {client} = props;
    return (
        <TableRow>
            <TableCell>{client.externalId}</TableCell>
            <TableCell>{client.fullName}</TableCell>
            <TableCell>{client.shortName}</TableCell>
            <TableCell>{client.street}</TableCell>
            <TableCell>{client.city}</TableCell>
            <TableCell>{client.zipCode}</TableCell>
            <TableCell>{client.country}</TableCell>
            <TableCell>
                <IconButton size={"small"} onClick={()=> history.push(`clients/${client.id}`)}>
                    <EditIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default ClientTableRow;