import React from "react";
import {IconButton, TableCell, TableRow} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import {useHistory} from "react-router-dom";
import {PartTableRow} from "./PartsTable";

interface IProps {
    partRow: PartTableRow;
}

const PartsTableRow: React.FunctionComponent<IProps> = (props: IProps) => {
    const history = useHistory();
    const {partRow} = props;
    return (
        <TableRow>
            <TableCell>{partRow.category}</TableCell>
            <TableCell>{partRow.typeId}</TableCell>
            <TableCell>{partRow.manufacturer}</TableCell>
            <TableCell>{partRow.partNumber}</TableCell>
            <TableCell>{partRow.descriptionEn}</TableCell>
            <TableCell>{partRow.descriptionPl}</TableCell>
            <TableCell>{partRow.quantity}</TableCell>
            <TableCell>{partRow.unit}</TableCell>
            <TableCell>
                <IconButton size={"small"} onClick={()=> history.push(`parts/${partRow.id}`)}>
                    <EditIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default PartsTableRow;