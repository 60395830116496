import React, {useState} from "react";
import {StyledComponentProps, Table, TableBody, TableCell, TableHead, TableRow, withStyles} from "@material-ui/core";
import {defaultStyles} from "../../../styles/defaultStyles";
import PartCategory from "../../../models/PartCategory";
import PartCategoriesTableRow from "./PartCategoriesTableRow";
import PartCategoryModal from "./PartCategoriesModal";
import {none, option, Option} from "ts-option";

interface IProps {
    partCategories: PartCategory[];
    onClose: () => void,
}

type Props = IProps & StyledComponentProps;

const PartCategoriesTable: React.FunctionComponent<Props> = (props: Props) => {
    const [isOpen, setOpen] = useState(false);
    const [partCategory, setPartCategory] = useState<Option<PartCategory>>(none);
    const {partCategories} = props;
    const {classes}: any = props;

    const onEdit = (partCategory: PartCategory) => () => {
        setPartCategory(option(partCategory));
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
        props.onClose()
    };

    return (
		<div className={classes.settingsTableContainer}>
            <Table size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHeader}>Category Name</TableCell>
                        <TableCell className={classes.tableHeader} style={{width: 80}}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {partCategories
                        .map((partCategory) => <PartCategoriesTableRow
                            key={partCategory.id}
                            partCategory={partCategory}
                            onEdit={onEdit(partCategory)}/>)}
                </TableBody>
            </Table>
            <PartCategoryModal isOpen={isOpen}
                               partCategory={partCategory}
                               onClose={() => onClose()}/>
        </div>
    );
};

export default withStyles(defaultStyles)(PartCategoriesTable);