import {Option} from "ts-option";
import {
	Button, CircularProgress, FormControl, FormHelperText,
	Grid, InputLabel,
	makeStyles, MenuItem,
	Modal, Select,
	Typography
} from "@material-ui/core";
import {Field, Form, Formik, FormikProps} from "formik";
import {defaultStyles} from "../../styles/defaultStyles";
import {apiInstance} from "../../api/Api";
import FormTextField from "../common/FormTextField";
import * as Yup from "yup";
import React, {useState} from "react";
import {KeyboardDatePicker, MaterialUiPickersDate} from "@material-ui/pickers";
import {DEFAULT_DATE_FORMAT} from "../../common/Constants";
import EGinDocument from "../../models/EGinDocument";
import Client from "../../models/Client";
import DateUtils from "../../common/DateUtils";

const validationSchema = Yup.object().shape({
	description: Yup.string()
		.required('Description is required'),
	clientId: Yup.string().test("is-selected", "Client is required", (value => value !== "select")),
});

interface IProps {
	isOpen: boolean,
	eGinDocument: Option<EGinDocument>,
	clients: Client[],
	onClose: () => void,
}

interface EGinFormValues {
	description: string,
	clientId: string,
	documentDate: Date,
}

const useStyles = makeStyles(defaultStyles);

const EGinDocumentsModal: React.FC<IProps> = (props: IProps) => {
	const {isOpen, eGinDocument, onClose, clients} = props;
	const [isSubmitting, setSubmitting] = useState(false);

	const classes = useStyles({});

	const initialValues: EGinFormValues = eGinDocument.map(doc => {
		return ({
			description: doc.description,
			documentDate: doc.documentDate.toDate(),
			clientId: doc.clientId
		});
	}).getOrElseValue({
		description: "",
		documentDate: new Date(),
		clientId: "select",
	});

	return (
		<Modal
			open={isOpen}
			onClose={() => onClose()}
			style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
			<div className={classes.wideModal}>
				<Formik
					initialValues={initialValues}
					enableReinitialize={true}
					validateOnBlur={true}
					validateOnChange={false}
					validationSchema={validationSchema}
					onSubmit={((values: EGinFormValues, actions) => {
						setSubmitting(true);
						const client = clients.filter(cl => cl.id === values.clientId)[0];
						if (client) {
							const submitAction = eGinDocument.isDefined ?
								apiInstance.eGinDocumentApi.putEGinDocument(
									eGinDocument.get.id,
									values.description,
									DateUtils.toUTCDate(values.documentDate),
									client.id,
									client.externalId,
									client.fullName,
									client.shortName,
									client.street,
									client.city,
									client.zipCode,
									client.country
								) :
								apiInstance.eGinDocumentApi.postEGinDocument(
									values.description,
									DateUtils.toUTCDate(values.documentDate),
									client.id,
									client.externalId,
									client.fullName,
									client.shortName,
									client.street,
									client.city,
									client.zipCode,
									client.country
								);

							// TODO Handle errors nicely
							submitAction.then(() => {
								setSubmitting(false);
								actions.resetForm();
								onClose();
							}).catch((err) => {
								setSubmitting(false);
								console.error(err)
							})
						}
					})}>
					{(formikProps: FormikProps<EGinFormValues>) => (
						<Form noValidate={true}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography variant={"h6"}>{
										eGinDocument.isDefined ?
											"Update EGIN Document" :
											"Create New EGIN Document"
									}</Typography>
									<Field
										id="description"
										label="Description"
										name="description"
										required={true}
										error={formikProps.errors.description && formikProps.touched.description}
										helperText={formikProps.errors.description &&
										formikProps.touched.description ?
											formikProps.errors.description : ""}
										as={FormTextField}
									/>
								</Grid>
								<Grid item xs={12}>
									<KeyboardDatePicker
										autoOk={true}
										disableToolbar
										inputVariant="outlined"
										required={true}
										fullWidth={true}
										maxDate={new Date()}
										format={DEFAULT_DATE_FORMAT}
										margin="dense"
										id="date-picker-inline"
										label="Document Date"
										value={formikProps.values.documentDate}
										onChange={(date: MaterialUiPickersDate) => {
											formikProps.setFieldValue("documentDate", date)
										}}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<FormControl variant={"outlined"} fullWidth={true} style={{marginTop: 8}}
												 error={Boolean(
													 formikProps.errors.clientId &&
													 formikProps.touched.clientId)}>
										<InputLabel>Select Client</InputLabel>
										<Field name={"clientId"} autoWidth={true} labelWidth={95} as={Select}
											   margin={"dense"} required={true}>
											<MenuItem value={"select"}>Select Client</MenuItem>
											{clients.sort(
												(a, b) => a.shortName.localeCompare(b.shortName)).map(
												client => <MenuItem
													value={client.id} key={client.id}>{client.shortName}</MenuItem>)}
										</Field>
										{formikProps.errors.clientId && formikProps.touched.clientId &&
										<FormHelperText>{formikProps.errors.clientId}</FormHelperText>
										}
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<div className={classes.modalButtonsRow}>
										<Button onClick={() => onClose()}>Close</Button>
										<Button type="submit" color={"primary"} variant={"contained"}>
											{isSubmitting ? <CircularProgress style={{color: "white"}} size={24}/> :
												eGinDocument.isDefined ? "Update" : "Create"}
										</Button>
									</div>
								</Grid>
							</Grid>
						</Form>
					)}
				</Formik>
			</div>
		</Modal>
	);
};

export default EGinDocumentsModal