import React from "react";
import {IconButton, TableCell, TableRow} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import PartCategory from "../../../models/PartCategory";

interface IProps {
    partCategory: PartCategory;
    onEdit: () => void;
}

const PartCategoriesTableRow: React.FunctionComponent<IProps> = (props: IProps) => {
    const {partCategory, onEdit} = props;
    return (
        <TableRow>
            <TableCell>{partCategory.name}</TableCell>
            <TableCell style={{textAlign: "center"}}>
                <IconButton size={"small"} onClick={onEdit}>
                    <EditIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default PartCategoriesTableRow;