import {EGinDocumentItemApiDto} from "./_RestModels";
import {option, Option} from "ts-option";

export default class EGinDocumentItem {
	constructor(
		public readonly id: string,
		public readonly partId: string,
		public readonly typeId: Option<string>,
		public readonly partNumber: string,
		public readonly descriptionPl: string,
		public readonly quantity: number,
		public readonly totalValue: number
	) {}

	public static fromJson(json: EGinDocumentItemApiDto): EGinDocumentItem {
		const typeId = option(json.typeId);
		const {id, partId, partNumber, descriptionPl, quantity, totalValue} = json;
		return new EGinDocumentItem(id, partId, typeId, partNumber, descriptionPl, quantity, totalValue)
	}
}