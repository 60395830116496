import {createStyles, Theme} from "@material-ui/core";
import {grey, red} from "@material-ui/core/colors";

export const defaultStyles = (theme: Theme) => createStyles({
    defaultPaper: {
        padding: theme.spacing(3),
        margin: theme.spacing(3),
        height: "100%",
        overflowY: "auto",
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            margin: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
            padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
        },
    },
    tablePaper: {
        margin: theme.spacing(2),
        height: "100%",
        overflowY: "hidden",
		position: "relative",
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            margin: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
            padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
        },
    },
	settingsTablePaper: {
		margin: theme.spacing(2),
		height: "100%",
		maxHeight: 415,
		overflowY: "auto",
		flexGrow: 1,
		[theme.breakpoints.down('sm')]: {
			margin: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
			padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
		},
	},
	settingsTableContainer: {
    	maxHeight: 295,
		overflowY: "auto"
	},
    tableHeader: {
        fontSize: "90%",
        opacity: 0.8,
        fontWeight: "bold"
    },
    buttonsRow: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    modal: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
	wideModal: {
		position: 'absolute',
		width: 600,
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	extraWideModal: {
		position: 'absolute',
		width: 900,
		maxHeight: "80%",
		overflowY: "auto",
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
    modalButtonsRow: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: theme.spacing(2),
    },
	textMuted: {
		color: grey[600]
	},
	textError: {
		color: red[700]
	},
});