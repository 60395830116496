import {option, Option} from "ts-option";
import {GinPartStateApiDto} from "./_RestModels";

export default class GinPartState {
	constructor(public readonly id: string,
		public readonly partId: string,
		public readonly typeId: Option<string>,
		public readonly partNumber: string,
		public readonly descriptionPl: string,
		public readonly quantity: number,
		public readonly totalValue: number,
		public readonly fromTechnology: boolean) {}

		public static fromJson(json: GinPartStateApiDto): GinPartState {
		const typeId = option(json.typeId);
		return new GinPartState(json.id, json.partId, typeId, json.partNumber, json.descriptionPl, json.quantity, json.totalValue, json.fromTechnology)
		}
}