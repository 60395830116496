import React, {useEffect, useState} from "react";
import {makeStyles, Paper} from "@material-ui/core";
import {defaultStyles} from "../../styles/defaultStyles";
import {apiInstance} from "../../api/Api";
import TabSpinner from "../common/TabSpinner";
import {none, Option, option} from "ts-option";
import IGrnDocument from "../../models/IGrnDocument";
import IGrnDocumentsTable from "./IGrnDocumentsTable";
import IGrnDocumentsModal from "./IGrnDocumentsModal";
import Scrollable from "../common/Scrollable";

const useStyles = makeStyles(defaultStyles);

const IGrnDocuments: React.FC<{}> = () => {

	const classes = useStyles();

	const [isLoading, setLoading] = useState<boolean>(true);
	const [isOpen, setOpen] = useState(false);
	const [iGrnDocuments, setIGrnDocuments] = useState<IGrnDocument[]>([]);
	const [iGrnDocument, setIGrnDocument] = useState<Option<IGrnDocument>>(none);

	const fetchData = async () => {
		const iGrnDocuments = await apiInstance.iGrnDocumentApi.fetchIGrnDocuments();
		setIGrnDocuments(iGrnDocuments);
		setLoading(false);
	};

	useEffect(() => {
		fetchData()
			.then(() => setLoading(false))
			.catch(() => {
				// TODO Handle error
				setLoading(false)
			});
	}, []);

	const onEdit = (document: IGrnDocument) => {
		setOpen(true);
		setIGrnDocument(option(document))
	};

	const onDelete = (document: IGrnDocument) => {
		if (window.confirm("Delete IGRN Document?")) {
			apiInstance.iGrnDocumentApi.deleteIGrnDocument(document.id).then(() => {
				setLoading(true);
				fetchData()
					.then(() => setLoading(false))
					.catch((err: any) => {
						console.error(err);
						setLoading(false)
					});
			}).catch((err: any) => console.error(err))
		}
	};

	const onClose = () => {
		setOpen(false);
		setLoading(true);
		fetchData()
			.then(() => setLoading(false))
			.catch(() => {
				// TODO Handle error
				setLoading(false)
			});
		setIGrnDocument(none)
	};

	return (
		<Paper className={classes.tablePaper}>
			{isLoading ?
				<TabSpinner/> :
				<>
					<Scrollable>
						<IGrnDocumentsTable iGrnDocuments={iGrnDocuments} onEdit={onEdit} onDelete={onDelete}/>
					</Scrollable>
					<IGrnDocumentsModal isOpen={isOpen} iGrnDocument={iGrnDocument} onClose={onClose}/>
				</>
			}
		</Paper>
	);
};

export default IGrnDocuments