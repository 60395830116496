import React, {useEffect, useState} from "react";
import {makeStyles, Paper} from "@material-ui/core";
import {defaultStyles} from "../../styles/defaultStyles";
import TabSpinner from "../common/TabSpinner";
import {apiInstance} from "../../api/Api";
import InvoicesTable from "./InvoicesTable";
import {useHistory} from "react-router-dom";
import InvoiceSummary from "../../models/InvoiceSummary";
import Scrollable from "../common/Scrollable";
import AddFab from "../common/AddFab";

const useStyles = makeStyles(defaultStyles);

const Invoices: React.FC = () => {

	const history = useHistory();
	const classes = useStyles();
	const [isLoading, setLoading] = useState<boolean>(true);
	const [invoices, setInvoices] = useState<InvoiceSummary[]>([]);

	const fetchData = async () => {
		const invoices = await apiInstance.invoicesApi.fetchInvoices();
		setInvoices(invoices);
		setLoading(false);
	};

	useEffect(() => {
		fetchData()
			.then(() => setLoading(false))
			.catch(() => {
				// TODO Handle error
				setLoading(false)
			});
	}, []);

	return (
		<Paper className={classes.tablePaper}>
			{isLoading ?
				<TabSpinner/> :
				<>
					<Scrollable>
						<InvoicesTable invoices={invoices}/>
					</Scrollable>
					<AddFab onClick={() => history.push("/invoices/create")}/>
				</>
			}
		</Paper>
	);
};

export default Invoices