import React, {useState} from "react";
import GrnItemsTable from "./GrnItemsTable";
import GrnDocument from "../../../models/GrnDocument";
import GrnItemsModal from "./GrnItemsModal";
import {Button, CircularProgress, Divider, Fab, Typography} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import GrnDocumentItem from "../../../models/GrnDocumentItem";
import {none, option, Option} from "ts-option";
import {apiInstance} from "../../../api/Api";
import AddMultiContainer from "../../common/AddMultiContainer";
import {GrnItemRow} from "../GrnEdit";

interface IProps {
	grn: GrnDocument
	items: GrnItemRow[]
	onChange: () => void;
}

const GrnItems: React.FC<IProps> = (props: IProps) => {

	const [isOpen, setOpen] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);
	const [grnItem, setGrnItem] = useState<Option<GrnDocumentItem>>(none);
	const {grn, onChange, items} = props;

	const onCreate = () => {
		setOpen(true)
	};

	const onClose = () => {
		setOpen(false);
		onChange()
	};

	const onDelete = (grnItem: GrnDocumentItem) => {
		apiInstance.invoicesApi.deleteInvoiceGrnItem(grnItem.id).then(() => {
			onChange()
		});
	};

	const onEdit = (grnItem: GrnDocumentItem) => {
		setOpen(true);
		setGrnItem(option(grnItem))
	};

	const getTotalValue = () => {
		return (grn.grnItems.reduce(((ac, current) => ac + current.totalValue), 0) / 100).toFixed(2)
	};

	const onDocumentAccept = () => {
		if (window.confirm("Accepted document can not be edited any more.\nAccept anyway?")) {
			setSubmitting(true);
			apiInstance.invoicesApi.acceptGrnDocument(grn.invoiceId).then(() => {
				setSubmitting(false);
				onChange();
			}).catch((err: any) => {
				setSubmitting(false);
				console.error(err)
			})
		}
	};

	return (
		<>
			<Typography style={{margin: 16}} variant={"body1"}>GRN Document: <strong>{grn.documentNumber}</strong></Typography>
			<Divider/>
			<GrnItemsTable grn={grn} items={items} onDelete={onDelete} onEdit={onEdit}/>
			<GrnItemsModal onClose={onClose} isOpen={isOpen} grnItem={grnItem}/>
			<Typography variant={"body1"} style={{marginTop: 8, marginRight: 8, textAlign: "right"}}>Total
				value: {getTotalValue()} {grn.invoiceCurrency}</Typography>
			<AddMultiContainer>
				{
					grn.acceptedAt.isEmpty &&
					<>
						<Button variant={"contained"} color={"primary"}
								onClick={onDocumentAccept}
								disabled={grn.grnItems.length === 0}>
							{isSubmitting ?
								<CircularProgress style={{color: "white"}} size={24}/>
								: "Accept Document"}
						</Button>
						< Fab size={"small"} color={"primary"}
							  onClick={onCreate}><AddIcon/>
						</Fab>
					</>
				}
			</AddMultiContainer>
		</>
	);
};

export default GrnItems;