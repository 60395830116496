import {Option} from "ts-option";
import {
	Button, Checkbox,
	CircularProgress,
	FormControl, FormControlLabel, FormHelperText,
	Grid, IconButton, InputAdornment,
	InputLabel,
	makeStyles,
	MenuItem,
	Modal,
	Select, TextField,
	Typography
} from "@material-ui/core";
import {Field, Form, Formik, FormikProps} from "formik";
import * as Yup from "yup";
import {defaultStyles} from "../../../styles/defaultStyles";
import TechnologyItem from "../../../models/TechnologyItem";
import FormTextField from "../../common/FormTextField";
import {apiInstance} from "../../../api/Api";
import Part from "../../../models/Part";
import React, {useEffect, useState} from "react";
import TabSpinner from "../../common/TabSpinner";
import {useParams} from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";

const validationSchema = Yup.object().shape({
	partId: Yup.string().test("is-selected", "Part is required", (value => value !== "select")),
	quantity: Yup.number()
		.required('Quantity is required')
		.min(0, 'Quantity have to be greater than 0'),
});

interface IProps {
	isOpen: boolean,
	technologyItem: Option<TechnologyItem>,
	onClose: () => void,
	onCreate: () => void,
}

interface TechnologyItemFormValues {
	partId: string,
	quantity: string,
}

const useStyles = makeStyles(defaultStyles);

const TechnologyItemModal: React.FC<IProps> = (props: IProps) => {

	const {isOpen, technologyItem, onClose, onCreate} = props;
	const [isSubmitting, setSubmitting] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [parts, setParts] = useState<Part[]>([]);
	const [isAnotherOne, setAnotherOne] = useState<boolean>(false);
	const [filterString, setFilterString] = useState<string>("");

	const {technologyId} = useParams();

	const classes = useStyles({});

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const parts = await apiInstance.partsApi.fetchParts();
			setParts(parts);
		};
		fetchData()
			.then(() => setLoading(false))
			.catch((err: Error) => {
				// TODO Handle error
				setLoading(false);
				console.error(err)
			});
	}, []);

	const initialValues: TechnologyItemFormValues = props.technologyItem.map(item => {
		return ({
			partId: item.partId,
			quantity: item.quantity.toString(),
		});
	}).getOrElseValue({
		partId: "select",
		quantity: "",
	});

	const filteredParts = parts.filter(part => {
		return filterString.length ? part.partNumber.toLowerCase().includes(filterString.toLowerCase()) : true
	}).sort((a, b) => a.partNumber.localeCompare(b.partNumber));

	return (
		<Modal
			open={isOpen}
			onClose={() => onClose()}
			style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
		>
			<div className={classes.extraWideModal}>
				{
					isLoading ?
						<TabSpinner/> :
						<>
							<Formik
								initialValues={initialValues}
								enableReinitialize={true}
								validateOnBlur={true}
								validateOnChange={false}
								validationSchema={validationSchema}
								onSubmit={((values: TechnologyItemFormValues, actions) => {
									setSubmitting(true);
									apiInstance.technologiesApi.postTechnologyItem(technologyId as string, values.partId, Number(values.quantity)).then(() => {
										setSubmitting(false);
										actions.resetForm();
										if (!isAnotherOne)
											onCreate();
									}).catch((err) => {
										setSubmitting(false);
										console.error(err)
									})
								})}>
								{(formikProps: FormikProps<TechnologyItemFormValues>) => (
									<Form noValidate={true}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<Typography variant={"h6"}>{
													technologyItem.isDefined ?
														"Update Product Technology Item" :
														"Create New Product Technology Item"
												}</Typography>
											</Grid>
											<Grid item xs={4}>
												<TextField
													variant={"outlined"}
													fullWidth={true}
													label={"Parts Filter"}
													margin={"dense"}
													id="partsFilter"
													value={filterString}
													onChange={(evt) => {
														formikProps.setFieldValue("partId", "select");
														setFilterString(evt.target.value)
													}}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton
																	size={"small"}
																	onClick={() => setFilterString("")}
																	disabled={!filterString.length}
																>
																	<CloseIcon/>
																</IconButton>
															</InputAdornment>
														)
													}}
												/>
											</Grid>
											<Grid item xs={4}>
												<FormControl variant={"outlined"} fullWidth={true} style={{marginTop: 8}}
															 error={Boolean(
																 formikProps.errors.partId && formikProps.touched.partId)}>
													<InputLabel>Select Part</InputLabel>
													<Field name={"partId"} autoWidth={true} labelWidth={85} as={Select}
														   margin={"dense"} required={true}
														   disabled={isLoading}>
														<MenuItem value={"select"}>Select Part</MenuItem>
														{filteredParts.map(
															part => <MenuItem
																value={part.id} key={part.id}>{part.partNumber}</MenuItem>)}
													</Field>
													{formikProps.errors.partId && formikProps.touched.partId &&
													<FormHelperText>{formikProps.errors.partId}</FormHelperText>
													}
												</FormControl>
											</Grid>
											<Grid item xs={4}>
												<Field
													id="quantity"
													label="Quantity"
													name="quantity"
													required={true}
													type={"number"}
													error={formikProps.errors.quantity && formikProps.touched.quantity}
													helperText={formikProps.errors.quantity && formikProps.touched.quantity ?
														formikProps.errors.quantity : ""}
													as={FormTextField}
												/>
											</Grid>
											<Grid item xs={12}>
												<div className={classes.modalButtonsRow}>
													<Button onClick={() => onClose()}>Close</Button>
													{technologyItem.isEmpty &&
													<FormControlLabel
														control={
															<Checkbox
																checked={isAnotherOne}
																onChange={() => setAnotherOne(!isAnotherOne)}
																value={isAnotherOne}
																color="primary"
															/>
														}
														label="Create another one"
													/>
													}
													<Button type="submit" color={"primary"} variant={"contained"}>
														{isSubmitting ? <CircularProgress style={{color: "white"}} size={24}/> :
															technologyItem.isDefined ? "Update" : "Create"}
													</Button>
												</div>
											</Grid>
										</Grid>
									</Form>
								)}
							</Formik>
						</>
				}
			</div>
		</Modal>
	);
};

export default TechnologyItemModal