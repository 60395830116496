import React from "react";
import {
	makeStyles,
	StyledComponentProps,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	withStyles
} from "@material-ui/core";
import {defaultStyles} from "../../styles/defaultStyles";
import GinDocument from "../../models/GinDocument";
import GinDocumentsTableRow from "./GinDocumentsTableRow";
import {GinDocumentTableRow} from "./GinDocuments";

const useStyles = makeStyles(defaultStyles);

interface IProps {
	ginDocuments: GinDocumentTableRow[];
	onEdit: (document: GinDocument) => void;
	onDelete: (document: GinDocument) => void;
	onDocCreate: (document: GinDocument) => void;
}

type Props = IProps & StyledComponentProps;

const GinDocumentsTable: React.FunctionComponent<Props> = (props: Props) => {
	const classes = useStyles({});

	const onEdit = (document: GinDocument) => () => {
		props.onEdit(document);
	};

	const onDocCreate = (document: GinDocument) => () => {
		props.onDocCreate(document);
	};

	const onDelete = (document: GinDocument) => () => {
		props.onDelete(document);
	};

	const {ginDocuments} = props;
	return (
		<Table size={"small"}>
			<TableHead>
				<TableRow>
					<TableCell className={classes.tableHeader}>Document Number</TableCell>
					<TableCell className={classes.tableHeader}>Document Date</TableCell>
					<TableCell className={classes.tableHeader}>Description</TableCell>
					<TableCell className={classes.tableHeader}>Technology Name</TableCell>
					<TableCell className={classes.tableHeader} style={{textAlign: "center"}}>Technology Quantity</TableCell>
					<TableCell className={classes.tableHeader} style={{width: 152, textAlign:"center"}}>Actions</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{ginDocuments.map((doc) => <GinDocumentsTableRow key={doc.id} document={doc} onEdit={onEdit(doc)} onDelete={onDelete(doc)} onDocCreate={onDocCreate(doc)}/>)}
			</TableBody>
		</Table>
	);
};

export default withStyles(defaultStyles)(GinDocumentsTable);