import {Action} from "redux-actions";
import {apiInstance} from "../../api/Api";
import {AnyAction, Dispatch} from "redux";
import {OnFailure, OnSuccessData} from "../actionHelpers";
import Settings from "../../models/Settings";
import {SETTINGS_FETCHED} from "./settingsActionTypes";

export function settingsFetched(settings: Settings): Action<SETTINGS_FETCHED> {
    return {type: SETTINGS_FETCHED, payload: {settings}};
}

export function fetchSettings(onSuccess: OnSuccessData<Settings>, onFailure: OnFailure) {
    return function (dispatch: Dispatch<AnyAction>) {
        if (process.env.NODE_ENV === "development" && process.env.REACT_APP_OAUTH_CLIENT_ID) {
            const settings = new Settings(process.env.REACT_APP_OAUTH_CLIENT_ID);
            dispatch(settingsFetched(settings));
            onSuccess(settings);
        } else {
            apiInstance.settingsApi.fetchSettings().then(settings => {
                dispatch(settingsFetched(settings));
                onSuccess(settings);
            }).catch(onFailure)
        }
    }
}