import React from "react";
import {IconButton, TableCell, TableRow} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import {useHistory} from "react-router-dom";
import {DEFAULT_DATE_FORMAT} from "../../common/Constants";
import IconAddCircle from '@material-ui/icons/AddCircleOutline';
import IconGrn from '@material-ui/icons/AssignmentReturn';
import InvoiceSummary from "../../models/InvoiceSummary";
import {apiInstance} from "../../api/Api";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

interface IProps {
	invoice: InvoiceSummary;
}

const InvoicesTableRow: React.FunctionComponent<IProps> = (props: IProps) => {
	const history = useHistory();
	const {invoice} = props;

	const createGrnEndEdit = () => {
		return apiInstance.invoicesApi.postInvoiceGrn(invoice.id)
			.then((grnDocument) => {
				history.push(`invoices/${invoice.id}/grn_document/${grnDocument.id}`)
			})
			// TODO Handle error using modal
			.catch((err: any) => console.error(err))
	};

	return (
		<TableRow>
			<TableCell>{invoice.supplierShortName}</TableCell>
			<TableCell>{invoice.invoiceNumber}</TableCell>
			<TableCell>{invoice.invoiceDate.format(DEFAULT_DATE_FORMAT)}</TableCell>
			<TableCell>{invoice.currency}</TableCell>
			<TableCell>{invoice.fxRateToEmptyString()}</TableCell>
			<TableCell style={{width: 152, textAlign: "right"}}>
				{invoice.grnDocumentAcceptedAt.isDefined &&
				<IconButton size={"small"}>
					<CheckCircleIcon htmlColor={"#7cb342"}/>
				</IconButton>
				}
				{invoice.grnDocumentId.map((grnId) => {
					return (
						<IconButton size={"small"} onClick={() => history.push(`invoices/${invoice.id}/grn_document/${grnId}`)}>
							<IconGrn style={{transform: "scaleX(-1)"}}/>
						</IconButton>
					);
				}).getOrElse(() => {
					return (
						<IconButton size={"small"} onClick={createGrnEndEdit}>
							<IconAddCircle/>
						</IconButton>
					)
				})}
				<IconButton size={"small"} onClick={() => history.push(`invoices/${invoice.id}`)}>
					<EditIcon/>
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default InvoicesTableRow;