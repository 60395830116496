import React from "react";
import {makeStyles, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {defaultStyles} from "../../styles/defaultStyles";
import {PartValueRow} from "./PartsSummary";
import PartsSummaryTableRow from "./PartsSummaryTableRow";

interface IProps {
	rows: PartValueRow[],
}

const useStyles = makeStyles(defaultStyles);

const PartsSummaryTable: React.FC<IProps> = (props: IProps) => {

	const {rows} = props;

	const classes = useStyles();

	return (
		<>
			<Table size={"small"}>
				<TableHead>
					<TableRow>
						<TableCell className={classes.tableHeader}>Category</TableCell>
						<TableCell className={classes.tableHeader}>Type</TableCell>
						<TableCell className={classes.tableHeader}>Manufacturer</TableCell>
						<TableCell className={classes.tableHeader}>Part Number</TableCell>
						<TableCell className={classes.tableHeader}>Description En</TableCell>
						<TableCell className={classes.tableHeader}>Description Pl</TableCell>
						<TableCell className={classes.tableHeader} style={{textAlign: "center"}}>Quantity</TableCell>
						<TableCell className={classes.tableHeader} style={{textAlign: "center"}}>Units</TableCell>
						<TableCell className={classes.tableHeader} style={{textAlign: "center"}}>Value PLN</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => <PartsSummaryTableRow
							key={row.id}
							row={row}/>)}
				</TableBody>
			</Table>
		</>
	);
};

export default PartsSummaryTable;