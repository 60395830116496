import React from 'react';
import {createStyles, Grid, StyledComponentProps, Theme, Typography, withStyles} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import GoogleCode from "../models/GoogleCode";
import {fetchAuthToken, fetchGoogleCode} from "../redux/auth/authActions";
import {AnyAction, Dispatch} from "redux";
import {connect} from "react-redux";
import {OnFailure, OnSuccess} from "../redux/actionHelpers";
import {Option} from "ts-option";
import Settings from "../models/Settings";
import {RootState} from "../redux/root/rootState";
import {GoogleLogin, GoogleOAuthProvider} from "@react-oauth/google";

const background = require("../assets/background.jpg");

const styles = (theme: Theme) => createStyles({
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: "center",
	},
	title: {
		color: "white",
		marginBottom: "20px"
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		alignItems: "center",
		padding: theme.spacing(4),
	},
	page: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: "center",
		background: `url(${background})`,
		width: "100%",
		height: "100%",
		boxSizing: "border-box"
	},
});


interface IDispatchProps {
	fetchGoogleCode: (googleCode: GoogleCode, onSuccess: OnSuccess) => void,
	fetchAuthToken: (googleCode: GoogleCode, onSuccess: OnSuccess, onFailure: OnFailure) => void,
}

interface IReduxProps {
	settings: Option<Settings>
}

type IProps = StyledComponentProps & IDispatchProps & IReduxProps;
const Login: React.FC<IProps> = (props: IProps) => {

	const {fetchGoogleCode, fetchAuthToken} = props;
	const {classes}: any = props;

	const history = useHistory();


	const googleErrorResponse = () => {
		// TODO Handle errors using toasters
		console.error("Could not get code from google")
	};

	const googleSuccessResponse = (response: any) => {
		const googleCode = GoogleCode.fromJson(response);
		fetchGoogleCode(googleCode,
			() => {
				fetchAuthToken(googleCode,
					() => {
						history.push("/");
					},
					() => {
						// TODO Handle error nicely
						console.error("Could not get token from backend");
					})
			});
	};

	const renderButton = () => {
		return (
			props.settings.isDefined &&
			<div>
				<GoogleLogin size={"medium"} onSuccess={googleSuccessResponse} onError={googleErrorResponse}/>
			</div>
		);
	};

	return (
		<GoogleOAuthProvider clientId={props.settings.get.googleClientId}>
			<div className={classes.page}>
				<Grid container
					  direction="row"
					  justify="center"
					  alignItems="center">
					<Grid item xl={4} lg={6} sm={9} xs={12} className={classes.gridItem}>
						<div className={classes.container}>
							<Typography className={classes.title} variant={"h3"}>Yosensi Warehouse</Typography>
							<Grid container className={classes.buttonContainer}>
								{renderButton()}
							</Grid>
						</div>
					</Grid>
				</Grid>
			</div>
		</GoogleOAuthProvider>
	);
};

function mapStateToProps(state: RootState): IReduxProps {
	return {
		settings: state.settings.settings
	}
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): IDispatchProps {
	return {
		fetchGoogleCode: (googleCode: GoogleCode, onSuccess: OnSuccess) => fetchGoogleCode(googleCode, onSuccess)(dispatch),
		fetchAuthToken: (googleCode: GoogleCode, onSuccess: OnSuccess, onFailure: OnFailure) => fetchAuthToken(googleCode,
			onSuccess, onFailure)(dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));