import React from "react";
import {IconButton, TableCell, TableRow} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import IconDelete from "@material-ui/icons/Delete";
import {DEFAULT_DATE_FORMAT} from "../../common/Constants";
import EGinDocument from "../../models/EGinDocument";
import IconGrn from "@material-ui/icons/AssignmentReturn";
import {useHistory} from "react-router-dom";

interface IProps {
	document: EGinDocument;
	onEdit: () => void;
	onDelete: () => void;
}

const EGinDocumentsTableRow: React.FunctionComponent<IProps> = (props: IProps) => {
	const {document, onEdit, onDelete} = props;

	const history = useHistory();

	return (
		<TableRow>
			<TableCell>{document.documentNumber}</TableCell>
			<TableCell>{document.documentDate.format(DEFAULT_DATE_FORMAT)}</TableCell>
			<TableCell>{document.description}</TableCell>
			<TableCell>{document.clientShortName}</TableCell>
			<TableCell style={{width: 184, textAlign: "center"}}>
				<IconButton size={"small"} onClick={() => history.push(`egin_documents/${document.id}/items`)}>
					<IconGrn style={{transform: "scaleX(-1)"}}/>
				</IconButton>
				{
					document.acceptedAt.isEmpty && <>
						<IconButton size={"small"} onClick={onEdit}>
							<EditIcon/>
						</IconButton>
						<IconButton size={"small"} onClick={onDelete}>
							<IconDelete/>
						</IconButton>
					</>
				}
			</TableCell>
		</TableRow>
	);
};

export default EGinDocumentsTableRow;