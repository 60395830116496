import React from "react";
import {Fab} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

interface IProps {
	onClick: () => void,
}

const AddFab: React.FC<IProps> = (props: IProps) => {
	return (
		<Fab size={"small"} color={"primary"}
			 style={{position: "absolute", bottom: 8, right: 8}}
			 onClick={props.onClick}>
			<AddIcon/>
		</Fab>
	);
};

export default AddFab
