import React, {useEffect, useState} from "react";
import {makeStyles, Paper, Typography} from "@material-ui/core";
import {defaultStyles} from "../../../styles/defaultStyles";
import {apiInstance} from "../../../api/Api";
import {useParams} from "react-router-dom";
import TechnologyItem from "../../../models/TechnologyItem";
import TabSpinner from "../../common/TabSpinner";
import {none} from "ts-option";
import TechnologyItemsTable from "./TechnologyItemsTable";
import TechnologyItemModal from "./TechnologyItemsModal";
import Technology from "../../../models/Technology";
import Scrollable from "../../common/Scrollable";
import AddFab from "../../common/AddFab";
import {sortOptionalString} from "../../../common/SortUtils";

const useStyles = makeStyles(defaultStyles);

const TechnologyItems: React.FC<{}> = () => {
	const classes = useStyles();
	const {technologyId} = useParams();

	const [isLoading, setLoading] = useState<boolean>(true);
	const [isOpen, setOpen] = useState(false);
	const [technologyItems, setTechnologyItems] = useState<TechnologyItem[]>([]);
	const [technology, setTechnology] = useState<Technology>();

	const fetchData = async () => {
		const items = await apiInstance.technologiesApi.fetchTechnologyItems(technologyId as string);
		const technology = await apiInstance.technologiesApi.fetchTechnology(technologyId as string);
		setTechnologyItems(items.sort((ti1, ti2) => sortOptionalString(ti1.categoryName, ti2.categoryName)));
		setTechnology(technology);
		setLoading(false);
	};

	const onClose = () => {
		setOpen(false);
		fetchData().then(() => setLoading(false)).catch((err: any) => console.error(err))
	};

	useEffect(() => {
		fetchData()
			.then(() => setLoading(false))
			.catch(() => {
				// TODO Handle error
				setLoading(false)
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onDelete = (technologyItem: TechnologyItem) => {
		apiInstance.technologiesApi.deleteTechnologyItem(technologyItem.id).then(() => {
			setLoading(true);
			fetchData().then(() => setLoading(false)).catch((err: any) => console.error(err))
		})
	};

	const onCreate = () => {
		setOpen(false);
		setLoading(true);
		fetchData().then(() => setLoading(false)).catch((err: any) => console.error(err))
	};

	return (
		<Paper className={classes.tablePaper}>
			{isLoading ?
				<TabSpinner/> :
				<>
					<Scrollable>
					{
						technology &&
						<Typography style={{padding: 16}}
									variant={"body1"}>Technology: <strong>{technology.name}</strong></Typography>
					}
							<TechnologyItemsTable technologyItems={technologyItems} onDelete={onDelete}/>
					</Scrollable>
					<TechnologyItemModal isOpen={isOpen} technologyItem={none} onClose={onClose} onCreate={onCreate}/>
					<AddFab onClick={() => setOpen(true)}/>
				</>
			}
		</Paper>
	);
};

export default TechnologyItems
