import moment from "moment";
import {InvoiceApiDto} from "./_RestModels";
import {none, option, Option} from "ts-option";

export default class Invoice {
	constructor(public readonly id: string,
		public readonly invoiceNumber: string,
		public readonly invoiceDate: moment.Moment,
		public readonly currency: string,
		public readonly fxRate: Option<number>,
		public readonly grnDocumentId: Option<string>,
		public readonly grnDocumentAcceptedAt: Option<moment.Moment>,
		public readonly supplierId: string,
		public readonly supplierExternalId: string,
		public readonly supplierShortName: string,
		public readonly supplierFullName: string,
		public readonly supplierStreet: string,
		public readonly supplierCity: string,
		public readonly supplierZipCode: string,
		public readonly supplierCountry: string
	) {
	}

	public static fromJson(json: InvoiceApiDto): Invoice {
		const invoiceDate = moment(json.invoiceDate);
		const grnDocumentAcceptedAt = json.grnDocumentAcceptedAt ? option(moment(json.grnDocumentAcceptedAt)) : none;
		const {id, invoiceNumber, currency, fxRate, grnDocumentId, supplierId, supplierExternalId, supplierShortName, supplierFullName, supplierStreet, supplierCity, supplierZipCode, supplierCountry} = json;
		return new Invoice(id, invoiceNumber, invoiceDate, currency, option(fxRate), option(grnDocumentId), grnDocumentAcceptedAt,
			supplierId, supplierExternalId, supplierShortName, supplierFullName, supplierStreet, supplierCity, supplierZipCode,
			supplierCountry);
	}

	public fxRateToEmptyString(): string {
		return this.fxRate.map(fx => (fx / 10000).toFixed(4)).getOrElseValue("")
	}
}