import React, {useEffect, useState} from "react";
import Invoice from "../../models/Invoice";
import {Option} from "ts-option";
import {
	Button,
	CircularProgress,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	makeStyles,
	MenuItem,
	Select,
	Typography
} from "@material-ui/core";
import {defaultStyles} from "../../styles/defaultStyles";
import {apiInstance} from "../../api/Api";
import Supplier from "../../models/Supplier";
import {useHistory, useParams} from "react-router";
import {Field, Form, Formik, FormikHelpers, FormikProps} from "formik";
import FormTextField from "../common/FormTextField";
import {KeyboardDatePicker, MaterialUiPickersDate,} from '@material-ui/pickers';
import CurrencySelector from "../common/CurrencySelector";
import * as Yup from "yup";
import {Currency, DEFAULT_DATE_FORMAT} from "../../common/Constants";
import moment from "moment";
import NumberUtils from "../../common/NumberUtils";
import DateUtils from "../../common/DateUtils";

interface IProps {
	invoice: Option<Invoice>
}

interface InvoiceFormValues {
	invoiceNumber: string,
	supplierId: string,
	invoiceDate: Date,
	currency: string,
	fxRate: string
}

const validationSchema = Yup.object().shape({
	invoiceNumber: Yup.string()
		.required('Invoice ID is required'),
	supplierId: Yup.string()
		.test("is-selected", "Supplier is required", (value => value !== "select")),
	invoiceDate: Yup.string()
		.required('English description is required'),
	currency: Yup.string()
		.test("is-selected", "Currency is required", (value => value !== "select")),
	fxRate: Yup.number()
		.when("currency", {
			is: Currency.PLN,
			then: Yup.number(),
			otherwise: Yup.number().required("Fx Rate is required").min(0, "Fx Rate should be greater than 0")
		})

});
const useStyles = makeStyles(defaultStyles);

const InvoicesForm: React.FC<IProps> = (props: IProps) => {

	const [isLoading, setLoading] = useState<boolean>(true);
	const [isSubmitting, setSubmitting] = useState<boolean>(false);
	const [suppliers, setSuppliers] = useState<Supplier[]>([]);
	const [rateError, setRateError] = useState<string>("");
	const [isRateLoading, setRateLoading] = useState<boolean>(false);
	const classes = useStyles();
	const {invoiceId} = useParams();
	const history = useHistory();

	const getMaxDate = () => {
		return moment().startOf("day").toDate();
	};
	const fetchData = async () => {
		setLoading(true);
		const suppliers = await apiInstance.suppliersApi.fetchSuppliers();
		setSuppliers(suppliers);
	};

	useEffect(() => {
		fetchData()
			.then(() => setLoading(false))
			.catch((err: Error) => {
				// TODO Handle error
				setLoading(false);
				console.error(err)
			});
	}, []);

	const initialValues: InvoiceFormValues = props.invoice.map((invoice: Invoice) => {
		const {invoiceNumber, supplierId, invoiceDate, currency} = invoice;
		return ({invoiceNumber, supplierId, invoiceDate: invoiceDate.toDate(), currency, fxRate: invoice.fxRateToEmptyString()})
	}).getOrElseValue({invoiceNumber: "", supplierId: "select", invoiceDate: getMaxDate(), currency: "select", fxRate: ""});

	const getFxRate = (formikProps: FormikProps<InvoiceFormValues>) => () => {
		setRateError("");
		setRateLoading(true);
		const date = moment(formikProps.values.invoiceDate).subtract(1, "d");
		apiInstance.externalApi.getFxRate(formikProps.values.currency, date).then((response) => {
			formikProps.setFieldValue("fxRate", response.data.rate);
			setRateLoading(false);
		}).catch((err) => {
			setRateError(`Could not fetch NBP rate for ${date.format(
				DEFAULT_DATE_FORMAT)}. Try to get Fx Rate manually from http://nbp.pl/.`);
			setRateLoading(false);
			console.error(err)
		})
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: InvoiceFormValues, actions: FormikHelpers<InvoiceFormValues>) => {
				const supplier = suppliers.filter(sup => sup.id === values.supplierId)[0];
				const submitAction = props.invoice.isDefined ?
					apiInstance.invoicesApi.putInvoice((invoiceId as string),
						values.invoiceNumber,
						DateUtils.toUTCDate(values.invoiceDate),
						values.currency,
						NumberUtils.stringToIntWithFactor(values.fxRate, 10000),
						values.supplierId,
						supplier.externalId,
						supplier.fullName,
						supplier.shortName,
						supplier.street,
						supplier.city,
						supplier.zipCode,
						supplier.country) :
					apiInstance.invoicesApi.postInvoice(
						values.invoiceNumber,
						DateUtils.toUTCDate(values.invoiceDate),
						values.currency,
						NumberUtils.stringToIntWithFactor(values.fxRate, 10000),
						values.supplierId,
						supplier.externalId,
						supplier.fullName,
						supplier.shortName,
						supplier.street,
						supplier.city,
						supplier.zipCode,
						supplier.country);
				setSubmitting(true);
				submitAction.then(() => {
					setSubmitting(false);
					actions.resetForm();
					history.push("/invoices");
				})
					.catch((err: any) => {
						//TODO Handle error nicely
						setSubmitting(false);
						if (err.response.status === 409) {
							actions.setFieldError("invoiceNumber", "Invoice ID is already in use");
						}
						else {
							console.error(err)
						}
					})
			}}
			enableReinitialize={true}
			validateOnBlur={true}
			validateOnChange={false}
		>
			{(formikProps: FormikProps<InvoiceFormValues>) => (
				<Form noValidate={true}>
					<Grid container>
						<Grid container item lg={6} md={12} spacing={3}>
							<Grid item xs={12}>
								{props.invoice.isDefined ?
									<Typography variant={"h6"}>Edit Invoice</Typography> :
									<Typography variant={"h6"}>Create New Invoice</Typography>
								}
							</Grid>
							<Grid item xs={12} md={6}>
								<Field
									id="invoiceNumber"
									label="Invoice ID"
									name="invoiceNumber"
									required={true}
									error={formikProps.errors.invoiceNumber && formikProps.touched.invoiceNumber}
									helperText={formikProps.errors.invoiceNumber && formikProps.touched.invoiceNumber ?
										formikProps.errors.invoiceNumber : ""}
									as={FormTextField}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<KeyboardDatePicker
									autoOk={true}
									disableToolbar
									inputVariant="outlined"
									fullWidth={true}
									maxDate={getMaxDate()}
									format={DEFAULT_DATE_FORMAT}
									margin="dense"
									id="date-picker-inline"
									label="Invoice Date"
									value={formikProps.values.invoiceDate}
									onChange={(date: MaterialUiPickersDate) => {formikProps.setFieldValue("invoiceDate", date)}}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
								/>
							</Grid>
							<Grid item xs={3}>
								<CurrencySelector onChange={(evt: React.ChangeEvent<any>) => {
									formikProps.setFieldValue("currency", evt.target.value);
									formikProps.setFieldValue("fxRate", "")
								}} value={formikProps.values.currency}
												  error={Boolean(formikProps.errors.currency && formikProps.touched.currency)}
								/>
							</Grid>
							<Grid item xs={3}>
								<Field
									id="fxRate"
									label="FX Rate"
									name="fxRate"
									required={true}
									type={"number"}
									disabled={formikProps.values.currency === Currency.PLN}
									error={formikProps.errors.fxRate && formikProps.touched.fxRate}
									helperText={formikProps.errors.fxRate && formikProps.touched.fxRate ?
										formikProps.errors.fxRate : ""}
									as={FormTextField}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormControl variant={"outlined"} fullWidth={true} style={{marginTop: 8}}
											 error={Boolean(formikProps.errors.supplierId && formikProps.touched.supplierId)}>
									<InputLabel>Select Supplier</InputLabel>
									<Field name={"supplierId"} autoWidth={true} labelWidth={110} as={Select} margin={"dense"}
										   disabled={isLoading}>
										<MenuItem value={"select"}>Select Supplier</MenuItem>
										{suppliers.sort((a, b) => a.shortName.localeCompare(b.shortName)).map(
											supplier => <MenuItem
												value={supplier.id} key={supplier.id}>{supplier.shortName}</MenuItem>)}
									</Field>
									{
										Boolean(formikProps.errors.supplierId && formikProps.touched.supplierId) &&
										<FormHelperText>{formikProps.errors.supplierId}</FormHelperText>
									}

								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<Button onClick={getFxRate(formikProps)}
										disabled={formikProps.values.currency === Currency.PLN || formikProps.values.currency ===
										"select"}
										variant={"contained"}
										color={"primary"}>
									{isRateLoading ?
										<CircularProgress style={{color: "white"}} size={24}/> :
										"Get FX Rate"}
								</Button>
								<Typography variant={"body2"} className={classes.textMuted}
											style={{fontSize: "90%", marginTop: 16}}>
									Fx Rates are fetched for the day before invoice date.
								</Typography>
								{Boolean(rateError.length) &&
								<Typography variant={"body2"} style={{fontSize: "90%", marginTop: 8}}
											className={classes.textError}>{rateError}</Typography>}
							</Grid>
							<Grid item xs={12}>
								<div className={classes.buttonsRow}>
									<Button onClick={() => history.goBack()}>Back</Button>
									<Button type="submit" color={"primary"} variant={"contained"}>
										{isSubmitting ?
											<CircularProgress style={{color: "white"}} size={24}/> :
											props.invoice.isDefined ? "Update" : "Create"}
									</Button>
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Form>
			)}
		</Formik>
	);
};


export default InvoicesForm