import React from "react";
import {StyledComponentProps, Table, TableBody, TableCell, TableHead, TableRow, withStyles} from "@material-ui/core";
import Supplier from "../../models/Supplier";
import SupplierTableRow from "./SuppliersTableRow";
import {defaultStyles} from "../../styles/defaultStyles";

interface IProps {
    suppliers: Supplier[];
}

type Props = IProps & StyledComponentProps;

const SuppliersTable: React.FunctionComponent<Props> = (props: Props) => {
    const {suppliers} = props;
    const {classes}: any = props;
    return (
        <Table size={"small"}>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.tableHeader}>External ID</TableCell>
                    <TableCell className={classes.tableHeader}>Full Name</TableCell>
                    <TableCell className={classes.tableHeader}>Short Name</TableCell>
                    <TableCell className={classes.tableHeader}>Street</TableCell>
                    <TableCell className={classes.tableHeader}>City</TableCell>
                    <TableCell className={classes.tableHeader}>Zip Code</TableCell>
                    <TableCell className={classes.tableHeader}>Country</TableCell>
                    <TableCell className={classes.tableHeader}>Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {suppliers.map((supplier) => <SupplierTableRow key={supplier.id} supplier={supplier}/>)}
            </TableBody>
        </Table>
    );
};

export default withStyles(defaultStyles)(SuppliersTable);