import React, {useEffect, useState} from "react";
import {Fab, Paper, StyledComponentProps, Typography, withStyles} from "@material-ui/core";
import {defaultStyles} from "../../../styles/defaultStyles";
import TabSpinner from "../../common/TabSpinner";
import {apiInstance} from "../../../api/Api";
import AddIcon from '@material-ui/icons/Add';
import PartCategory from "../../../models/PartCategory";
import PartCategoriesTable from "./PartCategoriesTable";
import SettingsTableHeader from "../../common/SettingsTableHeader";
import PartCategoryModal from "./PartCategoriesModal";
import {none} from "ts-option";
import AddContainer from "../../common/AddContainer";

type IProps = StyledComponentProps

const PartCategories: React.FunctionComponent<IProps> = (props: IProps) => {

    const [isLoading, setLoading] = useState<boolean>(true);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [partCategories, setPartCategories] = useState<PartCategory[]>([]);

    const fetchData = async () => {
        const partCategories = await apiInstance.partsApi.fetchPartCategories();
        setPartCategories(partCategories)
    };

    const onClose = () => {
        setOpen(false);
        fetchData().then()
    };

    useEffect(() => {
        fetchData()
            .then(() => setLoading(false))
            .catch(() => {
                // TODO Handle error
                setLoading(false)
            });
    }, []);

    const {classes}: any = props;
    return (
        <Paper className={classes.settingsTablePaper}>
            {isLoading ?
                <TabSpinner/> :
                <>
                    <SettingsTableHeader>
                        <Typography variant={"h6"}>Part Categories</Typography>
                    </SettingsTableHeader>
                    <PartCategoriesTable partCategories={partCategories} onClose={onClose}/>
                    <AddContainer>
                        <Fab size={"small"} color={"primary"} onClick={() => setOpen(true)}><AddIcon/></Fab>
                    </AddContainer>
                    <PartCategoryModal isOpen={isOpen}
                                       partCategory={none}
                                       onClose={() => onClose()}/>
                </>
            }
        </Paper>
    );
};

export default withStyles(defaultStyles)(PartCategories);