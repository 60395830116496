import {AxiosInstance} from "axios";
import {
	PartApiDto,
	PartCategoryApiDto,
	PartStateApiDto,
	PartTypeApiDto,
	PartUnitApiDto, PartValueApiDto,
	TypeStateApiDto,
} from "../models/_RestModels";
import PartCategory from "../models/PartCategory";
import PartType from "../models/PartType";
import PartUnit from "../models/PartUnit";
import Part from "../models/Part";
import FormsUtils from "../common/FormsUtils";
import PartState from "../models/PartState";
import TypeState from "../models/TypeState";
import QuantityState from "../models/QuantityState";
import PartValue from "../models/PartValue";
import PartValidate from "../models/PartValidate";
import ValidationRequest from "../models/ValidationRequest";
import {Option, option} from "ts-option";

export default class PartsApi {
	constructor(public readonly axiosInstance: AxiosInstance) {
	}

	fetchPartCategories(): Promise<PartCategory[]> {
		const url = '/api/parts/categories';
		return this.axiosInstance.get(url).then(resp => resp.data.map((ptc: PartCategoryApiDto) => PartCategory.fromJson(ptc)))
	}

	postPartCategory(name: string) {
		const url = '/api/parts/categories';
		const config = {
			name
		};
		return this.axiosInstance.post(url, config);
	}

	putPartCategory(id: string, name: string) {
		const url = `/api/parts/categories/${id}`;
		const config = {
			name
		};
		return this.axiosInstance.put(url, config);
	}

	fetchPartTypes(): Promise<PartType[]> {
		const url = '/api/parts/types';
		return this.axiosInstance.get(url).then(resp => resp.data.map((pt: PartTypeApiDto) => PartType.fromJson(pt)))
	}

	postPartType(typeId: string): Promise<PartType> {
		const url = '/api/parts/types';
		const config = {
			typeId
		};
		return this.axiosInstance.post(url, config).then(resp => PartType.fromJson(resp.data))
	}

	putPartType(id: string, typeId: string): Promise<PartType> {
		const url = `/api/parts/types/${id}`;
		const config = {
			typeId
		};
		return this.axiosInstance.put(url, config).then(resp => PartType.fromJson(resp.data))
	}

	fetchPartUnits(): Promise<PartUnit[]> {
		const url = '/api/parts/units';
		return this.axiosInstance.get(url).then(resp => resp.data.map((pu: PartUnitApiDto) => PartUnit.fromJson(pu)))
	}

	postPartUnit(nameEn: string, namePl: string, displayName: string): Promise<PartUnit> {
		const url = '/api/parts/units';
		const config = {
			nameEn, namePl, displayName
		};
		return this.axiosInstance.post(url, config)
	}

	putPartUnit(id: string, nameEn: string, namePl: string, displayName: string): Promise<PartUnit> {
		const url = `/api/parts/units/${id}`;
		const config = {
			nameEn, namePl, displayName
		};
		return this.axiosInstance.put(url, config)
	}

	fetchParts(): Promise<Part[]> {
		const url = "/api/parts";
		return this.axiosInstance.get(url).then(resp => resp.data.map((p: PartApiDto) => Part.fromJson(p)))
	}

	fetchPart(id: string): Promise<Part> {
		const url = `/api/parts/${id}`;
		return this.axiosInstance.get(url).then((resp) => Part.fromJson(resp.data))
	}

	postPart(partNumber: string,
		manufacturer: string,
		descriptionEn: string,
		descriptionPl: string,
		categoryId: string,
		typeId: string,
		unitId: string): Promise<Part> {
		const url = '/api/parts';
		const config = {
			partNumber,
			manufacturer,
			descriptionEn,
			descriptionPl,
			categoryId: FormsUtils.optionalSelect(categoryId),
			typeId: FormsUtils.optionalSelect(typeId),
			unitId
		};
		return this.axiosInstance.post(url, config)
	}

	putPart(id: string,
		partNumber: string,
		manufacturer: string,
		descriptionEn: string,
		descriptionPl: string,
		categoryId: string,
		typeId: string,
		unitId: string): Promise<Part> {
		const url = `/api/parts/${id}`;
		const config = {
			partNumber,
			manufacturer,
			descriptionEn,
			descriptionPl,
			categoryId: FormsUtils.optionalSelect(categoryId),
			typeId: FormsUtils.optionalSelect(typeId),
			unitId
		};
		return this.axiosInstance.put(url, config)
	}

	fetchPartsStates(): Promise<QuantityState> {
		const url = `/api/store/state`;
		return this.axiosInstance.get(url).then(resp => {
			const partState = resp.data.byParts.map((p: PartStateApiDto) => PartState.fromJson(p));
			const typeState = resp.data.byTypes.map((t: TypeStateApiDto) => TypeState.fromJson(t));
			return new QuantityState(partState, typeState)
		})
	}

	fetchPartsValues(): Promise<PartValue[]> {
		const url = `/api/store/value`;
		return this.axiosInstance.get(url).then(resp => resp.data.map((pv: PartValueApiDto) => PartValue.fromJson(pv)))
	}

	validateTechnology(items: PartValidate[]):Promise<Option<ValidationRequest>> {
		const url = `/api/store/state/validate`;
		return this.axiosInstance.post(url, items).then(resp => option(ValidationRequest.fromJson(resp.data)))
	}
}