import React from "react";
import {IconButton, TableCell, TableRow} from "@material-ui/core";
import Supplier from "../../models/Supplier";
import EditIcon from '@material-ui/icons/Edit';
import {useHistory} from "react-router-dom";

interface IProps {
    supplier: Supplier;
}

const SupplierTableRow: React.FunctionComponent<IProps> = (props: IProps) => {
    const history = useHistory();
    const {supplier} = props;
    return (
        <TableRow>
            <TableCell>{supplier.externalId}</TableCell>
            <TableCell>{supplier.fullName}</TableCell>
            <TableCell>{supplier.shortName}</TableCell>
            <TableCell>{supplier.street}</TableCell>
            <TableCell>{supplier.city}</TableCell>
            <TableCell>{supplier.zipCode}</TableCell>
            <TableCell>{supplier.country}</TableCell>
            <TableCell>
                <IconButton size={"small"} onClick={()=> history.push(`suppliers/${supplier.id}`)}>
                    <EditIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default SupplierTableRow;