import {AxiosInstance} from "axios";
import {SettingsApiDto} from "../models/_RestModels";
import Settings from "../models/Settings";

export default class SettingsApi {
    constructor(public readonly axiosInstance: AxiosInstance) {}

    fetchSettings(): Promise<Settings> {
        const url = "/settings";
        return this.axiosInstance.get(url).then(resp => Settings.fromJson(resp.data as SettingsApiDto));
    }
}