import {option, Option} from "ts-option";
import {SupplierApiDto} from "./_RestModels";

export default class Supplier {
    constructor(
        public readonly id: string,
        public readonly externalId: string,
        public readonly vatNumber: Option<string>,
        public readonly fullName: string,
        public readonly shortName: string,
        public readonly street: string,
        public readonly city: string,
        public readonly zipCode: string,
        public readonly country: string,
        public readonly website: Option<string>,
    ) {
    }

    public static fromJson(json: SupplierApiDto): Supplier {
        return new Supplier(
            json.id,
            json.externalId,
            option(json.vatNumber),
            json.fullName,
            json.shortName,
            json.street,
            json.city,
            json.zipCode,
            json.country,
            option(json.website),
        );
    }

    public toInvoiceData(): Partial<Supplier> {
    	return ({
    		id: this.id,
			externalId: this.externalId,
			fullName: this.fullName,
			shortName: this.shortName,
			street: this.street,
			city: this.city,
			zipCode: this.zipCode,
			country: this.country
		});
	}
}
