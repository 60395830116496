import React from "react";
import {
	makeStyles,
	StyledComponentProps,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	withStyles
} from "@material-ui/core";
import {defaultStyles} from "../../styles/defaultStyles";
import IGrnDocument from "../../models/IGrnDocument";
import IGrnDocumentsTableRow from "./IGrnDocumentsTableRow";

const useStyles = makeStyles(defaultStyles);

interface IProps {
	iGrnDocuments: IGrnDocument[];
	onEdit: (document: IGrnDocument) => void;
	onDelete: (document: IGrnDocument) => void;
}

type Props = IProps & StyledComponentProps;

const IGrnDocumentsTable: React.FunctionComponent<Props> = (props: Props) => {
	const classes = useStyles({});

	const onEdit = (document: IGrnDocument) => () => {
		props.onEdit(document);
	};

	const onDelete = (document: IGrnDocument) => () => {
		props.onDelete(document);
	};

	const {iGrnDocuments} = props;
	return (
		<Table size={"small"}>
			<TableHead>
				<TableRow>
					<TableCell className={classes.tableHeader}>Document Number</TableCell>
					<TableCell className={classes.tableHeader}>Document Date</TableCell>
					<TableCell className={classes.tableHeader}>Description</TableCell>
					<TableCell className={classes.tableHeader} style={{width: 152, textAlign:"center"}}>Actions</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{iGrnDocuments.map((doc) => <IGrnDocumentsTableRow key={doc.id} document={doc} onEdit={onEdit(doc)} onDelete={onDelete(doc)}/>)}
			</TableBody>
		</Table>
	);
};

export default withStyles(defaultStyles)(IGrnDocumentsTable);