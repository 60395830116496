import {none, option, Option} from "ts-option";
import {GinDocumentApiDto} from "./_RestModels";
import moment from "moment";

export default class GinDocument {
	constructor(
		public readonly id: string,
		public readonly documentNumber: string,
		public readonly documentDate: moment.Moment,
		public readonly description: string,
		public readonly technologyId: Option<string>,
		public readonly technologyQuantity: Option<number>,
		public readonly igrnDocumentId: Option<string>,
		public readonly internalUse: boolean,
		public readonly acceptedAt: Option<moment.Moment>,
		public readonly acceptedBy: Option<string>,
		public readonly reportId: Option<string>,
	) {}

	public static fromJson(json: GinDocumentApiDto): GinDocument {
		const documentDate = moment(json.documentDate);
		const technologyId = option(json.technologyId);
		const technologyQuantity = option(json.technologyQuantity);
		const igrnDocumentId = option(json.igrnDocumentId);
		const acceptedAt = json.acceptedAt ? option(moment(json.acceptedAt)) : none;
		const acceptedBy = option(json.acceptedBy);
		const reportId = option(json.reportId);
		return new GinDocument(json.id, json.documentNumber, documentDate, json.description, technologyId, technologyQuantity,
			igrnDocumentId, json.internalUse, acceptedAt, acceptedBy, reportId)
	}
}
