import {AxiosInstance} from "axios";
import Client from "../models/Client";
import {ClientApiDto} from "../models/_RestModels";
import {Option} from "ts-option";

export default class ClientApi {
    constructor(public readonly axiosInstance: AxiosInstance) {
    }

    fetchClients(): Promise<Client[]> {
        const url = "/api/clients";
        return this.axiosInstance.get(url).then(resp => resp.data.map((sp: ClientApiDto) => Client.fromJson(sp)))
    }

    postClient(externalId: string,
                 vatNumber: Option<string>,
                 shortName: string,
                 fullName: string,
                 street: string,
                 city: string,
                 zipCode: string,
                 country: string,
                 website: Option<string>,): Promise<void> {
        const url = "/api/clients";
        const config = {
            externalId,
            vatNumber: vatNumber.orNull,
            shortName,
            fullName,
            street,
            city,
            zipCode,
            country,
            website: website.orNull,
        };
        return this.axiosInstance.post(url, config)
    }

    fetchClient(clientId: string): Promise<Client> {
        const url = `/api/clients/${clientId}`;
        return this.axiosInstance.get(url).then(resp => Client.fromJson(resp.data));
    }

    putClient(clientId: string,
                externalId: string,
                vatNumber: Option<string>,
                shortName: string,
                fullName: string,
                street: string,
                city: string,
                zipCode: string,
                country: string,
                website: Option<string>): Promise<void> {
        const url = `/api/clients/${clientId}`;
        const config = {
            externalId,
            vatNumber: vatNumber.orNull,
            shortName,
            fullName,
            street,
            city,
            zipCode,
            country,
            website: website.orNull,
        };
        return this.axiosInstance.put(url, config);
    }
}