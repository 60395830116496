import React from "react";
import {
	makeStyles,
	StyledComponentProps,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	withStyles
} from "@material-ui/core";
import {defaultStyles} from "../../styles/defaultStyles";
import Technology from "../../models/Technology";
import TechnologyTableRow from "./TechnologyTableRow";

const useStyles = makeStyles(defaultStyles);

interface IProps {
	technologies: Technology[];
	onEdit: (technology: Technology) => void;
	onCopy: (technology: Technology) => void;
	onValidate: (technology: Technology) => void;
}

type Props = IProps & StyledComponentProps;

const TechnologiesTable: React.FunctionComponent<Props> = (props: Props) => {
	const classes = useStyles();

	const onEdit = (technology: Technology) => () => {
		props.onEdit(technology);
	};

	const onValidate = (technology: Technology) => () => {
		props.onValidate(technology);
	};

	const onCopy = (technology: Technology) => () => {
		props.onCopy(technology);
	};

	const {technologies} = props;
	return (
		<Table size={"small"}>
			<TableHead>
				<TableRow>
					<TableCell className={classes.tableHeader}>Name</TableCell>
					<TableCell className={classes.tableHeader}>Part Number</TableCell>
					<TableCell className={classes.tableHeader}>Description En</TableCell>
					<TableCell className={classes.tableHeader} style={{width: 152, textAlign: "center"}}>Actions</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{technologies.map((technology) => <TechnologyTableRow key={technology.id} technology={technology}
																	  onEdit={onEdit(technology)} onCopy={onCopy(technology)}
																	  onValidate={onValidate(technology)}/>)}
			</TableBody>
		</Table>
	);
};

export default withStyles(defaultStyles)(TechnologiesTable);