import React from "react";
import {GinPartStateTableRow} from "./GinItems";
import {IconButton, makeStyles, TableCell, TableRow} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import IconDelete from "@material-ui/icons/Delete";
import {defaultStyles} from "../../../styles/defaultStyles";

interface IProps {
	ginItem: GinPartStateTableRow,
	index: number,
	onEdit: () => void,
	onDelete: () => void,
	accepted: boolean,
}

const useStyles = makeStyles(defaultStyles);

const GinItemsTableRow: React.FC<IProps> = (props: IProps) => {

	const classes = useStyles();

	const {ginItem, index, onEdit, onDelete, accepted} = props;

	const getClass = () => ginItem.fromTechnology ? classes.textMuted : "";
	return (
		<TableRow>
			<TableCell className={getClass()}>{index + 1}</TableCell>
			<TableCell className={getClass()}>{ginItem.partNumber}</TableCell>
			<TableCell className={getClass()}>{ginItem.partDescriptionEn}</TableCell>
			<TableCell className={getClass()} style={{textAlign: "center"}}>{ginItem.quantity}</TableCell>
			<TableCell className={getClass()} style={{textAlign: "center"}}>{ginItem.unitName}</TableCell>
			<TableCell className={getClass()} style={{textAlign: "center"}}>{(ginItem.totalValue / 10000).toFixed(
				2)}</TableCell>
			<TableCell className={getClass()} style={{width: 152, textAlign: "center"}}>
				{
					!ginItem.fromTechnology && !accepted &&
					<>
						<IconButton size={"small"} onClick={onEdit}>
							<EditIcon/>
						</IconButton>
						<IconButton size={"small"} onClick={onDelete}>
							<IconDelete/>
						</IconButton>
					</>
				}
			</TableCell>
		</TableRow>
	);
};

export default GinItemsTableRow
