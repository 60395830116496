import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@material-ui/core";
import React from "react";
import {getCurrencies} from "../../common/Constants";

interface IProps {
	onChange: (evt: React.ChangeEvent<any>) => void
	value: string,
	error: boolean
}

const CurrencySelector: React.FC<IProps> = (props: IProps) => {
	const {onChange, value, error} = props;
	return (
		<FormControl variant="outlined" fullWidth={true} error={error} style={{marginTop: 8}}>
			<InputLabel>
				Currency
			</InputLabel>
			<Select
				margin={"dense"}
				value={value}
				onChange={onChange}
				labelWidth={70}
			>
				<MenuItem value="select">Select Currency</MenuItem>
				{getCurrencies().map(currency => <MenuItem value={currency} key={currency}>{currency}</MenuItem>)}
			</Select>
			{error &&
			<FormHelperText>Currency is required</FormHelperText>
			}
		</FormControl>
	);
};

export default CurrencySelector;