import {PartTechnologyItemApiDto} from "./_RestModels";
import {option, Option} from "ts-option";

export default class TechnologyItem {
	constructor(public readonly id: string,
		public readonly partId: string,
		public readonly quantity: number,
		public readonly typeId: Option<string>,
		public readonly categoryName: Option<string>,
		public readonly partNumber: string,
		public readonly manufacturer: string,
		public readonly descriptionEn: string,
		public readonly unitEn: string,
	) {}

	public static fromJson(json: PartTechnologyItemApiDto) {
		const typeId = option(json.typeId);
		const categoryName = option(json.categoryName);
		return new TechnologyItem(json.id, json.partId, json.quantity, typeId, categoryName, json.partNumber, json.manufacturer, json.descriptionEn, json.unitEn)
	}
}