import React, {useEffect, useState} from "react";
import {Button, CircularProgress, Divider, Fab, makeStyles, Paper, Typography} from "@material-ui/core";
import {defaultStyles} from "../../../styles/defaultStyles";
import {useParams} from "react-router";
import TabSpinner from "../../common/TabSpinner";
import {apiInstance} from "../../../api/Api";
import {none, Option, option} from "ts-option";
import IGrnDocument from "../../../models/IGrnDocument";
import Part from "../../../models/Part";
import PartUnit from "../../../models/PartUnit";
import IGrnItemsTable from "./IGrnItemsTable";
import IGrnDocumentItem from "../../../models/IGrnDocumentItem";
import AddMultiContainer from "../../common/AddMultiContainer";
import IGrnItemsModal from "./IGrnItemsModal";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(defaultStyles);

const IGrnItems: React.FC<{}> = () => {

	const {igrnId} = useParams();

	const classes = useStyles();
	const [isOpen, setOpen] = useState<boolean>(false);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [isSubmitting, setSubmitting] = useState<boolean>(false);
	const [igrnDocument, setIGrnDocument] = useState<Option<IGrnDocument>>(none);
	const [igrnItems, setIGrnItems] = useState<IGrnItemRow[]>([]);
	const [igrnItem, setIGrnItem] = useState<Option<IGrnDocumentItem>>(none);
	const [parts, setParts] = useState<Part[]>([]);
	const [units, setUnits] = useState<PartUnit[]>([]);

	const fetchData = () => {
		setLoading(true);
		Promise.all([
			apiInstance.iGrnDocumentApi.fetchIGrnDocument(igrnId as string),
			apiInstance.iGrnDocumentApi.fetchIGrnItems(igrnId as string),
			apiInstance.partsApi.fetchParts(),
			apiInstance.partsApi.fetchPartUnits()
		]).then(data => {
			const [igrnDocument, igrnItems, parts, units] = data;
			const rows: IGrnItemRow[] = igrnItems.map(item => {
				const part = parts.filter(p => p.id === item.partId)[0];
				const unitName = units.filter(u => u.id === part.unitId)[0].displayName;
				const descriptionEn = part.descriptionEn;
				return {...item, descriptionEn, unitName}
			});
			setIGrnDocument(option(igrnDocument));
			setParts(parts);
			setUnits(units);
			setIGrnItems(rows);
			setLoading(false);
		}).catch(err => console.error(err));
	};

	useEffect(() => {
		fetchData()
		// eslint-disable-next-line
	}, []);

	const onClose = () => {
		setOpen(false);
		fetchData();
		setIGrnItem(none)
	};

	const onEdit = (item: IGrnItemRow) => {
		const igrnItem = igrnItems.filter(ig => ig.id === item.id)[0];
		setIGrnItem(option(igrnItem));
		setOpen(true);
	};

	const onDelete = (item: IGrnDocumentItem) => {
		if (window.confirm("Are you sure you want to delete this item?")) {
			apiInstance.iGrnDocumentApi.deleteIGrnItem(item.id).then(() => {
				fetchData()
			});
		}
	};

	const onCreate = () => {
		setOpen(true)
	};

	const onDocumentAccept = () => {
		if (window.confirm("Accepted document can not be edited any more.\nAccept anyway?")) {
			setSubmitting(true);
			apiInstance.iGrnDocumentApi.acceptIGrnDocument(igrnId as string).then(() => {
				setSubmitting(false);
				fetchData();
			}).catch((err: any) => {
				setSubmitting(false);
				console.error(err)
			})
		}
	};

	return (
		<Paper className={classes.tablePaper}>
			{isLoading ?
				<TabSpinner/> :
				<>
					{
						igrnDocument.map(doc => {
							return <Typography style={{margin: 16}} variant={"body1"}>IGRN Document
								number: <strong>{doc.documentNumber}</strong></Typography>
						}).orNull
					}
					<Divider/>
					<IGrnItemsTable iGrnItems={igrnItems} igrnDocument={igrnDocument} onDelete={onDelete} onEdit={onEdit}/>
					<IGrnItemsModal iGrnDocument={igrnDocument} iGrnItem={igrnItem} onClose={onClose} isOpen={isOpen}
									parts={parts} units={units}/>
					<AddMultiContainer>
						{igrnDocument.map(doc => {
							return doc.acceptedAt.isEmpty && (
								<>
									<Button variant={"contained"} color={"primary"}
											onClick={onDocumentAccept}
											disabled={igrnItems.length === 0}>
										{isSubmitting ?
											<CircularProgress style={{color: "white"}} size={24}/>
											: "Accept Document"}
									</Button>
									< Fab size={"small"} color={"primary"}
										  onClick={onCreate}><AddIcon/>
									</Fab>
								</>
							)

						}).getOrElseValue(false)
						}
					</AddMultiContainer>
				</>
			}
		</Paper>
	);
};

export default IGrnItems

export interface IGrnItemRow extends IGrnDocumentItem {
	descriptionEn: string,
	unitName: string,
}