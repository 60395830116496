import React from "react";
import {
	makeStyles,
	StyledComponentProps,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	withStyles
} from "@material-ui/core";
import {defaultStyles} from "../../../styles/defaultStyles";
import GrnItemsTableRow from "./GrnItemsTableRow";
import GrnDocument from "../../../models/GrnDocument";
import GrnDocumentItem from "../../../models/GrnDocumentItem";
import {GrnItemRow} from "../GrnEdit";

const useStyles = makeStyles(defaultStyles);

interface IProps {
	grn: GrnDocument;
	items: GrnItemRow[];
	onDelete: (grnItem: GrnDocumentItem) => void,
	onEdit: (grnItem: GrnDocumentItem) => void,
}

type Props = IProps & StyledComponentProps;

const GrnItemsTable: React.FunctionComponent<Props> = (props: Props) => {

	const classes = useStyles();
	const {grn, items} = props;
	const {invoiceCurrency} = props.grn;

	const onDelete = (grnItem: GrnDocumentItem) => () => {
		props.onDelete(grnItem);
	};

	const onEdit = (grnItem: GrnDocumentItem) => () => {
		props.onEdit(grnItem);
	};

	return (
		<Table size={"small"}>
			<TableHead>
				<TableRow>
					<TableCell className={classes.tableHeader} style={{width: 30}}>No.</TableCell>
					<TableCell className={classes.tableHeader}>Part Number</TableCell>
					<TableCell className={classes.tableHeader}>Description En</TableCell>
					<TableCell className={classes.tableHeader} style={{textAlign: "center"}}>Quantity</TableCell>
					<TableCell className={classes.tableHeader} style={{textAlign: "center"}}>Unit</TableCell>
					<TableCell className={classes.tableHeader} style={{textAlign: "center"}}>Total Value
						({invoiceCurrency})</TableCell>
					{grn.acceptedAt.isEmpty &&
					<TableCell className={classes.tableHeader} style={{width: 80, textAlign: "center"}}>Actions</TableCell>
					}
				</TableRow>
			</TableHead>
			<TableBody>
				{items.map((gi, index) => {
					return (
						<GrnItemsTableRow item={gi} onDelete={onDelete(gi)} onEdit={onEdit(gi)} index={index}
										  key={gi.id} isAccepted={grn.acceptedAt.isDefined}/>
					);
				})}
			</TableBody>
		</Table>
	);
};

export default withStyles(defaultStyles)(GrnItemsTable);