import {Option} from "ts-option";

export const sortOptionalString = (first: Option<string>, second: Option<string>): number => {
	const s1 = first.orNull;
	const s2 = second.orNull;
	if (!s1 && !s2) {
		return 0
	}
	else if (!s1 && s2) {
		return 1
	}
	else if (s1 && !s2) {
		return -1
	}
	else if (s1 && s2) {
		return s1.localeCompare(s2);
	}
	else {
		return 0;
	}
}