import React from "react";
import {Chip, IconButton, TableCell, TableRow} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import IconGrn from "@material-ui/icons/AssignmentReturn";
import {useHistory} from "react-router-dom";
import IconDelete from "@material-ui/icons/Delete";
import {GinDocumentTableRow} from "./GinDocuments";
import {DEFAULT_DATE_FORMAT} from "../../common/Constants";

interface IProps {
	document: GinDocumentTableRow;
	onEdit: () => void;
	onDelete: () => void;
	onDocCreate: () => void;
}

const GinDocumentsTableRow: React.FunctionComponent<IProps> = (props: IProps) => {
	const {document, onEdit, onDelete, onDocCreate} = props;

	const history = useHistory();

	const renderIGrnButtons = () => {
		if(document.acceptedAt.isDefined && !document.internalUse) {
			if (document.igrnDocumentId.isDefined) {
				return <Chip size={"small"} variant={"outlined"} label={"IGRN"}
					  onClick={() => history.push(`igrn_documents/${document.igrnDocumentId.get}/items`)}/>
			} else {
				return <Chip size={"small"} variant={"outlined"} label={"+IGRN"} onClick={onDocCreate}/>
			}
		}
	};

	return (
		<TableRow>
			<TableCell>{document.documentNumber}</TableCell>
			<TableCell>{document.documentDate.format(DEFAULT_DATE_FORMAT)}</TableCell>
			<TableCell>{document.description}</TableCell>
			<TableCell>{document.technologyName}</TableCell>
			<TableCell style={{textAlign: "center"}}>{document.technologyQuantity.map(value => value.toString()).getOrElseValue(
				"-")}</TableCell>
			<TableCell style={{width: 184, textAlign: "center"}}>
				{renderIGrnButtons()}
				<IconButton size={"small"} onClick={() => history.push(`gin_documents/${document.id}/items`)}>
					<IconGrn style={{transform: "scaleX(-1)"}}/>
				</IconButton>
				{
					document.acceptedAt.isEmpty && <>
						<IconButton size={"small"} onClick={onEdit}>
							<EditIcon/>
						</IconButton>
						<IconButton size={"small"} onClick={onDelete}>
							<IconDelete/>
						</IconButton>
					</>
				}
			</TableCell>
		</TableRow>
	);
};

export default GinDocumentsTableRow;