import React from "react";
import {IconButton, TableCell, TableRow} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import PartType from "../../../models/PartType";

interface IProps {
    partType: PartType;
    onEdit: () => void;
}

const PartCategoriesTableRow: React.FunctionComponent<IProps> = (props: IProps) => {
    const {partType, onEdit} = props;
    return (
        <TableRow>
            <TableCell>{partType.typeId}</TableCell>
            <TableCell style={{textAlign: "center"}}>
                <IconButton size={"small"} onClick={onEdit}>
                    <EditIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default PartCategoriesTableRow;