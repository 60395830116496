import React, {useEffect, useState} from "react";
import {none, option, Option} from "ts-option";
import {makeStyles, Paper} from "@material-ui/core";
import {defaultStyles} from "../../styles/defaultStyles";
import InvoicesForm from "./InvoicesForm";
import {useParams} from "react-router";
import {apiInstance} from "../../api/Api";
import Invoice from "../../models/Invoice";
import TabSpinner from "../common/TabSpinner";

const useStyles = makeStyles(defaultStyles);

const InvoicesEdit = () => {
	const classes = useStyles();

	const {invoiceId} = useParams();
	const [isLoading, setLoading] = useState<boolean>(true);
	const [invoice, setInvoice] = useState<Option<Invoice>>(none);

	useEffect(() => {
		const fetchData = async () => {
			const invoice = await apiInstance.invoicesApi.fetchInvoice(invoiceId as string);
			setInvoice(option(invoice))
		};
		fetchData()
			.then(() => setLoading(false))
			.catch((err: Error) => {
				// TODO Handle error
				console.error(err)
			});
	}, [invoiceId]);

	return (
		<Paper className={classes.defaultPaper}>
			{
				isLoading ?
					<TabSpinner/> :
					<InvoicesForm invoice={invoice}/>
			}
		</Paper>
	)
};

export default InvoicesEdit;