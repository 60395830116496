import React from "react";
import ValidateRequestItem from "../../models/ValidateRequestItem";
import PartType from "../../models/PartType";
import Part from "../../models/Part";
import {Grid, makeStyles, TableCell, TableRow} from "@material-ui/core";
import {defaultStyles} from "../../styles/defaultStyles";

interface IProps {
	req: ValidateRequestItem,
	parts: Part[],
	types: PartType[],
}

const useStyles = makeStyles(defaultStyles);

const TechnologyValidationTableRow: React.FC<IProps> = (props: IProps) => {
	const {req, parts, types} = props;
	const type = req.requestedTypeId.isDefined ? types.filter(type => type.id === req.requestedTypeId.get)[0] : null;
	const part = parts.filter(part => part.id === req.requestedPartId)[0];

	const classes = useStyles();

	const rowClass = req.availableQuantity >= req.requestedQuantity ? "" : classes.textError;

	return (
		<>
			<TableRow>
				<TableCell className={rowClass}>{part ? part.partNumber : req.requestedPartId}</TableCell>
				<TableCell className={rowClass}>{type ? type.typeId : "-"}</TableCell>
				<TableCell className={rowClass} style={{textAlign: "center"}}>{req.requestedQuantity}</TableCell>
				<TableCell className={rowClass} style={{textAlign: "center"}}>{req.availableQuantity}</TableCell>
			</TableRow>
			{type &&
			<TableRow>
				<TableCell colSpan={ 4 }>
					<ul>
						{req.available.map((av) => {
							const part = parts.filter(part => part.id === av.partId)[0];
							return (
								<li key={av.partId}>
									<Grid container>
										<Grid item style={{flexGrow: 1}}>
											<strong>{part ? part.partNumber : av.partId}</strong>
										</Grid>
										<Grid item style={{textAlign: "center", width: 160}}>
											<em>{av.quantity}</em>
										</Grid>
									</Grid>
								</li>
							);
						})
						}
					</ul>
				</TableCell>
			</TableRow>
			}
		</>
	);
};

export default TechnologyValidationTableRow
