import moment from "moment";
import {none, option, Option} from "ts-option";
import {EGinDocumentApiDto} from "./_RestModels";

export default class EGinDocument {
	constructor(
		public readonly id: string,
		public readonly description: string,
		public readonly documentNumber: string,
		public readonly documentDate: moment.Moment,
		public readonly clientId: string,
		public readonly clientExternalId: string,
		public readonly clientFullName: string,
		public readonly clientShortName: string,
		public readonly clientStreet: string,
		public readonly clientCity: string,
		public readonly clientZipCode: string,
		public readonly clientCountry: string,
		public readonly acceptedAt: Option<moment.Moment>,
		public readonly acceptedBy: Option<string>,
		public readonly reportId: Option<string>,
		public readonly createdAt: moment.Moment,
		public readonly createdBy: string,
	) {}

	public static fromJson(json: EGinDocumentApiDto): EGinDocument {
		const acceptedAt = json.acceptedAt ? option(moment(json.acceptedAt)) : none;
		const acceptedBy = option(json.acceptedBy);
		const reportId = option(json.reportId);
		const {id, description, documentNumber, documentDate, clientId, clientExternalId, clientFullName, clientShortName, clientStreet, clientCity, clientZipCode, clientCountry, createdAt, createdBy} = json;
		return new EGinDocument(id, description, documentNumber, moment(documentDate), clientId, clientExternalId, clientFullName,
			clientShortName, clientStreet, clientCity, clientZipCode, clientCountry, acceptedAt, acceptedBy, reportId, moment(createdAt),
			createdBy)
	}
}