import React from "react";
import {TableCell, TableRow} from "@material-ui/core";
import {PartValueRow} from "./PartsSummary";
import Decimal from "decimal.js";

interface IProps {
	row: PartValueRow;
}

const PartsSummaryTableRow: React.FunctionComponent<IProps> = (props: IProps) => {
	const {row} = props;
	return (
		<TableRow>
			<TableCell>{row.categoryName}</TableCell>
			<TableCell>{row.typeName}</TableCell>
			<TableCell>{row.manufacturer}</TableCell>
			<TableCell>{row.partNumber}</TableCell>
			<TableCell>{row.descriptionEn}</TableCell>
			<TableCell>{row.descriptionPl}</TableCell>
			<TableCell style={{textAlign: "center"}}>{row.quantity}</TableCell>
			<TableCell style={{textAlign: "center"}}>{row.unitName}</TableCell>
			<TableCell style={{textAlign: "center"}}>{new Decimal(row.value).dividedBy(10000).toFixed(2)}</TableCell>
		</TableRow>
	);
};

export default PartsSummaryTableRow;