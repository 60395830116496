import React, {useEffect, useState} from "react";
import {Option} from "ts-option";
import {
	Button, Checkbox, CircularProgress,
	FormControl, FormControlLabel,
	FormHelperText,
	Grid, IconButton, InputAdornment,
	InputLabel,
	makeStyles,
	MenuItem,
	Modal,
	Select, TextField,
	Typography
} from "@material-ui/core";
import {defaultStyles} from "../../../styles/defaultStyles";
import GrnDocumentItem from "../../../models/GrnDocumentItem";
import Part from "../../../models/Part";
import {apiInstance} from "../../../api/Api";
import TabSpinner from "../../common/TabSpinner";
import {Formik, Form, FormikHelpers, Field, FormikProps} from "formik";
import * as Yup from "yup";
import {useParams} from "react-router";
import FormTextField from "../../common/FormTextField";
import Decimal from "decimal.js";
import PartUnit from "../../../models/PartUnit";
import CloseIcon from "@material-ui/icons/Close";

interface IProps {
	isOpen: boolean,
	grnItem: Option<GrnDocumentItem>,
	onClose: () => void,
}

const validationSchema = Yup.object().shape({
	quantity: Yup.number()
		.required('Quantity is required')
		.positive('Quantity have to be positive number'),
	totalValue: Yup.number()
		.required('Total value is required'),
	partId: Yup.string().test("is-selected", "Part is required", (value => value !== "select")),
});

interface GrnItemFormValues {
	partId: string,
	typeId: string,
	quantity: string,
	totalValue: string,
}

const useStyles = makeStyles(defaultStyles);

const GrnItemsModal: React.FC<IProps> = (props: IProps) => {

	const [isLoading, setLoading] = useState<boolean>(true);
	const [isSubmitting, setSubmitting] = useState<boolean>(false);
	const [isAnotherOne, setAnotherOne] = useState<boolean>(false);
	const [filterString, setFilterString] = useState<string>("");

	const [parts, setParts] = useState<Part[]>([]);
	const [units, setUnits] = useState<PartUnit[]>([]);

	const {isOpen, grnItem, onClose} = props;
	const classes = useStyles();
	const {invoiceId} = useParams();

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const parts = await apiInstance.partsApi.fetchParts();
			const units = await apiInstance.partsApi.fetchPartUnits();
			setParts(parts);
			setUnits(units);
		};
		fetchData()
			.then(() => setLoading(false))
			.catch((err: Error) => {
				// TODO Handle error
				setLoading(false);
				console.error(err)
			});
	}, []);

	const initialValues: GrnItemFormValues = props.grnItem.map(grnItem => {
		const {partId, typeId, quantity, totalValue} = grnItem;
		return ({partId, typeId, quantity: quantity.toString(), totalValue: (totalValue / 100).toString()})
	}).getOrElseValue({
		partId: "select",
		typeId: "select",
		quantity: "",
		totalValue: ""
	});

	const filteredParts = parts.filter(part => {
		return filterString.length ? part.partNumber.toLowerCase().includes(filterString.toLowerCase()) : true
	}).sort((a, b) => a.partNumber.localeCompare(b.partNumber));

	return (
		<Modal
			open={isOpen}
			onClose={() => onClose()}
			style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
		>
			<div className={classes.extraWideModal}>
				{
					isLoading ?
						<TabSpinner/> :
						<>
							<Formik
								initialValues={initialValues}
								enableReinitialize={true}
								validateOnBlur={true}
								validateOnChange={false}
								validationSchema={validationSchema}
								onSubmit={(values: GrnItemFormValues, actions: FormikHelpers<GrnItemFormValues>) => {
									setSubmitting(true);
									const part = parts.filter((part) => part.id === values.partId)[0];
									const unit = units.filter(u => u.id === part.unitId)[0];
									const totalValue = new Decimal(values.totalValue).mul(100).toNumber();
									const quantity = new Decimal(values.quantity).toDecimalPlaces(2).toNumber();
									const submitAction = grnItem.isDefined ?
										apiInstance.invoicesApi.putInvoiceGrnItem(
											grnItem.get.id,
											invoiceId as string,
											values.partId,
											part.typeId,
											part.partNumber,
											part.descriptionPl,
											unit.namePl,
											quantity,
											totalValue
										) :
										apiInstance.invoicesApi.postInvoiceGrnItem(
											invoiceId as string,
											values.partId,
											part.typeId,
											part.partNumber,
											part.descriptionPl,
											unit.namePl,
											quantity,
											totalValue
										);
									submitAction
										.then(() => {
											setSubmitting(false);
											actions.resetForm();
											if (!isAnotherOne)
												onClose();
										})
										.catch((err: any) => {
											//TODO Handle error nicely
											setSubmitting(false);
											console.error(err)
										})
								}}
							>
								{(formikProps: FormikProps<GrnItemFormValues>) => (
									<Form noValidate={true}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<Typography variant={"h6"}>{
													grnItem.isDefined ?
														"Update Grn Document Item" :
														"Create Grn Document Item"
												}</Typography>
											</Grid>
											<Grid item xs={3}>
												<TextField
													variant={"outlined"}
													fullWidth={true}
													label={"Parts Filter"}
													margin={"dense"}
													id="partsFilter"
													value={filterString}
													onChange={(evt) => {
														formikProps.setFieldValue("partId", "select");
														setFilterString(evt.target.value)
													}}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton
																	size={"small"}
																	onClick={() => setFilterString("")}
																	disabled={!filterString.length}
																>
																	<CloseIcon/>
																</IconButton>
															</InputAdornment>
														)
													}}
												/>
											</Grid>
											<Grid item xs={3}>
												<FormControl variant={"outlined"} fullWidth={true} style={{marginTop: 8}}
															 error={Boolean(
																 formikProps.errors.partId && formikProps.touched.partId)}>
													<InputLabel>Select Part</InputLabel>
													<Field name={"partId"} autoWidth={true} labelWidth={85} as={Select}
														   margin={"dense"} required={true}>
														<MenuItem value={"select"}>Select Part</MenuItem>
														{filteredParts.map(
															part => <MenuItem
																value={part.id} key={part.id}>{part.partNumber}</MenuItem>)}
													</Field>
													{formikProps.errors.partId && formikProps.touched.partId &&
													<FormHelperText>{formikProps.errors.partId}</FormHelperText>
													}
												</FormControl>
											</Grid>
											<Grid item xs={3}>
												<Field
													id="quantity"
													label="Quantity"
													name="quantity"
													required={true}
													type={"number"}
													error={formikProps.errors.quantity && formikProps.touched.quantity}
													helperText={formikProps.errors.quantity && formikProps.touched.quantity ?
														formikProps.errors.quantity : ""}
													as={FormTextField}
												/>
											</Grid>
											<Grid item xs={3}>
												<Field
													id="totalValue"
													label="Total Value"
													name="totalValue"
													required={true}
													type={"number"}
													error={formikProps.errors.totalValue && formikProps.touched.totalValue}
													helperText={formikProps.errors.totalValue && formikProps.touched.totalValue ?
														formikProps.errors.totalValue : ""}
													as={FormTextField}
												/>
											</Grid>
											<Grid item xs={12}>
												<div className={classes.buttonsRow}>
													<Button onClick={onClose}>Close</Button>
													{grnItem.isEmpty &&
													<FormControlLabel
														control={
															<Checkbox
																checked={isAnotherOne}
																onChange={() => setAnotherOne(!isAnotherOne)}
																value={isAnotherOne}
																color="primary"
															/>
														}
														label="Create another one"
													/>
													}
													<Button type="submit" color={"primary"} variant={"contained"}>
														{isSubmitting ?
															<CircularProgress style={{color: "white"}} size={24}/> :
															props.grnItem.isDefined ? "Update" : "Create"}
													</Button>
												</div>
											</Grid>
										</Grid>
									</Form>
								)}
							</Formik>
						</>
				}
			</div>
		</Modal>
	);
};

export default GrnItemsModal