import React from "react";
import {makeStyles, StyledComponentProps, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {defaultStyles} from "../../../styles/defaultStyles";
import IGrnDocument from "../../../models/IGrnDocument";
import {Option} from "ts-option";
import IGrnItemsTableRow from "./IGrnItemsTableRow";
import {IGrnItemRow} from "./IGrnItems";

const useStyles = makeStyles(defaultStyles);

interface IProps {
	iGrnItems: IGrnItemRow[],
	onEdit: (item: IGrnItemRow) => void,
	onDelete: (item: IGrnItemRow) => void,
	igrnDocument: Option<IGrnDocument>,
}

type Props = IProps & StyledComponentProps;

const IGrnItemsTable: React.FC<Props> = (props: Props) => {
	const classes = useStyles();

	const {iGrnItems, igrnDocument} = props;

	const isAccepted = igrnDocument.map(doc => doc.acceptedAt.isDefined).getOrElseValue(true);

	const onEdit = (ginItem: IGrnItemRow) => () => {
		props.onEdit(ginItem);
	};

	const onDelete = (ginItem: IGrnItemRow) => () => {
		props.onDelete(ginItem);
	};

	return (
		<Table size={"small"}>
			<TableHead>
				<TableRow>
					<TableCell className={classes.tableHeader}>No.</TableCell>
					<TableCell className={classes.tableHeader}>Part Number</TableCell>
					<TableCell className={classes.tableHeader}>Description En</TableCell>
					<TableCell className={classes.tableHeader} style={{textAlign: "center"}}>Quantity</TableCell>
					<TableCell className={classes.tableHeader} style={{textAlign: "center"}}>Unit</TableCell>
					<TableCell className={classes.tableHeader} style={{textAlign: "center"}}>Total Value PLN</TableCell>
					<TableCell className={classes.tableHeader} style={{width: 152, textAlign: "center"}}>Actions</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{iGrnItems.map((igrnItem, index) => (
					<IGrnItemsTableRow key={igrnItem.id} index={index}
									   igrnItem={igrnItem} onEdit={onEdit(igrnItem)}
									   onDelete={onDelete(igrnItem)} accepted={isAccepted}/>
									   ))
				}
			</TableBody>
		</Table>
	);
};

export default IGrnItemsTable
