import * as React from "react";
import {Redirect, Route} from "react-router";
import store from "./../../redux/strore"

export const PrivateRoute: React.SFC<any> = ({component: Component, ...rest}) => (
    <Route {...rest} render={(props) => (
        store.getState().auth.authToken.isDefined
            ? <Component {...props} />
            : <Redirect to='/auth'/>
    )}/>
);
