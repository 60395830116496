import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Supplier from "../../models/Supplier";
import {apiInstance} from "../../api/Api";
import {none, option, Option} from "ts-option";
import SuppliersForm from "./SuppliersForm";
import TabSpinner from "../common/TabSpinner";
import {makeStyles, Paper} from "@material-ui/core";
import {defaultStyles} from "../../styles/defaultStyles";

const useStyles = makeStyles(defaultStyles);

const SuppliersEdit = () => {
    const classes = useStyles();
    const {supplierId} = useParams();
    const [isLoading, setLoading] = useState<boolean>(true);
    const [supplier, setSupplier] = useState<Option<Supplier>>(none);

    useEffect(() => {
        const fetchData = async () => {
            const supplier = await apiInstance.suppliersApi.fetchSupplier(supplierId as string);
            setSupplier(option(supplier))
        };
        fetchData()
            .then(() => setLoading(false))
            .catch((err) => {
                // TODO Handle error
            });
    }, [supplierId]);

    return (
        <Paper className={classes.defaultPaper}>
            {
                isLoading ?
                    <TabSpinner/> :
                    <SuppliersForm supplier={supplier}/>
            }
        </Paper>
    )
};

export default SuppliersEdit;