import React from "react";
import {IconButton, TableCell, TableRow} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import TechnologyItem from "../../../models/TechnologyItem";

interface IProps {
	technologyItem: TechnologyItem;
	onDelete: () => void;
}

const TechnologyItemsTableRow: React.FC<IProps> = (props: IProps) => {
	const {technologyItem, onDelete} = props;
	return (
		<TableRow>
			<TableCell>{technologyItem.categoryName.getOrElseValue("-")}</TableCell>
			<TableCell>{technologyItem.partNumber}</TableCell>
			<TableCell>{technologyItem.manufacturer}</TableCell>
			<TableCell>{technologyItem.descriptionEn}</TableCell>
			<TableCell>{technologyItem.quantity}</TableCell>
			<TableCell>{technologyItem.unitEn}</TableCell>
			<TableCell style={{width: 152, textAlign: "center"}}>
				<IconButton size={"small"} onClick={onDelete}>
					<DeleteIcon/>
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default TechnologyItemsTableRow
