import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Client from "../../models/Client";
import {apiInstance} from "../../api/Api";
import {none, option, Option} from "ts-option";
import ClientsForm from "../clients/ClientsForm";
import TabSpinner from "../common/TabSpinner";
import {makeStyles, Paper} from "@material-ui/core";
import {defaultStyles} from "../../styles/defaultStyles";

const useStyles = makeStyles(defaultStyles);

const ClientsEdit = () => {
    const classes = useStyles();
    const {clientId} = useParams();
    const [isLoading, setLoading] = useState<boolean>(true);
    const [client, setClient] = useState<Option<Client>>(none);

    useEffect(() => {
        const fetchData = async () => {
            const client = await apiInstance.clientApi.fetchClient(clientId as string);
            setClient(option(client))
        };
        fetchData()
            .then(() => setLoading(false))
            .catch((err) => {
                // TODO Handle error
            });
    }, [clientId]);

    return (
        <Paper className={classes.defaultPaper}>
            {
                isLoading ?
                    <TabSpinner/> :
                    <ClientsForm client={client}/>
            }
        </Paper>
    )
};

export default ClientsEdit;