import React, {useEffect, useState} from "react";
import {makeStyles, Paper} from "@material-ui/core";
import {defaultStyles} from "../../styles/defaultStyles";
import {apiInstance} from "../../api/Api";
import Part from "../../models/Part";
import TabSpinner from "../common/TabSpinner";
import PartsTable from "./PartsTable";
import {useHistory} from "react-router";
import PartType from "../../models/PartType";
import PartUnit from "../../models/PartUnit";
import PartCategory from "../../models/PartCategory";
import PartState from "../../models/PartState";
import Scrollable from "../common/Scrollable";
import AddFab from "../common/AddFab";

const useStyles = makeStyles(defaultStyles);

const Parts: React.FC<{}> = () => {
	const history = useHistory();

	const [isLoading, setLoading] = useState<boolean>(true);
	const [types, setTypes] = useState<PartType[]>([]);
	const [units, setUnits] = useState<PartUnit[]>([]);
	const [categories, setCategories] = useState<PartCategory[]>([]);
	const [parts, setParts] = useState<Part[]>([]);
	const [partStates, setPartStates] = useState<PartState[]>([]);

	const fetchData = async () => {
		const parts = await apiInstance.partsApi.fetchParts();
		const categories = await apiInstance.partsApi.fetchPartCategories();
		const units = await apiInstance.partsApi.fetchPartUnits();
		const types = await apiInstance.partsApi.fetchPartTypes();
		const quantityState = await apiInstance.partsApi.fetchPartsStates();
		setTypes(types);
		setCategories(categories);
		setUnits(units);
		setParts(parts);
		setPartStates(quantityState.partState);
	};

	useEffect(() => {
		fetchData()
			.then(() => setLoading(false))
			.catch((err) => {
				console.error(err)
				// TODO Handle error
				setLoading(false)
			});
	}, []);

	const classes = useStyles();

	return (
		<Paper className={classes.tablePaper}>
			{isLoading ?
				<TabSpinner/> :
				<>
					<Scrollable>
						<PartsTable parts={parts} types={types} categories={categories} units={units} states={partStates}/>
					</Scrollable>
					<AddFab onClick={() => history.push("/parts/create")}/>
				</>
			}
		</Paper>
	);
};

export default Parts;