import * as React from 'react';
import {
    AppBar,
    Avatar,
    createStyles,
    Hidden,
    makeStyles,
    Menu,
    MenuItem,
    Theme,
    Typography,
    withStyles
} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import {deepPurple} from "@material-ui/core/colors";
import {RootState} from "../../redux/root/rootState";
import {Option} from "ts-option";
import AuthToken from "../../models/AuthToken";
import {connect} from "react-redux";
import {AnyAction, Dispatch} from "redux";
import {OnFailure, OnSuccess} from "../../redux/actionHelpers";
import {logOut} from "../../redux/auth/authActions";
import { useHistory, useLocation } from "react-router-dom";
import {useState} from "react";
import {capitalizeSnakeCase} from "../../common/TextUtils";

const drawerWidth = 240;
const styles = (theme: Theme) => createStyles({
    appBar: {
        marginLeft: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    toolBar: {
        [theme.breakpoints.down('sm')]: {
            paddingRight: "0"
        },
    },
    menuButton: {
        marginRight: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    grow: {
        flexGrow: 1,
    },
    avatar: {
        padding: 5,
        color: '#fff',
        backgroundColor: deepPurple[500],
    },
});

interface CProps {
    handleDrawerToggle: () => void
}


interface IReduxProps {
    auth: Option<AuthToken>
}

interface IDispatchProps {
    logOut: (onSuccess: OnSuccess, onFailure: OnFailure) => void
}

const useStyles = makeStyles(styles);

type IProps = CProps & IReduxProps & IDispatchProps;

const NavigationBar: React.FC<IProps> = (props: IProps) => {

    const [menuAnchor, setAnchor] = useState(null);

    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const handleMenu = (event: any) => {
        setAnchor(event.currentTarget);
    };

    const handleClose = () => {
        setAnchor(null);
    };

    const handleLogout = () => {
        props.logOut(() => {history.push("/auth")}, () => {});
    };

    const renderBreadcrumbs = () => {
		const v4 = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
		const urlParts = location.pathname.substr(1).split("/");
		return "/ " + urlParts.map((part) => {
			if(part.match(v4)) {
				return "ID"
			} else {
				return capitalizeSnakeCase(part);
			}
		}).join(" / ");
	};

        return (
            <AppBar position={"fixed"} className={classes.appBar}>
                <Toolbar className={classes.toolBar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={props.handleDrawerToggle}
                        className={classes.menuButton}>
                        <MenuIcon/>
                    </IconButton>
					<Typography variant="h6" color="inherit" noWrap className={classes.grow}>
						{renderBreadcrumbs()}
					</Typography>
                    {props.auth.map(authUser => {
                        return (
                            <>
                                <Hidden smDown={true}>
                                    <Typography variant="subtitle2" color="inherit" noWrap>{authUser.userFullName}
                                    </Typography>
                                </Hidden>
                                < IconButton
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit">
                                    <Avatar className={classes.avatar}>
                                        {authUser.userFullName && authUser.userFullName.split(" ").map(el => el.charAt(0)).join("")}
                                    </Avatar>
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={menuAnchor}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(menuAnchor)}
                                    onClose={handleClose}>
                                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                </Menu>
                            </>
                        );
                    }).orNull}
                </Toolbar>
            </AppBar>
        );
};

function mapStateToProps(state: RootState): IReduxProps {
    return {
        auth: state.auth.authToken
    }
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): IDispatchProps {
    return {
        logOut: (onSuccess: OnSuccess, onFailure: OnFailure) => logOut(onSuccess, onFailure)(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NavigationBar));