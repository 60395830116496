import GoogleCode from "../../models/GoogleCode";
import AuthToken from "../../models/AuthToken";

export const GOOGLE_CODE_FETCHED = "GOOGLE_CODE_FETCHED";
export type GOOGLE_CODE_FETCHED = { googleCode: GoogleCode }

export const AUTH_TOKEN_FETCHED = "AUTH_TOKEN_FETCHED";
export type AUTH_TOKEN_FETCHED = { authToken: AuthToken }

export const AUTH_LOG_OUT = "AUTH_LOG_OUT";
export type AUTH_LOG_OUT = {}