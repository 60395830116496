import React, {useEffect, useState} from "react";
import {makeStyles, Paper, Typography} from "@material-ui/core";
import {defaultStyles} from "../../styles/defaultStyles";
import {apiInstance} from "../../api/Api";
import Part from "../../models/Part";
import TabSpinner from "../common/TabSpinner";
import PartsSummaryTable from "./PartsSummaryTable";
import Decimal from "decimal.js";
import Scrollable from "../common/Scrollable";

const useStyles = makeStyles(defaultStyles);

const PartsSummary: React.FC<{}> = () => {

	const [isLoading, setLoading] = useState<boolean>(true);
	const [rows, setRows] = useState<PartValueRow[]>([]);

	const fetchData = () => {
		setLoading(true);
		Promise.all([
			apiInstance.partsApi.fetchParts(),
			apiInstance.partsApi.fetchPartsValues(),
			apiInstance.partsApi.fetchPartUnits(),
			apiInstance.partsApi.fetchPartCategories(),
			apiInstance.partsApi.fetchPartTypes(),
		]).then(data => {
			const partWithValues = data[0].filter(p => data[1].some(pv => pv.partId === p.id));
			const rows: PartValueRow[] = partWithValues.map(part => {
				const partValue = data[1].filter(pv => pv.partId === part.id)[0];
				const unit = data[2].filter(u => u.id === part.unitId)[0];
				const category = data[3].filter(c => c.id === part.categoryId)[0];
				const type = data[4].filter(t => t.id === part.typeId)[0];
				return {
					...part,
					quantity: partValue.quantity,
					value: partValue.value,
					categoryName: part.categoryId ? category.name : "-",
					typeName: part.typeId ? type.typeId : "-",
					unitName: unit.displayName
				}
			}).filter((pv) => (pv as PartValueRow).quantity !== 0);
			setRows(rows);
			setLoading(false)
		}).catch(err => console.error(err));
	};

	useEffect(() => {
		fetchData()
	}, []);

	const classes = useStyles();

	const totalValue = new Decimal(rows.reduce((acc, b) => acc + b.value, 0)).dividedBy(10000).toFixed(2);

	return (
		<Paper className={classes.tablePaper}>
			{isLoading ?
				<TabSpinner/> :
				<Scrollable>
					<PartsSummaryTable rows={rows}/>
					<Typography variant={"body1"} style={{marginTop: 8, marginRight: 8, textAlign: "right", marginBottom: 8}}>
						Total value: {totalValue} PLN
					</Typography>
				</Scrollable>
			}
		</Paper>
	);
};

export default PartsSummary;

export interface PartValueRow extends Part {
	quantity: number,
	value: number,
	unitName: string,
	typeName: string,
	categoryName: string,
}