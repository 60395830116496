import {none, option, Option} from "ts-option";
import {IGrnDocumentApiDto} from "./_RestModels";
import moment from "moment";

export default class IGrnDocument {
	constructor(
		public readonly id: string,
		public readonly description: string,
		public readonly documentDate: moment.Moment,
		public readonly documentNumber: string,
		public readonly ginDocumentId: string,
		public readonly acceptedAt: Option<moment.Moment>,
		public readonly acceptedBy: Option<string>,
		public readonly reportId: Option<string>,
		public readonly createdAt: moment.Moment,
		public readonly createdBy: string,
	) {}

	public static fromJson(json: IGrnDocumentApiDto): IGrnDocument {
		const acceptedAt = json.acceptedAt ? option(moment(json.acceptedAt)) : none;
		const acceptedBy = option(json.acceptedBy);
		const reportId = option(json.reportId);
		const {id, description, documentDate, documentNumber, ginDocumentId, createdAt, createdBy} = json;
		return new IGrnDocument(id, description, moment(documentDate), documentNumber, ginDocumentId, acceptedAt, acceptedBy,
			reportId, moment(createdAt), createdBy)
	}
}