import {none, option, Option} from "ts-option";
import AuthToken from "../models/AuthToken";

export default class LocalStorage {
	static YOSENSI_WAREHOUSE_TOKEN = "YOSENSI_TOKEN";

	memoryStorage: { [key: string]: string } = {};

	constructor() {
		const yosensiToken = localStorage.getItem(LocalStorage.YOSENSI_WAREHOUSE_TOKEN);

		if (yosensiToken) {
			this.saveToStorage(LocalStorage.YOSENSI_WAREHOUSE_TOKEN, JSON.parse(yosensiToken))
		}
	}

	/**
	 * Save value to storage.
	 * @param key {string}
	 * @param value {any}
	 */
	public saveToStorage(key: string, value: any) {
		const stringValue = JSON.stringify(value);
		this.memoryStorage[key] = stringValue;
		try {
			localStorage.setItem(key, stringValue);
		}
		catch (err) {
			console.error("Could not save item to storage. "+ err)
		}
	}

	public removeYosensiData() {
		this.removeItemFromStorage(LocalStorage.YOSENSI_WAREHOUSE_TOKEN);
	}

	public getYosensiToken(): Option<AuthToken> {
		const jsonToken = this.getFromStorage(LocalStorage.YOSENSI_WAREHOUSE_TOKEN);
		if (jsonToken) {
			return option(jsonToken);
		}
		return none;
	}

	/**
	 * Get value from storage.
	 * @param key
	 */
	public getFromStorage(key: string): any {
		let item: any;
		try {
			item = localStorage.getItem(key);
			if (item) {
				return JSON.parse(item)
			}
			else if (this.memoryStorage.hasOwnProperty(key)) {
				return JSON.parse(this.memoryStorage[key]);
			}
			return null;
		}
		catch (err) {
			console.warn("There is a problem accessing browser local storage. After browser refresh you will be automatically log out.");
		}
	}

	/**
	 * Remove value from storage.
	 * @param key {string}
	 */
	public removeItemFromStorage(key: string): void {
		if (this.memoryStorage.hasOwnProperty(key)) {
			delete this.memoryStorage[key];
			try {
				localStorage.removeItem(key);
			}
			catch (err) {
				console.error("Could not remove item from storage. "+ err)
			}
		}
	}
}
