import React, {useEffect, useState} from "react";
import {makeStyles, Paper} from "@material-ui/core";
import TabSpinner from "../common/TabSpinner";
import {defaultStyles} from "../../styles/defaultStyles";
import {apiInstance} from "../../api/Api";
import Technology from "../../models/Technology";
import TechnologyTable from "./TechnologyTable";
import TechnologyModal from "./TechnologyModal";
import {none, Option, option} from "ts-option";
import TechnologyValidateModal from "./TechnologyValidateModal";
import TechnologyItem from "../../models/TechnologyItem";
import PartType from "../../models/PartType";
import Part from "../../models/Part";
import Scrollable from "../common/Scrollable";
import AddFab from "../common/AddFab";

const useStyles = makeStyles(defaultStyles);

const Technologies: React.FC<{}> = () => {
	const classes = useStyles();
	const [isLoading, setLoading] = useState<boolean>(true);
	const [isOpen, setOpen] = useState(false);
	const [isValidateOpen, setValidateOpen] = useState(false);
	const [technologies, setTechnologies] = useState<Technology[]>([]);
	const [parts, setParts] = useState<Part[]>([]);
	const [types, setTypes] = useState<PartType[]>([]);
	const [technology, setTechnology] = useState<Option<Technology>>(none);
	const [technologyItems, setTechnologyItems] = useState<TechnologyItem[]>([]);

	const fetchData = async () => {
		const technologies = await apiInstance.technologiesApi.fetchTechnologies();
		setTechnologies(technologies);
	};

	const onClose = () => {
		setOpen(false);
		setLoading(true);
		fetchData()
			.then(() => setLoading(false))
			.catch(() => {
				// TODO Handle error
				setLoading(false)
			});
		setTechnology(none)
	};

	const onCopy = (technology: Technology) => {
		if (window.confirm("Technologies could not be deleted.\nClone current technology?")) {
			apiInstance.technologiesApi.fetchTechnologyItems(technology.id).then((items) => {
				apiInstance.technologiesApi.postTechnology(`${technology.name}_copy`, technology.partId)
					.then((technologyCopy) => {
						const technologyCopyItemsPromises = items.map(
							item => apiInstance.technologiesApi.postTechnologyItem(technologyCopy.id, item.partId,
								item.quantity));
						//TODO Handle error
						Promise.all(technologyCopyItemsPromises).then(() => {
							fetchData()
								.then(() => setLoading(false))
								.catch(() => {
									// TODO Handle error
									setLoading(false)
								});
						}).catch((err: any) => console.error(err))
					}).catch((err: any) => {
					console.error(err)
				})
			}).catch((err: any) => {
				console.error(err)
			})
		}
	};

	useEffect(() => {
		fetchData()
			.then(() => setLoading(false))
			.catch(() => {
				// TODO Handle error
				setLoading(false)
			});
	}, []);

	const onEdit = (technology: Technology) => {
		setOpen(true);
		setTechnology(option(technology))
	};

	const onCreate = () => {
		setOpen(true)
	};

	const onValidate = (technology: Technology) => {
		return Promise.all([
			apiInstance.technologiesApi.fetchTechnologyItems(technology.id),
			apiInstance.partsApi.fetchParts(),
			apiInstance.partsApi.fetchPartTypes(),
		]).then(data => {
			const [technologyItems, parts, types] = data;
			setTechnologyItems(technologyItems);
			setParts(parts);
			setTypes(types);
			setValidateOpen(true)

		});
	};


	return (
		<Paper className={classes.tablePaper}>
			{isLoading ?
				<TabSpinner/> :
				<>
					<Scrollable>
						<TechnologyTable technologies={technologies} onEdit={onEdit} onCopy={onCopy} onValidate={onValidate}/>
					</Scrollable>
					<TechnologyModal isOpen={isOpen} technology={technology} onClose={onClose}/>
					<TechnologyValidateModal isOpen={isValidateOpen}
											 technology={technology}
											 technologyItems={technologyItems}
											 parts={parts}
											 types={types}
											 onClose={() => setValidateOpen(false)}/>
					<AddFab onClick={onCreate}/>
				</>
			}
		</Paper>
	);
};

export default Technologies
