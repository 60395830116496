import React from "react";
import ClientsForm from "./ClientsForm";
import {none} from "ts-option";
import {makeStyles, Paper} from "@material-ui/core";
import {defaultStyles} from "../../styles/defaultStyles";

const useStyles = makeStyles(defaultStyles);

const ClientsCreate = () => {
	const classes = useStyles();
	return (
		<Paper className={classes.defaultPaper}>
			<ClientsForm client={none}/>
		</Paper>
	)
};

export default ClientsCreate;