import {GrnDocumentItemApiDto} from "./_RestModels";

export default class GrnDocumentItem {
	constructor(
		public readonly id: string,
		public readonly partId: string,
		public readonly typeId: string,
		public readonly partNumber: string,
		public readonly descriptionPl: string,
		public readonly quantity: number,
		public readonly totalValue: number
	) {}

	public static fromJson(json: GrnDocumentItemApiDto): GrnDocumentItem {
		const {id, partId, typeId, partNumber, descriptionPl, quantity, totalValue} = json;
		return new GrnDocumentItem(id, partId, typeId, partNumber, descriptionPl, quantity, totalValue)
	}
}