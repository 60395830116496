export enum Currency {
	USD = "USD",
	EUR = "EUR",
	GBP = "GBP",
	PLN = "PLN"
}

export function getCurrencies() {
	return Object.keys(Currency)
}

export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";