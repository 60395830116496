import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import Client from "../../models/Client";
import {apiInstance} from "../../api/Api";
import {makeStyles, Paper} from "@material-ui/core";
import TabSpinner from "../common/TabSpinner";
import {defaultStyles} from "../../styles/defaultStyles";
import ClientsTable from "./ClientsTable";
import AddFab from "../common/AddFab";
import Scrollable from "../common/Scrollable";

const useStyles = makeStyles(defaultStyles);

const Clients: React.FC<{}> = () => {

	const classes = useStyles();

	const [isLoading, setLoading] = useState<boolean>(true);
	const [clients, setClients] = useState<Client[]>([]);
	const history = useHistory();

	useEffect(() => {
		const fetchData = async () => {
			const clients = await apiInstance.clientApi.fetchClients();
			setClients(clients)
		};
		fetchData()
			.then(() => setLoading(false))
			.catch((err) => console.error(err));
	}, []);

	return (
		<Paper className={classes.tablePaper}>
			{isLoading ?
				<TabSpinner/> :
				<>
					<Scrollable>
						<ClientsTable clients={clients}/>
					</Scrollable>
					<AddFab onClick={() => history.push("/clients/create")}/>
				</>
			}
		</Paper>
	);
};

export default Clients
