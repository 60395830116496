import React, {useState} from "react";
import PartCategory from "../../../models/PartCategory";
import {Option} from "ts-option";
import {Button, CircularProgress, makeStyles, Modal, Typography} from "@material-ui/core";
import {defaultStyles} from "../../../styles/defaultStyles";
import {useFormik} from "formik";
import {apiInstance} from "../../../api/Api";
import FormTextField from "../../common/FormTextField";

interface IProps {
    isOpen: boolean,
    partCategory: Option<PartCategory>,
    onClose: () => void,
}

interface PartCategoryFormValues {
    name: string,
}

const useStyles = makeStyles(defaultStyles);

const PartCategoryModal: React.FC<IProps> = (props: IProps) => {
    const {isOpen, partCategory, onClose} = props;
    const [isSubmitting, setSubmitting] = useState(false);
    const classes = useStyles();
    const initialValues: PartCategoryFormValues = props.partCategory.map(partCategory => {
        return ({
            name: partCategory.name
        });
    }).getOrElseValue({
        name: ""
    });
    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        onSubmit: ((values: PartCategoryFormValues) => {
            const submitAction = partCategory.isDefined ?
                apiInstance.partsApi.putPartCategory(partCategory.get.id, values.name) :
                apiInstance.partsApi.postPartCategory(values.name);
            // TODO Handle errors nicely
            setSubmitting(true);
            submitAction.then(() => {
                setSubmitting(false);
                formik.resetForm();
                onClose();
            }).catch((err) => {
                setSubmitting(false);
                console.error(err)
            })
        })
    });
    return (
        <Modal
            open={isOpen}
            onClose={() => onClose()}
            style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        >
            <div className={classes.modal}>
                <Typography variant={"h6"}>{
                    partCategory.isDefined ?
                        "Update Part Category" :
                        "Create New Part Category"
                }</Typography>
                <form onSubmit={formik.handleSubmit}>
                    {partCategory.isDefined}
                    <FormTextField
                        id="name"
                        label="Category Name"
                        name="name"
                        required={true}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                    />
                    <div className={classes.modalButtonsRow}>
                        <Button onClick={() => onClose()}>Close</Button>
                        <Button type="submit" color={"primary"} variant={"contained"}>
                            {isSubmitting ? <CircularProgress style={{color: "white"}} size={24}/> :
                                partCategory.isDefined ? "Update" : "Create"}
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default PartCategoryModal